// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OopsFailed_wrapper_2VylL{padding:calc(1.375rem + 1.5vw) calc(1.325rem + .9vw) calc(1.4375rem + 2.25vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--legend-panel-bg)}@media(min-width:1200px){.OopsFailed_wrapper_2VylL{padding:2.5rem 2rem 3.125rem}}.OopsFailed_wrapper_2VylL h2{font-size:calc(1.325rem + .9vw);margin:0 0 calc(1.3rem + .6vw);color:var(--legend-c-text);line-height:1.2;text-align:center}@media(min-width:1200px){.OopsFailed_wrapper_2VylL h2{font-size:2rem;margin:0 0 1.75rem}}.OopsFailed_wrapper_2VylL svg{max-width:calc(1.675rem + 5.1vw);margin:.5rem auto calc(1.375rem + 1.5vw)}@media(min-width:1200px){.OopsFailed_wrapper_2VylL svg{max-width:5.5rem;margin:.5rem auto 2.5rem}}.OopsFailed_wrapper_2VylL p{font-size:1.25rem;margin-bottom:1.25rem;text-align:center;color:var(--grey-cool)}.OopsFailed_wrapper_2VylL section{margin:0 0 calc(1.325rem + .9vw);padding:1rem;background-color:var(--disable);border-radius:var(--border-radius);color:var(--grey-cool)}@media(min-width:1200px){.OopsFailed_wrapper_2VylL section{margin:0 0 2rem}}.OopsFailed_wrapper_2VylL section p{margin:0}.OopsFailed_wrapper_2VylL section .OopsFailed_cardFaildMsg_1eXDW{font-size:.875rem;color:var(--grey-cool);text-align:left;white-space:pre-wrap}.OopsFailed_wrapper_2VylL ol{font-size:1.25rem;padding:0 0 0 calc(1.275rem + .3vw);line-height:1.6;margin:0}@media(min-width:1200px){.OopsFailed_wrapper_2VylL ol{padding:0 0 0 1.5rem}}", ""]);
// Exports
exports.locals = {
	"wrapper": "OopsFailed_wrapper_2VylL",
	"cardFaildMsg": "OopsFailed_cardFaildMsg_1eXDW"
};
module.exports = exports;
