// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PurchasedProcessed_wrapper_30nAi{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--legend-panel-bg)}@media(min-width:1200px){.PurchasedProcessed_wrapper_30nAi{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.PurchasedProcessed_wrapper_30nAi h2{margin-bottom:calc(1.3rem + .6vw);color:var(--legend-c-text);text-align:center}@media(min-width:1200px){.PurchasedProcessed_wrapper_30nAi h2{margin-bottom:1.75rem}}.PurchasedProcessed_wrapper_30nAi .PurchasedProcessed_desc_3Ijfc{font-size:1.25rem;margin-bottom:calc(1.3rem + .6vw);margin-top:1.25rem;text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.PurchasedProcessed_wrapper_30nAi .PurchasedProcessed_desc_3Ijfc{margin-bottom:1.75rem}}.PurchasedProcessed_wrapper_30nAi .PurchasedProcessed_iconSuccess_3EVNO{max-width:calc(1.5625rem + 3.75vw)}@media(min-width:1200px){.PurchasedProcessed_wrapper_30nAi .PurchasedProcessed_iconSuccess_3EVNO{max-width:4.375rem}}.PurchasedProcessed_wrapper_30nAi .PurchasedProcessed_baseButton_3SpCx{margin-bottom:calc(1.3125rem + .75vw)}@media(min-width:1200px){.PurchasedProcessed_wrapper_30nAi .PurchasedProcessed_baseButton_3SpCx{margin-bottom:1.875rem}}", ""]);
// Exports
exports.locals = {
	"wrapper": "PurchasedProcessed_wrapper_30nAi",
	"desc": "PurchasedProcessed_desc_3Ijfc",
	"iconSuccess": "PurchasedProcessed_iconSuccess_3EVNO",
	"baseButton": "PurchasedProcessed_baseButton_3SpCx"
};
module.exports = exports;
