import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import arrLogFunc from "../utils";
import store from "../store";

let configPairs = {
  sorted: null,
  unsorted: null
};

export const LoadMockJs = async () => {
  return await Promise.all([
    import(/* webpackChunkName: "mock" */ "mockjs"),
    (await import(/* webpackChunkName: "mock" */ "axios-mock-adapter")).default
  ]);
};

export const initMockAdapter = async (api, Mock, MockAdapter) => {
  if (
    process.env.NODE_ENV === "development" &&
    store.getters["APICONFIG/baseRemote"] !== "mock"
  ) {
    return;
  }

  const {
    tmplRfq,
    tmplTradeHistory,
    tmplSettleHistory,
    tmplBalance,
    tmplBankList,
    tmplPairs,
    tmplUpdateBankInfo,
    tmplGetCurrencies,
    tmplGetBankInfo,
    tmplDepositMethods,
    tmplSubmitDeposit,
    tmplSaveBillingAddress,
    tmplQueryWireBankFields,
    tmplSubmitWireBankFields,
    tmplQueryDepositBank,
    tmplConfirmdeposit,
    tmplGetWireCode,
    tmplQueryQA,
    tmplSubmitQA,
    tmplQueryPaymentmethods,
    tmplQueryUserBankInfo,
    tmplDeletePaymentmethod,
    tmplQueryProfile,
    tmplSubmitProfile,
    tmplQueryQuoteForCrypto,
    tmplSumitOrderForCrypto,
    tmplQueryQuoteForFiat,
    tmplSubmitOrderForFiat,
    tmplQueryTradePaymentMethods,
    tmplQueryWithdrawBank,
    tmplConfirmWithdraw,
    tmplSubmitCreditcard,
    tmplQueryRegion,
    tmplTransferHistory,
    tmplCheckBin
  } = await import(/* webpackChunkName: "mock-data" */ "./mock");

  const mock = new MockAdapter(api, {
    // delayResponse: 2000
  });

  // mock.onPost('/trade').networkErrorOnce();
  mock
    .onGet("/users")
    .reply(200, {
      users: [{ id: 1, name: "John Smith" }]
    })
    .onGet("/pairs")
    .reply(200, Mock.mock(tmplPairs))
    .onPost("/rfq", { pair: "BTCUSD", side: "buy", quantity: 1.1 })
    .reply(config => {
      const { pair, side, quantity } = JSON.parse(config.data);
      return [200, { ...Mock.mock(tmplRfq), pair, side, quantity }];
    })
    .onGet("/tradehistory")
    .reply(config => {
      const { offset, limit } = config.params;
      return [
        200,
        Mock.mock(tmplTradeHistory).array.slice(offset, offset + limit)
      ];
    })
    .onGet("/transferhistory")
    .reply(config => {
      const { offset, limit } = config.params;
      return [
        200,
        Mock.mock(tmplTransferHistory).array.slice(offset, offset + limit)
      ];
    })
    .onPost("/rfq", { pair: "BTCUSD", side: "buy", quantity: 1.1 })
    .reply(config => {
      const { pair, side, quantity } = JSON.parse(config.data);
      return [200, { ...Mock.mock(tmplRfq), pair, side, quantity }];
    })
    // .onGet("/balance")
    // .reply(200, Mock.mock(tmplBalance))
    .onGet("/bank/list")
    .reply(200, Mock.mock(tmplBankList))
    .onGet(/\/settlement\/history/)
    .reply(config => {
      const { offset, limit } = config.params;
      return [
        200,
        Mock.mock(tmplSettleHistory).array.slice(offset, offset + limit)
      ];
    })
    .onPost("/bank/update")
    .reply(200, Mock.mock(tmplUpdateBankInfo))
    .onPost("/user/preference")
    .reply(200, Mock.mock(tmplUpdateBankInfo))
    .onPost("/settlement")
    .reply(200, Mock.mock(tmplUpdateBankInfo))
    .onGet("/currencies")
    .reply(200, Mock.mock(tmplGetCurrencies))
    .onGet("/balance")
    .reply(200, Mock.mock(tmplBalance))
    .onGet("/bank")
    .reply(200, Mock.mock(tmplGetBankInfo))
    .onGet("/wirecode")
    .reply(200, Mock.mock(tmplGetWireCode))
    .onGet("/depositmethods")
    .reply(200, Mock.mock(tmplDepositMethods))
    .onPost("/deposit")
    .reply(200, Mock.mock(tmplSubmitDeposit))
    .onPost("/billingaddress")
    .reply(200, Mock.mock(tmplSaveBillingAddress))
    .onGet("/bank/fields")
    .reply(200, Mock.mock(tmplQueryWireBankFields))
    .onPost("/bank")
    .reply(200, Mock.mock(tmplSubmitWireBankFields))
    .onGet("/deposit/bank")
    .reply(200, Mock.mock(tmplQueryDepositBank))
    .onPost("/confirmdeposit")
    .reply(200, Mock.mock(tmplConfirmdeposit))
    .onGet("/individual/qa")
    .reply(200, Mock.mock(tmplQueryQA))
    .onPost("/individual/qa")
    .reply(200, Mock.mock(tmplSubmitQA))
    .onGet("/paymentmethods")
    .reply(200, Mock.mock(tmplQueryPaymentmethods))
    .onGet("/user/bank")
    .reply(200, Mock.mock(tmplQueryUserBankInfo))
    .onDelete("/paymentmethod")
    .reply(200, Mock.mock(tmplDeletePaymentmethod))
    .onGet("/profile")
    .reply(200, Mock.mock(tmplQueryProfile))
    .onPost("/profile")
    .reply(200, Mock.mock(tmplSubmitProfile))
    .onPost("/quote")
    .reply(200, Mock.mock(tmplQueryQuoteForCrypto))
    .onPost("/order")
    .reply(200, Mock.mock(tmplSumitOrderForCrypto))
    .onPost("/quotebyfiat")
    .reply(200, Mock.mock(tmplQueryQuoteForFiat))
    .onPost("/orderbyfiat")
    .reply(200, Mock.mock(tmplSubmitOrderForFiat))
    // .reply(202, { redirect_url: "https://api.legendtrading.com/dist/mix-manifest.json" })
    .onGet("/order/paymentmethods")
    .reply(200, Mock.mock(tmplQueryTradePaymentMethods))
    .onGet("/banks")
    .reply(200, Mock.mock(tmplQueryWithdrawBank))
    .onPost("/withdraw")
    .reply(200, Mock.mock(tmplConfirmWithdraw))
    .onPost("/creditcard")
    .reply(200, Mock.mock(tmplSubmitCreditcard))
    .onGet("/region")
    .reply(200, Mock.mock(tmplQueryRegion))
    .onPost("/creditcard/checkbin")
    .reply(200, Mock.mock(tmplCheckBin))
    .onGet("/payment/intent")
    .reply(200, Mock.mock(tmplQueryRegion))
    .onAny()
    .passThrough();
};

const api = axios.create({
  baseURL: store.getters["APICONFIG/baseUrlTrade"],
  headers: {
    Authorization: `Bearer`,
    Accept: "application/json"
  },
  timeout: 180000
});

api.interceptors.request.use(async config => {
  config.baseURL = store.getters["APICONFIG/baseUrlTrade"];
  if (!config?.skipInterceptors) {
    const token = store.state.token;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(...arrLogFunc);

const getUsers = () => api.get("/users");

const postLogin = async ({
  email = "",
  exchangeName = "",
  environment = ""
}) => {
  const resp = await api.post(
    `${store.getters["APICONFIG/getDemoDevBaseUrl"]}/login`,
    {
      email,
      environment,
      exchangeName
    }
  );
  return resp.data;
};

const sign = async config => {
  const resp = await api.get(
    `${store.getters["APICONFIG/getDemoDevBaseUrl"]}/sign`,
    {
      skipInterceptors: true,
      headers: { ...config }
    }
  );
  return resp.data;
};

const authorize = async ({
  timestamp,
  signature,
  appKey,
  appId,
  appUrl,
  appUid,
  appEmail,
  appPhone
}) => {
  const resp = await api.post("/authorize", {
    timestamp,
    signature,
    "APP-KEY": appKey,
    "APP-ID": appId,
    "APP-URL": appUrl,
    "APP-UID": appUid,
    "APP-EMAIL": appEmail,
    "APP-PHONE": appPhone
  });
  return resp.data;
};

const getSardineConfig = async params => {
  const resp = await api.post("/risksession", params);
  return resp.data;
};

// 这个接口貌似不用了，请确认
const getPairs = async ({ is_sort = false }) => {
  const cacheKey = is_sort ? "sorted" : "unsorted";
  if (!configPairs[cacheKey]) {
    const resp = await api.get("/pairs", {
      params: { is_sort: is_sort ? 1 : 0 }
    });
    configPairs[cacheKey] = resp.data;
  }
  return configPairs[cacheKey];
};

const getPairsInfo = async () => {
  const resp = await api.get("/pairsinfo");
  return resp.data;
};

const rfq = async ({ pair = "BTCUSD", side = "buy", quantity = 1 }) => {
  const resp = await api.post("/rfq", {
    client_rfq_id: uuidv4(),
    pair,
    side,
    quantity
  });
  return resp.data;
};

const trade = async ({
  rfq_id = "",
  side = "buy",
  quantity = 0,
  pair = "BTCUSD",
  price = 0
}) => {
  const resp = await api.post("/trade", {
    rfq_id,
    side,
    quantity,
    pair,
    price
  });
  if (resp.data.error_code) {
    // error with 200 response code
    const error = new Error(resp.data.error_code);
    error.response = resp;
    throw error;
  }
  return resp.data;
};

const getTradeHistory = async ({
  startdate = "1970-01-01T00:00:00.000000Z",
  enddate = "",
  limit = 5000,
  offset = 0,
  order = "desc"
}) => {
  const resp = await api.get("/tradehistory", {
    params: { offset, limit, startdate, enddate, order }
  });
  return resp.data;
};

const getTransferHistory = async ({
  startdate = "1970-01-01T00:00:00.000000Z",
  enddate = "",
  limit = 5000,
  offset = 0,
  order = "desc"
}) => {
  const resp = await api.get("/transferhistory", {
    params: { offset, limit, startdate, enddate, order }
  });
  return resp.data;
};

const getBalance = async () => {
  const resp = await api.get("/balance");
  return resp.data;
};

const getCurrencies = async () => {
  const resp = await api.get("/currencies");
  return resp.data;
};

const getBankInfo = async ({ currency = "USD" }) => {
  const resp = await api.get("/bank", {
    params: { currency }
  });
  return resp.data;
};

const getTbwallet = async (asset = "") => {
  const resp = await api.get("/tbwallet", {
    params: { asset }
  });
  return resp.data;
};

const addSettlement = async ({ amount_pay_to = 0, asset_pay_to = "USD" }) => {
  const resp = await api.post("/settlement", {
    amount_pay_to,
    asset_pay_to
  });
  return resp.data;
};

const getSettlementHistory = async ({
  startdate = "1970-01-01T00:00:00.000000Z",
  enddate = "",
  limit = 5000,
  offset = 0,
  order = "desc"
}) => {
  const resp = await api.get("/settlement/history", {
    params: { offset, limit, startdate, enddate, order }
  });
  return resp.data;
};

const getBankList = async () => {
  const resp = await api.get("/bank/list");
  return resp.data;
};

const updateBankInfo = async ({
  account_name = "",
  account_address = "",
  bank_name = "",
  bank_address = "",
  bank_country = "",
  account_number = "",
  routing_number = "",
  bank_swift = ""
}) => {
  const resp = await api.post("/bank/update", {
    account_name,
    account_address,
    bank_name,
    bank_address,
    bank_country,
    account_number,
    routing_number,
    bank_swift
  });
  return resp.data;
};

const getFees = async () => {
  const resp = await api.get("/fees");
  return resp.data;
};

const rfqByFiat = async ({ size = "", side = "", pair = "" }) => {
  const resp = await api.post("/rfqbyfiat", {
    size,
    side,
    pair
  });
  return resp.data;
};

const tradeByFiat = async ({ size = "", side = "", pair = "" }) => {
  const resp = await api.post("/tradebyfiat", {
    size,
    side,
    pair
  });
  return resp.data;
};

const saveUserPreference = async ({ key = "default_fiat", value = "USD" }) => {
  const resp = await api.post("/user/preference", {
    key,
    value
  });
  return resp.data;
};

const queryDepositMethods = async ({ currency = "USD" }) => {
  const resp = await api.get("/depositmethods", {
    params: { currency }
  });
  return resp.data;
};

const submitDeposit = async ({ payment_method_id = "", amount = "" }) => {
  const resp = await api.post("/deposit", {
    payment_method_id,
    amount
  });
  return resp.data;
};

const saveBillingAddress = async ({
  country_iso2_code = "",
  state = "",
  city = "",
  address_line_1 = "",
  address_line_2 = "",
  zipcode
}) => {
  const resp = await api.post("/billingaddress", {
    country_iso2_code,
    state,
    city,
    address_line_1,
    address_line_2,
    zipcode
  });
  return resp.data;
};

const submitCreditcard = async params => {
  const resp = await api.post("/creditcard", params);
  return resp.data;
};

const queryWireBankFields = async ({ currency = "USD" }) => {
  const resp = await api.get("/bank/fields", {
    params: { currency }
  });
  return resp.data;
};

const sumbitWireBankInfo = async params => {
  const resp = await api.post("/bank", params);
  return resp.data;
};

const queryDepositBank = async ({
  currency = "USD",
  transfer_type = "wire"
}) => {
  const resp = await api.get("/deposit/bank", {
    params: { currency, transfer_type }
  });
  return resp.data;
};

const getWireCode = async ({ currency = "USD" }) => {
  const resp = await api.get("/wirecode", { params: { currency } });
  return resp.data;
};

const confirmDeposit = async params => {
  const resp = await api.post("/confirmdeposit", params);
  return resp.data;
};

const queryQA = async () => {
  const resp = await api.get("/individual/qa");
  return resp.data;
};

const submitQA = async params => {
  const resp = await api.post("/individual/qa", params);
  return resp.data;
};

const queryPaymentmethods = async () => {
  const resp = await api.get("/paymentmethods");
  const paymentmethods = resp.data;
  const findIndex = paymentmethods.findIndex(item => item.type === "ach");
  if (findIndex >= 0) {
    store.dispatch("updateLinkBankAccount", true);
  } else {
    store.dispatch("updateLinkBankAccount", false);
  }
  return paymentmethods;
};

const queryUserBankInfo = async ({ bank_id = "" }) => {
  const resp = await api.get("/user/bank", {
    params: { bank_id }
  });
  return resp.data;
};

const deletePaymentmethod = async ({ payment_method_id = "" }) => {
  const resp = await api.delete("/paymentmethod", {
    params: { payment_method_id }
  });
  return resp.data;
};

const queryProfile = async () => {
  const resp = await api.get("/profile");
  return resp.data;
};

const submitProfile = async params => {
  const resp = await api.post("/profile", params);
  return resp.data;
};

const queryQuoteForCrypto = async params => {
  const resp = await api.post("/quote", params);
  return resp.data;
};

const submitOrderForCrypto = async params => {
  const resp = await api.post("/order", params);
  return resp.data;
};

const queryQuoteForFiat = async params => {
  const resp = await api.post("/quotebyfiat", params);
  return resp.data;
};

const submitOrderForFiat = async params => {
  const filterParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== "" && value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const resp = await api.post("/orderbyfiat", filterParams);
  return resp.data;
};

const queryTradePaymentMethods = async ({ pair = "", side = "" }) => {
  const resp = await api.get("/order/paymentmethods", {
    params: { pair, side }
  });

  const paymentmethods = resp.data;
  const findIndex = paymentmethods.findIndex(item => item.type === "ach");
  if (findIndex >= 0) {
    store.dispatch("updateLinkBankAccount", true);
  } else {
    store.dispatch("updateLinkBankAccount", false);
  }
  return paymentmethods;
};

const queryWithdrawBank = async ({ currency = "USD" }) => {
  const resp = await api.get("/banks", {
    params: { currency }
  });
  return resp.data;
};

const confirmWithdraw = async params => {
  const resp = await api.post("/withdraw", params);
  return resp.data;
};

const queryRegion = async ({ parent_id }) => {
  const resp = await api.get("/region", {
    params: { parent_id }
  });
  return resp.data;
};

const queryPortifolio = async () => {
  const resp = await api.get("/portifolio");
  return resp.data;
};

const sendDepositEmail = async params => {
  const resp = await api.post("/depositemail", params);
  return resp.data;
};

const getUserInfo = async ({ appId, appUid }) => {
  const resp = await api.get("/user/info", {
    params: {
      "APP-ID": appId,
      "APP-UID": appUid
    }
  });
  return resp.data;
};

const checkCreditCardBin = async ({ bin = "" }) => {
  const resp = await api.post("/creditcard/checkbin", { bin });
  return resp.data;
};

const queryPaymentMethodFields = async ({ payment_method_id }) => {
  const resp = await api.get("/paymentmethod/fields", {
    params: {
      payment_method_id
    }
  });
  return resp.data;
};

const queryPaymentDetails = async ({ settlement_id }) => {
  const resp = await api.get("/settlementdetails", {
    params: {
      settlement_id
    }
  });
  return resp.data;
};

const saveUserSsn = async params => {
  const resp = await api.post("/user/ssn", params);
  return resp.data;
};

const checkAchPullProcess = async ({ scene = "plaid_bind" } = {}) => {
  const resp = await api.get("/process/check", {
    params: {
      scene
    }
  });
  return resp.data;
};

const saveMobile = async ({
  phone_number = "",
  phone_country_code = "",
  phone_country_code_short = ""
}) => {
  const resp = await api.post("/mobile", {
    phone_number,
    phone_country_code,
    phone_country_code_short
  });
  return resp.data;
};

const getMobile = async () => {
  const resp = await api.get("/mobile");
  return resp.data;
};

const resendMobileCode = async () => {
  const resp = await api.post("/mobile/send");
  return resp.data;
};

const verifyMobile = async ({ code = "", verify_id = "" }) => {
  const resp = await api.post("/mobile/verify", {
    code,
    verify_id
  });
  return resp.data;
};

const getVeriffSession = async () => {
  const resp = await api.get("/veriff/session");
  return resp.data;
};

const getVeriffResult = async () => {
  const resp = await api.get("/idv/status");
  return resp.data;
};

const resendEmailCode = async () => {
  const resp = await api.post("/email/send");
  return resp.data;
};

const verifyEmail = async ({ code = "", verify_id = "" }) => {
  const resp = await api.post("/email/verify", {
    code,
    verify_id
  });
  return resp.data;
};

const saveUserSsnInKycProcess = async params => {
  const resp = await api.post("/user/ssn/new", params);
  return resp.data;
};

const queryTradeDetails = async ({ trade_id = "" } = {}) => {
  const resp = await api.get("/tradedetails", {
    params: {
      trade_id
    }
  });
  return resp.data;
};

const confirmAchPullDeposit = async params => {
  const resp = await api.post("/ach/confirmdeposit", params);
  return resp.data;
};

const makeAchPullDeposit = async params => {
  const resp = await api.post("/ach/makedeposit", params);
  return resp.data;
};

const queryUserStatus = async params => {
  const resp = await api.get("/user/status", {
    params
  });
  return resp.data;
};

const queryPaymentDetailsByIntentId = async ({ payment_intent_id = "" }) => {
  const resp = await api.get("payment/intent", {
    params: {
      payment_intent_id
    }
  });
  return resp.data;
};

export {
  api,
  sign,
  authorize,
  getSardineConfig,
  rfq,
  getUsers,
  getPairs,
  trade,
  getTradeHistory,
  getBalance,
  getCurrencies,
  getBankInfo,
  getTbwallet,
  addSettlement,
  getSettlementHistory,
  getBankList,
  updateBankInfo,
  getFees,
  rfqByFiat,
  tradeByFiat,
  saveUserPreference,
  queryDepositMethods,
  submitDeposit,
  saveBillingAddress,
  submitCreditcard,
  queryWireBankFields,
  sumbitWireBankInfo,
  queryDepositBank,
  getWireCode,
  confirmDeposit,
  queryQA,
  submitQA,
  queryPaymentmethods,
  queryUserBankInfo,
  deletePaymentmethod,
  queryProfile,
  submitProfile,
  queryQuoteForCrypto,
  submitOrderForCrypto,
  queryQuoteForFiat,
  submitOrderForFiat,
  queryTradePaymentMethods,
  queryWithdrawBank,
  confirmWithdraw,
  getTransferHistory,
  queryRegion,
  queryPortifolio,
  sendDepositEmail,
  getUserInfo,
  checkCreditCardBin,
  queryPaymentMethodFields,
  queryPaymentDetails,
  saveUserSsn,
  checkAchPullProcess,
  getMobile,
  resendMobileCode,
  verifyMobile,
  saveMobile,
  getVeriffSession,
  getVeriffResult,
  resendEmailCode,
  verifyEmail,
  queryTradeDetails,
  postLogin,
  getPairsInfo,
  saveUserSsnInKycProcess,
  confirmAchPullDeposit,
  makeAchPullDeposit,
  queryUserStatus,
  queryPaymentDetailsByIntentId
};
