import { mapGetters, mapState, mapActions } from "vuex";

export const commonMixin = {
  computed: {
    ...mapGetters(["isExternalCheckoutProcess"]),
    ...mapState([
      "tradeBuyAchPullProcessSteps",
      "tradeBuyAchPullProcessIndex",
      "tradeBuyAchPullProcesses",
      "kycProcessSteps",
      "checkKycProcessList",
      "tradeBuyAchPullInKycProcessIndex",
      "tradeBuyAchPullInKycProcesses",
      "tradeBuyAchPullInKycProcessSteps",
      "paymentAchPullProcessSteps",
      "paymentAchPullProcessIndex",
      "paymentAchPullProcesses",
      "depositAchPullProcessSteps",
      "depositAchPullProcessIndex",
      "depositAchPullProcesses",
      "depositAchPullInKycProcessSteps",
      "depositAchPullInKycProcessIndex",
      "depositAchPullInKycProcesses"
    ])
  },
  methods: {
    ...mapActions([
      "updateTradeBuyAchPullProcessIndex",
      "updateTradeBuyAchPullInKycProcessIndex",
      "updatePaymentAchPullProcessIndex",
      "updateDepositAchPullProcessIndex",
      "updateDepositAchPullInKycProcessIndex"
    ]),
    ...mapActions("REGISTER", ["changeCurrentStep4IndividualStatus"]),
    isTransferOff(dataInfo) {
      const { parameters = null } = dataInfo;
      if (!parameters) throw new Error();
      const bRet = Object.values(parameters).some(value => value === "N/A"); //只要有一个“N/A”就返回true
      return bRet;
    },
    autoNavigateToTradePage() {
      setTimeout(() => {
        if (this.isExternalCheckoutProcess) {
          this.$router.push({ name: "external.checkout" });
        } else {
          this.$router.push({ name: "home.trade" });
        }
      }, 4000);
    },
    additionalIndividualKycProcess() {
      return this.$route.meta.type === "additional_individual_kyc";
    },
    tradeBuyAchPullProcess() {
      return this.$route.meta.type === "achPull_in_trade";
    },
    tradeBuyAchPullInKycProcess() {
      return this.$route.meta.type === "achPull_in_kyc";
    },
    depositAchPullInKycProcess() {
      return this.$route.meta.type === "achPull_deposit_in_kyc";
    },
    paymentAchPullProcess() {
      return this.$route.meta.type === "achPull_in_payment";
    },
    depositAchPullProcess() {
      return this.$route.meta.type === "achPull_deposit_in_trade";
    },
    creditCardInTradeProcess() {
      return this.$route.meta.type === "credit_card_in_trade";
    },
    creditCardInExternalCheckoutProcess() {
      return this.$route.meta.type === "credit_card_in_external_checkout";
    },
    getTradeBuyAchPullPathName() {
      let currentIndex = this.tradeBuyAchPullProcessIndex;
      const processStepsArray = this.handleProcessStepsData(
        this.tradeBuyAchPullProcesses,
        this.tradeBuyAchPullProcessSteps
      );
      const pathName = processStepsArray[currentIndex];
      this.updateTradeBuyAchPullProcessIndex(++currentIndex);
      return pathName;
    },
    operateKycProcessSteps() {
      let currentIndex = 0;
      const dataKey = this.checkKycProcessList[currentIndex];
      if (["question_naire"].includes(dataKey)) {
        this.changeCurrentStep4IndividualStatus(0);
      } else if (["veriff_id"].includes(dataKey)) {
        this.changeCurrentStep4IndividualStatus(0);
        this.changeCurrentStep4IndividualStatus(1);
      }

      const pathName = this.kycProcessSteps[dataKey];
      if (pathName) {
        return pathName;
      } else {
        this.changeCurrentStep4IndividualStatus(0);
        this.changeCurrentStep4IndividualStatus(1);
        this.changeCurrentStep4IndividualStatus(2);
        return "individual.enterEmail";
      }
    },
    getTradeBuyAchPullInKycProcessPathName() {
      let currentIndex = this.tradeBuyAchPullInKycProcessIndex;
      const processStepsArray = this.handleProcessStepsData(
        this.tradeBuyAchPullInKycProcesses,
        this.tradeBuyAchPullInKycProcessSteps
      );
      const pathName = processStepsArray[currentIndex];
      this.updateTradeBuyAchPullInKycProcessIndex(++currentIndex);
      return pathName;
    },
    getPaymentAchPullPathName() {
      let currentIndex = this.paymentAchPullProcessIndex;
      const processStepsArray = this.handleProcessStepsData(
        this.paymentAchPullProcesses,
        this.paymentAchPullProcessSteps
      );
      const pathName = processStepsArray[currentIndex];
      this.updatePaymentAchPullProcessIndex(++currentIndex);
      return pathName;
    },
    getDepositAchPullPathName() {
      let currentIndex = this.depositAchPullProcessIndex;
      const processStepsArray = this.handleProcessStepsData(
        this.depositAchPullProcesses,
        this.depositAchPullProcessSteps
      );
      const pathName = processStepsArray[currentIndex];
      this.updateDepositAchPullProcessIndex(++currentIndex);
      return pathName;
    },
    getDepositAchPullInKycPathName() {
      let currentIndex = this.depositAchPullInKycProcessIndex;
      const processStepsArray = this.handleProcessStepsData(
        this.depositAchPullInKycProcesses,
        this.depositAchPullInKycProcessSteps
      );
      const pathName = processStepsArray[currentIndex];
      this.updateDepositAchPullInKycProcessIndex(++currentIndex);
      return pathName;
    },
    handleProcessStepsData(processArray, stepArray) {
      const processStepsSet = new Set();
      processArray.map(process => {
        const pathName = stepArray[process];
        processStepsSet.add(pathName);
      });
      return Array.from(processStepsSet);
    },
    getMinimumDepositbyPairsInfo(paris, currentPair, defaultAsset) {
      const pairsInfo = `${currentPair}${defaultAsset}`;
      const dataInfo = paris?.find(pair => pair?.[pairsInfo]);
      return dataInfo?.[pairsInfo]?.minimum_size || 50;
    },
    getMinimumQuantitybyPairsInfo(paris, currentPair, defaultAsset) {
      const pairsInfo = `${currentPair}${defaultAsset}`;
      const dataInfo = paris?.find(pair => pair?.[pairsInfo]);
      return dataInfo?.[pairsInfo]?.minimum_quantity || 50;
    }
  }
};
