import {
  UPDATE_WIRE_CURRENT_STEP_INDEX,
  UPDATE_CARD_CURRENT_STEP_INDEX,
  SET_WIRE_BANK_DETAIL,
  UPDATE_WIRE_CURRENCY,
  UPDATE_CARD_INFO,
  UPDATE_CARD_ADDREES_ID,
  UPDATE_BANK_TRANSFER_STEP_INDEX
} from "./mutation-types";

// inital state
const state = {
  currentWireStepIndex: 0,
  wireViewList: [
    {
      stepIndex: 0,
      view: "ChooseCurrency",
      title: "Choose currency"
    },
    {
      stepIndex: 1,
      view: "EnterBankDetails",
      title: "Enter bank details"
    },
    {
      stepIndex: 2,
      view: "ConfirmBankDetails",
      title: "Confirm bank details"
    },
    {
      stepIndex: 3,
      view: "WireFinished",
      title: ""
    }
  ],
  wireBankDetail: [],
  wireCurrency4Payment: "",
  currentCreditCardStepIndex: 0,
  creditCardViewList: [
    {
      stepIndex: 0,
      view: "BillingAddress",
      title: "Enter billing address"
    },
    {
      stepIndex: 1,
      view: "CreditCardDetails",
      title: "Enter card details"
    },
    {
      stepIndex: 2,
      view: "CreditCardCheck",
      title: "Card check"
    },
    {
      stepIndex: 3,
      view: "CreditCardSuccessed",
      title: ""
    },
    {
      stepIndex: 4,
      view: "CreditCardFailed",
      title: ""
    }
  ],
  creditCardInfo: {},
  cardAddressId: "",
  bankTransferStepIndex: 0,
  bankTransferViewList: [
    {
      stepIndex: 0,
      view: "EnterBankDetails",
      title: "Enter bank details"
    },
    {
      stepIndex: 1,
      view: "ConfirmBankDetails",
      title: "Confirm bank details"
    },
    {
      stepIndex: 2,
      view: "Finished",
      title: ""
    }
  ]
};
// getters
const getters = {};
// actions
const actions = {
  updateCurrentWireStepIndex({ commit }, stepData) {
    commit(UPDATE_WIRE_CURRENT_STEP_INDEX, stepData);
  },
  updateCurrentCreditCardStepIndex({ commit }, stepData) {
    commit(UPDATE_CARD_CURRENT_STEP_INDEX, stepData);
  },
  setWireBankDetail({ commit }, data) {
    commit(SET_WIRE_BANK_DETAIL, data);
  },
  updateWireCurrency4Payment({ commit }, data) {
    commit(UPDATE_WIRE_CURRENCY, data);
  },
  updateCreditCardInfo({ commit }, data) {
    commit(UPDATE_CARD_INFO, data);
  },
  updateCardAddressId({ commit }, data) {
    commit(UPDATE_CARD_ADDREES_ID, data);
  },
  updateBankTransferStepIndex({ commit }, stepData) {
    commit(UPDATE_BANK_TRANSFER_STEP_INDEX, stepData);
  }
};
// mutations
const mutations = {
  [UPDATE_WIRE_CURRENT_STEP_INDEX](state, stepData) {
    state.currentWireStepIndex = stepData;
  },
  [UPDATE_CARD_CURRENT_STEP_INDEX](state, stepData) {
    state.currentCreditCardStepIndex = stepData;
  },
  [SET_WIRE_BANK_DETAIL](state, value) {
    state.wireBankDetail = !value ? [] : [...value];
  },
  [UPDATE_WIRE_CURRENCY](state, value) {
    state.wireCurrency4Payment = value;
  },
  [UPDATE_CARD_INFO](state, data) {
    state.creditCardInfo = { ...state.creditCardInfo, ...data };
  },
  [UPDATE_CARD_ADDREES_ID](state, data) {
    state.cardAddressId = data;
  },
  [UPDATE_BANK_TRANSFER_STEP_INDEX](state, data) {
    state.bankTransferStepIndex = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
