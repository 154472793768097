<template>
  <section :class="$style.wrapper">
    <h2>
      {{ $t("Your deposit has been initiated") }}
    </h2>
    <icon-success :class="$style.iconSuccess" />
    <div :class="$style.desc">
      {{
        $t(
          `Your ACH deposit of ${amount} has been initiated. These funds have been pre-credited and are now available in your fiat account for trading.`
        )
      }}
    </div>
    <BaseButton @click="handleClick" :class="$style.baseButton">
      {{ $t("START A ORDER") }}
    </BaseButton>
  </section>
</template>

<script>
import { Icon } from "ant-design-vue";
import { currencyLoadMixin } from "@/mixin/currencyLoadMixin";
import BaseButton from "@/components/BaseButton.vue";
import IconSuccess from "@/assets/img/common/icon-success.svg?inline";

export default {
  name: "Modal.MakeDeposit4AchPull",
  mixins: [currencyLoadMixin],
  components: {
    [Icon.name]: Icon,
    BaseButton,
    IconSuccess
  },
  props: {
    amount: [String]
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.reloadBalance();
      this.$emit("ok");
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(2rem);
    @include rfs(1.75rem, margin-bottom);

    color: var(--legend-c-text);
    text-align: center;
  }

  .desc {
    @include rfs(1rem);
    @include rfs(1.75rem, margin-bottom);
    @include rfs(1.25rem, margin-top);

    text-align: center;
    color: var(--grey-cool);
  }

  .iconSuccess {
    @include rfs(4.375rem, max-width);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }

  label {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);

    &:hover {
      color: var(--primary-3);
    }

    &:active {
      color: var(--legend-c-text);
    }
  }
}
</style>
