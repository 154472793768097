import tinycolor from "tinycolor2";

var hueStep = 2;
var saturationStep = 16;
var saturationStep2 = 5;
var brightnessStep1 = 5;
var brightnessStep2 = 15;
var lightColorCount = 5;
var darkColorCount = 4;

const getHue = function(hsv, i, isLight) {
  var hue;
  if (hsv.h >= 60 && hsv.h <= 240) {
    hue = isLight ? hsv.h - hueStep * i : hsv.h + hueStep * i;
  } else {
    hue = isLight ? hsv.h + hueStep * i : hsv.h - hueStep * i;
  }
  if (hue < 0) {
    hue += 360;
  } else if (hue >= 360) {
    hue -= 360;
  }
  return Math.round(hue);
};
const getSaturation = function(hsv, i, isLight) {
  var saturation;
  if (isLight) {
    saturation = Math.round(hsv.s * 100) - saturationStep * i;
  } else if (i === darkColorCount) {
    saturation = Math.round(hsv.s * 100) + saturationStep;
  } else {
    saturation = Math.round(hsv.s * 100) + saturationStep2 * i;
  }
  if (saturation > 100) {
    saturation = 100;
  }
  if (isLight && i === lightColorCount && saturation > 10) {
    saturation = 10;
  }
  if (saturation < 6) {
    saturation = 6;
  }
  return Math.round(saturation);
};
const getValue = function(hsv, i, isLight) {
  if (isLight) {
    return Math.round(hsv.v * 100) + brightnessStep1 * i;
  }
  return Math.round(hsv.v * 100) - brightnessStep2 * i;
};

export const getHsvColor = function(color, index) {
  var isLight = index <= 6;
  const primaryColor = tinycolor(color);
  var hsv = primaryColor.toHsv();
  var i = isLight ? lightColorCount + 1 - index : index - lightColorCount - 1;

  return tinycolor({
    h: getHue(hsv, i, isLight),
    s: getSaturation(hsv, i, isLight),
    v: getValue(hsv, i, isLight)
  }).toHexString();
};

export const colorToHexString = color => {
  return tinycolor(color).toHexString();
};
