<template>
  <section :class="$style.wrapper">
    <h2 v-html="$t('cvv_modal_title')" />

    <div :class="$style.item">
      <div :class="$style.bankAccountIcon">
        <IconCreditCard :type="cardInfo.type" :flag="cardInfo.flag" />
      </div>

      <div :class="$style.itemContent">
        <div :class="$style.title">
          {{ cardInfo.title }}
        </div>
        <div :class="$style.desc">
          {{ $t("Debit/credit card") }}
        </div>
      </div>
    </div>
    <p>
      <a-input
        v-model="cvv"
        ref="input"
        required
        inputmode="numeric"
        size="large"
        placeholder="CVV/CVC"
        @pressEnter="handleClick"
        @keyup.esc="handleEscape"
        @keydown.stop.enter
      />
    </p>
    <BaseButton type="primary" @click="handleClick" :class="$style.baseButton">
      {{ $t("Confirm") | uppercase }}
    </BaseButton>

    <label @click="handleCancel">
      {{ $t("Cancel") | uppercase }}
    </label>
  </section>
</template>

<script>
import { Input } from "ant-design-vue";
import BaseButton from "@/components/BaseButton.vue";
import IconCreditCard from "@/components/IconCreditCard.vue";

export default {
  name: "Modal.EnterCvv",
  components: {
    [Input.name]: Input,
    BaseButton,
    IconCreditCard
  },
  data() {
    return {
      cvv: ""
    };
  },
  props: {
    cardInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {},
  watch: {
    cvv(val, oldVal) {
      const re = /[^0-9]/gi;
      if (re.test(val)) {
        this.cvv = oldVal;
      }
    }
  },
  methods: {
    handleEscape() {
      this.$emit("cancel");
    },
    handleClick() {
      if (this.cvv) {
        this.$emit("ok", this.cvv);
      }
    },
    handleCancel() {
      this.$emit("cancel");
    }
  },
  activated() {
    this.cvv = "";
    this.$nextTick(() => {
      this.$refs?.input.$refs?.input?.focus?.();
    });
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--legend-c-text);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);
    @include rfs(1.25rem, margin-top);

    text-align: center;
    color: var(--grey-cool);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }

  :global(.ant-input) {
    @include rfs(0.625rem, border-radius);
    @include rfs(3.125rem, height);
    @include rfs(1.25rem);

    color: var(--legend-c-text);
    background-color: var(--legend-component-bg);
    font-weight: 500;
  }

  label {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);

    &:hover {
      color: var(--primary-3);
    }

    &:active {
      color: var(--legend-c-text);
    }
  }

  .item {
    @include rfs(1.25rem, padding);
    @include rfs(0.625rem, margin-bottom);

    display: flex;
    align-items: center;

    .bankAccountIcon {
      @include rfs(3.875rem, width);

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        @include rfs(100%, width);
        color: var(--silver);
      }
    }
    .itemContent {
      @include rfs(0.9375rem, margin-left);
      // @include rfs(20rem, min-width);

      text-align: left;
      flex: 1;

      .title {
        @include rfs(1.25rem);

        color: var(--legend-c-text);
      }
      .desc {
        @include rfs(0.875rem);

        color: var(--grey-cool);
        line-height: 22px;
        font-weight: 600;
      }
    }
  }
}
</style>
