<template>
  <section :class="$style.wrapper">
    <section :class="$style.container">
      <div :class="$style.headerTitle">Order Summary</div>
      <div :class="$style.orderInfo">
        <div :class="$style.itemTitle">YOU PAY</div>
        <div :class="$style.itemInfo">
          <div :class="$style.fiatAmount">{{ tradeQuantity }}</div>
          <AssetSelector
            assetType="fiat"
            :assetList="fiatList"
            v-model="currency"
          />
        </div>
      </div>
      <div :class="$style.orderInfo">
        <div :class="$style.itemTitle">YOU GET</div>
        <div :class="$style.itemInfo">
          <a-spin v-if="quoteLoading">
            <a-icon slot="indicator" :component="IconWait" spin />
          </a-spin>
          <div v-else :class="$style.cryptoAmount">{{ getReceiveCount }}</div>
          <AssetSelector
            assetType="crypto"
            :assetList="cryptoList"
            v-model="currentPair"
          />
        </div>
      </div>
      <div :class="$style.exchangeRateInfo">
        <div :class="$style.exchangeRate">
          <div>Exchange rate:</div>
          <a-spin v-if="quoteLoading">
            <a-icon slot="indicator" :component="IconWait" spin />
          </a-spin>
          <div v-else>{{ getTradeRate }}</div>
        </div>
        <div :class="$style.exchangePerCrypto">= 1 {{ tradePair }}</div>
      </div>
      <div :class="$style.viewMore" @click="handleViewMore">
        <span>{{ $t("View fees") }}</span>
        <icon-arrow
          :class="[isViewMore ? $style.iconArrowUp : $style.iconArrowDown]"
        />
      </div>
      <div v-if="isViewMore">
        <div :class="$style.orderFeeContainer">
          <div :class="$style.orderFee">
            <span :class="$style.title">
              {{ $t("Network fees") }}:
              <icon-error
                @mouseenter="isShowNetworkTips = true"
                @mouseleave="isShowNetworkTips = false"
                :class="$style.iconTips"
              />
            </span>
            <a-spin v-if="quoteLoading">
              <a-icon slot="indicator" :component="IconWait" spin />
            </a-spin>
            <span v-else :class="$style.fee">{{ getNetworkFee }}</span>
            <template v-if="!isAchPullTabSelected">
              <div
                :class="$style.tipsContent"
                v-show="isShowNetworkTips"
                v-html="$t('trade_confirm_desc.network_fees')"
              />
            </template>
            <template v-else>
              <div :class="$style.tipsContent" v-show="isShowNetworkTips">
                {{ $t("trade_ach_pull_confirm_desc.network_fees") }}
              </div>
            </template>
          </div>
          <div :class="$style.estimated">
            {{ isAchPullTabSelected ? `(${$t("Estimated")})` : "" }}
          </div>
        </div>

        <div :class="$style.orderFee">
          <span :class="$style.title">
            {{ $t("Processing fees") }}:
            <icon-error
              @mouseenter="isShowProcessingTips = true"
              @mouseleave="isShowProcessingTips = false"
              :class="$style.iconTips"
            />
          </span>
          <a-spin v-if="quoteLoading">
            <a-icon slot="indicator" :component="IconWait" spin />
          </a-spin>
          <span v-else :class="$style.fee">{{ getProcessingFee }}</span>
          <template v-if="!isAchPullTabSelected">
            <div :class="$style.tipsContent" v-show="isShowProcessingTips">
              {{ $t("trade_confirm_desc.processing_fees") }}
            </div>
          </template>
          <template v-else>
            <div :class="$style.tipsContent" v-show="isShowProcessingTips">
              {{ $t("trade_ach_pull_confirm_desc.processing_fees") }}
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="!isAchPullTabSelected"
        :class="$style.orderTips"
        v-html="$t('trade_confirm_desc.exchange_rate')"
      />
      <div
        v-else
        :class="$style.orderTips"
        v-html="$t('trade_ach_pull_confirm_desc.exchange_rate')"
      />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Spin, Icon, Select } from "ant-design-vue";
import { quoteMixin } from "@/mixin/quoteMixin";
import AssetSelector from "@/components/register/AssetSelector.vue";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";
import IconArrow from "@/assets/img/common/icon-arrow.svg?inline";
import IconError from "@/assets/img/common/icon-error.svg?inline";
import CaretDown from "@/assets/img/common/caret-down.svg?inline";

export default {
  name: "Register.OrderSummary",
  mixins: [quoteMixin],
  props: {},
  data() {
    return {
      isViewMore: false,
      isShowNetworkTips: false,
      isShowProcessingTips: false,
      IconWait,
      CaretDown,
      errorMessage: ""
    };
  },
  computed: {
    ...mapGetters({
      asset: "asset",
      allowAchPull: "allowAchPull"
    }),
    ...mapState({
      pairs: state => state.pairs,
      tradePair: state => state.tradePair,
      tradeQuantity: state => state.tradeQuantity,
      approxInfo: state => state.approxInfo,
      currencyUnitList: state => state.currencyUnitList,
      quoteLoading: state => state.quoteLoadTimer,
      defaultPaymentTabsActived: state => state.defaultPaymentTabsActived
    }),
    getNetworkFee() {
      const { currencyUnitList, asset, approxInfo } = this;
      return (
        approxInfo?.network_fee &&
        `${currencyUnitList[asset]} ${approxInfo?.network_fee ?? ""} ${asset}`
      );
    },
    getProcessingFee() {
      const { currencyUnitList, asset, approxInfo } = this;
      return (
        approxInfo?.processing_fee &&
        `${currencyUnitList[asset]} ${approxInfo?.processing_fee ??
          ""} ${asset}`
      );
    },
    currentPair: {
      get() {
        return this.tradePair;
      },
      set(value) {
        this.updateTradePair(value);
      }
    },
    fiatList() {
      // 如果用户选择了数字货币，那么就找到对应的可以交易的法币
      const set = new Set();
      this.pairs.forEach(itemPair => {
        Object.keys(itemPair).forEach(pair => {
          let v = itemPair[pair];
          const vArr = v?.display?.split(" / ") ?? "";
          if (this.currentPair === vArr[0]) {
            set.add(v?.display?.split(" / ")[1]);
          }
        });
      });
      const arr = Array.from(set);
      return arr;
    },
    cryptoList() {
      // 先找设定的法币对应的交易对，找到就正常展示
      const set = new Set();
      const defaultSet = new Set();
      this.pairs.forEach(itemPair => {
        Object.keys(itemPair).forEach(pair => {
          let v = itemPair[pair];
          const vArr = v?.display?.split(" / ") ?? "";
          if (this.asset === vArr[1]) {
            vArr.length && set.add(vArr[0]);
          } else {
            vArr.length && defaultSet.add(vArr[0]);
          }
        });
      });
      const arr = Array.from(!set.size ? defaultSet : set);
      return arr;
    },
    currency: {
      get() {
        return this.asset;
      },
      set(value) {
        this.updateAsset(value);
      }
    },
    getReceiveCount() {
      return this.approxInfo?.quantity ?? "";
    },
    getTradeRate() {
      const { currencyUnitList, asset, approxInfo } = this;
      return (
        approxInfo?.price &&
        `${currencyUnitList[asset]} ${approxInfo?.price ?? ""} ${asset}`
      );
    },
    isAchPullTabSelected() {
      return (
        this.allowAchPull && this.defaultPaymentTabsActived === "bank_transfer"
      );
    }
  },
  components: {
    [Icon.name]: Icon,
    [Spin.name]: Spin,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    AssetSelector,
    IconArrow,
    IconError
  },
  methods: {
    ...mapActions(["updateAsset", "updateTradePair"]),
    handleViewMore() {
      this.isViewMore = !this.isViewMore;
    }
  },
  activated() {},
  deactivated() {},
  created() {
    this.startQuote();
  },
  beforeDestroy() {
    this.stopQuote();
  },
  mounted() {}
};
</script>

<style scoped lang="scss" module>
@import "~rfs/scss";

.wrapper {
  position: absolute;
  right: rfs-value(70px);
  top: 3.75rem;

  background-color: var(--legend-component-bg);
  border-radius: rfs-value(10px);
  .container {
    @include rfs(280px, width);
    @include rfs(15px 20px, padding);

    border: solid 2px var(--primary);
    border-radius: rfs-value(10px);

    .headerTitle {
      @include rfs(24px);
      @include rfs(40px, margin-bottom);

      font-weight: 600;
      color: var(--legend-c-text);
    }

    .orderInfo {
      &:nth-child(2) {
        @include rfs(12px, padding-bottom);
        @include rfs(12px, margin-bottom);

        border-bottom: solid rfs-value(1px) var(--silver-25);
      }
      &:nth-child(3) {
        @include rfs(23px, margin-bottom);
      }
      .itemTitle {
        font-size: 14px;
        font-weight: 500;
        color: var(--grey-cool);
      }
      .itemInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: rfs-value(40px);

        .fiatAmount,
        .cryptoAmount {
          @include rfs(35px);

          font-weight: 300;
          color: var(--legend-c-text);
          line-height: rfs-value(38px);
        }
        .fiatInfo,
        .cryptoInfo {
          @include rfs(30px, height);
          @include rfs(0 10px, padding);

          display: flex;
          align-items: center;
          background-color: #f7f7f7;
          border-radius: rfs-value(15px);

          .flagBg,
          .cryptoBg {
            @include rfs(16px, width);
            @include rfs(16px, height);
            @include rfs(6px, margin-right);

            border-radius: 50%;
            background-size: cover;
            box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);
          }
        }
      }
    }

    .exchangeRateInfo {
      @include rfs(14px);

      font-weight: 500;
      color: var(--legend-c-text);
      .exchangeRate {
        display: flex;
        align-items: center;
        justify-content: space-between;

        min-height: rfs-value(24px);
      }
      .exchangePerCrypto {
        text-align: right;
      }
    }

    .viewMore {
      @include rfs(1.0625rem, margin-bottom);

      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        @include rfs(0.875rem);
        @include rfs(0.25rem, margin-right);

        font-weight: 500;
        color: var(--grey-cool-light);
        text-decoration: underline;

        &:hover {
          color: var(--primary-3);
        }

        &:active {
          color: var(--dark);
        }
      }

      .iconArrowDown {
        @include rfs(0.875rem, max-width);
        color: var(--grey-cool-light);
        transition: 0.2s;
        transform-origin: center;
        transform: rotate(0deg);
      }

      .iconArrowUp {
        @include rfs(0.875rem, max-width);
        color: var(--grey-cool-light);
        transition: 0.2s;
        transform-origin: center;
        transform: rotate(180deg);
      }
    }

    .orderFeeContainer {
      @include rfs(0.5rem, margin-bottom);
    }

    .orderFee {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .title {
        @include rfs(0.875rem);

        font-weight: 500;
        color: var(--legend-c-text);
        display: flex;
        align-items: center;
        .iconTips {
          @include rfs(1rem, width);
          @include rfs(0.5rem, margin-left);

          color: var(--silver);
          cursor: pointer;

          &:hover {
            @include rfs(1.2rem, width);

            box-shadow: 0 0 0 0.1rem var(--white), 0 0 0 0.2rem var(--primary);
            border-radius: 50%;
          }
        }
      }
      .fee {
        @include rfs(0.875rem);

        color: var(--legend-c-text);
        text-align: right;
      }

      .tipsContent {
        @include rfs(0.75rem);
        @include rfs(0.625rem, padding);

        color: var(--white);
        position: absolute;
        border-radius: 0.625rem;
        background: #797980;
        z-index: 2;
        letter-spacing: -0.2px;
        top: 2rem;
        text-align: left;
      }

      :global {
        .ant-spin-dot {
          @include rfs(1rem);
        }
      }
    }

    .orderTips {
      @include rfs(0.75rem);
      @include rfs(2.1875rem, margin-bottom);

      letter-spacing: -0.26px;
      color: var(--grey-cool);
      text-align: left;
    }

    .estimated {
      @include rfs(4px, margin-left);

      font-weight: 500;

      color: var(--grey-cool-light);
    }
  }
}
</style>
