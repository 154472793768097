import Index from "./index.vue";

const AlertMessage = {};
let getContainer = () => {
  return document.body;
};

AlertMessage.install = function(Vue) {
  const AlertMessageInstance = Vue.extend(Index);

  let _alertMessage;
  const initInstance = () => {
    const $link = document.createElement("style");
    $link.innerHTML = `
    .alertMessageModal{
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      pointer-events: all;
    }
    .alertMessageBox {
      min-width: 35rem;
      max-width: 35rem;
      position: relative;
      border-radius: 0.625rem;
      word-break: break-word;
      background: var(--legend-panel-bg);
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;
      -webkit-animation-name: NotificationFadeIn;
      animation-name: NotificationFadeIn;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
    }
    .alertMessageContent {
      padding: 2.5rem 3.125rem 3.125rem 3.125rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .message {
      font-size: 2rem;
      color: var(--legend-c-text);
      margin-bottom: 1.75rem;
      text-align: center;
    }
    .desc {
      font-size: 1.25rem;
      color: var(--grey-cool);
      margin-bottom: 1.75rem;
      line-height: 2rem;
      text-align: center;
    }
    .btnDismiss {
      padding: 0.9375rem 2.5rem;
      height: 3.125rem;
      font-size: 0.875rem;
      min-width: 15.9375rem;
      box-shadow: none;
      border-radius: 0.625rem;
      text-align: center;
      color: var(--white);
      font-weight: 600;
      background-color: var(--primary);
      cursor: pointer;
      outline: 0;
      border: none;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      transition: 0.8s;
    }
    .btnDismiss:hover {
      background-color: var(--dark-80);
      box-shadow: inset 0 0 0 2rem var(--primary-8);
      color: var(--white);
    }
    .btnDismiss:active {
      background-color: var(--primary);
      color: var(--white);
      box-shadow: inset 0 0 0 0rem var(--primary-8);
    }
    @-webkit-keyframes NotificationFadeIn {
      0% {
        bottom: 88px;
        opacity: 0;
      }
      100% {
        bottom: 0;
        opacity: 1;
      }
    }
    @keyframes NotificationFadeIn {
      0% {
        bottom: 88px;
        opacity: 0;
      }
      100% {
        bottom: 0;
        opacity: 1;
      }
    }
    `;
    getContainer().appendChild($link);
    _alertMessage = new AlertMessageInstance();
    let el = _alertMessage.$mount().$el;
    getContainer().appendChild(el);
  };

  Vue.prototype.$alertMessage = {
    success(msg, desc, cb) {
      if (!_alertMessage) {
        initInstance();
      }
      return _alertMessage.success(msg, desc, cb);
    },
    error(msg, desc, cb) {
      if (!_alertMessage) {
        initInstance();
      }
      return _alertMessage.error(msg, desc, cb);
    },
    warning(msg, desc, cb) {
      if (!_alertMessage) {
        initInstance();
      }
      return _alertMessage.warning(msg, desc, cb);
    }
  };
};

AlertMessage.config = function(options) {
  if (options?.getContainer) {
    getContainer = options.getContainer;
  }
};
export default AlertMessage;
