<template>
  <section
    :class="[
      $style.tabsItem,
      itemData.groupType === defaultPaymentTabsActived
        ? $style.tabsItemSel
        : $style.tabsItemNor
    ]"
    @click="onChange(itemData)"
  >
    <div :class="$style.tabsItemTop">
      <div :class="$style.logo">
        <IconCreditCard :type="itemData.groupType" />
      </div>
      <div :class="$style.radioBtn">
        <div
          :class="$style.radioBtnChecked"
          v-show="itemData.groupType === defaultPaymentTabsActived"
        />
      </div>
    </div>
    <div :class="$style.tabsItemTitle">
      {{ $t(itemData.title) }}
    </div>
    <div :class="$style.tabsItemDesc">{{ $t(itemData.description) }}</div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import IconCreditCard from "@/components/IconCreditCard.vue";

export default {
  name: "TradePaymentList.TradeTabs.TabsItem",
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["defaultPaymentTabsActived"])
  },
  components: {
    IconCreditCard
  },
  methods: {
    onChange(data) {
      this.$emit("change:tabsItem", data.groupType);
    }
  },
  created() {},
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.tabsItem {
  &:not(:last-child) {
    @include rfs(20px, margin-right);
  }
  @include rfs(12px 12px 15px 20px, padding);
  width: rfs-value(160px);
  border-radius: rfs-value(20px);
  text-align: left;
  cursor: pointer;

  .tabsItemTop {
    @include rfs(9px, margin-bottom);

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rfs-value(26px);

    .logo {
      width: rfs-value(30px);

      svg {
        height: rfs-value(26px);
      }
    }

    .radioBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rfs-value(26px);
      height: rfs-value(26px);
      border-radius: 50%;
      border: solid 1px var(--grey-cool);

      .radioBtnChecked {
        width: rfs-value(18px);
        height: rfs-value(18px);
        background-color: var(--primary);
        border-radius: 50%;
      }
    }
  }

  .tabsItemTitle {
    @include rfs(14px);

    font-weight: 500;
    line-height: rfs-value(20px);
    color: var(--legend-c-text);
  }

  .tabsItemDesc {
    @include rfs(12px);

    font-weight: normal;
    color: var(--grey-cool);
    letter-spacing: -0.26px;
  }
}

.tabsItemNor {
  background-color: var(--legend-disabled-bg);

  svg {
    color: var(--grey-cool);
  }
}
.tabsItemSel {
  background-color: var(--legend-panel-bg);

  box-shadow: inset 0 0 0 1px var(--primary);
  svg {
    color: var(--primary);
  }
}
</style>
