<template>
  <a-button
    :disabled="passed"
    :class="$style.baseButton"
    :loading="loading"
    :type="type"
    @click="handleClick"
  >
    <slot></slot>
  </a-button>
</template>

<script>
import { Button } from "ant-design-vue";
export default {
  name: "BaseButton",
  components: {
    [Button.name]: Button
  },
  props: {
    passed: {
      type: [String, Boolean],
      default: false
    },
    loading: {
      type: [Boolean],
      default: false
    },
    type: {
      type: [String],
      default: "primary"
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClick(e) {
      this.$emit("click", e);
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
</style>
