<template>
  <section v-if="showBanner" :class="$style.banner">
    <a-list
      :data-source="filteredBanner"
      :split="false"
      rowKey="banner_id"
      size="small"
    >
      <a-list-item slot="renderItem" slot-scope="{ text, url }">
        <div>
          <strong :class="$style.circle">&ocir;</strong>
          <a v-if="url" target="_blank" :href="url">{{ text }}</a>
          <span v-else>{{ text }}</span>
        </div>
      </a-list-item>
    </a-list>
  </section>
</template>

<script>
import { List } from "ant-design-vue";
import { mapGetters } from "vuex";

export default {
  name: "BannerLeft",
  components: {
    [List.name]: List,
    [List.Item.name]: List.Item
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    ...mapGetters({
      banner: "activeBanner"
    }),
    filteredBanner() {
      if (Array.isArray(this.banner)) {
        return this.banner.filter(n => {
          const isTop = n.position === "Left";
          const isAlive = true;
          return isTop && isAlive;
        });
      } else {
        return [];
      }
    },
    showBanner() {
      return this.visible && this.filteredBanner.length > 0;
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.banner {
  @include rfs(17.5rem, max-width);
  @include rfs(1.6875rem, margin-bottom);
  @include rfs(1.125rem 1.25rem 1.25rem, padding);
  background-color: var(--legend-panel-bg);
  border-radius: 0.625rem;
  position: relative;

  &::before {
    border: 2px solid var(--primary);
    border-radius: 0.625rem;
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
  }

  & a {
    text-decoration: underline;
  }

  .circle {
    @include rfs(1rem);
    @include rfs(0.5rem, margin-right);
    color: var(--success);
  }

  & :global(.ant-list-sm .ant-list-item) {
    padding-bottom: 2px;
    padding-top: 2px;
  }
}
</style>
