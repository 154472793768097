// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PaymentList_checkBox_3pptf{font-size:.75rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;color:var(--grey-2)}.PaymentList_checkBox_3pptf span{text-decoration:underline;color:var(--primary)}.PaymentList_errors_JBkZ3{margin:0 auto .625rem auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:.625rem 0;color:var(--danger);width:70%}.PaymentList_errors_JBkZ3 svg{width:1rem;min-width:1rem;margin-right:.5rem}.PaymentList_baseButton_VAkIO{margin:0 auto .9375rem auto}.PaymentList_cancelButton_Mw7n5{font-size:.875rem;font-weight:700;text-decoration:underline;color:var(--primary);cursor:pointer;width:-webkit-max-content;width:-moz-max-content;width:max-content;margin:auto}.PaymentList_cancelButton_Mw7n5:hover{color:var(--primary-3)}.PaymentList_cancelButton_Mw7n5:active{color:var(--legend-c-text)}.PaymentList_userAgreement_GH5Nl{margin:0 auto calc(1.375rem + 1.5vw) auto;max-width:32.5rem}@media(min-width:1200px){.PaymentList_userAgreement_GH5Nl{margin:0 auto 2.5rem auto}}.PaymentList_userAgreement_GH5Nl span{cursor:pointer;text-decoration:underline;color:var(--primary)}.PaymentList_achPullTips__6DQ_{font-size:.6875rem;color:var(--grey-2);text-align:left}.PaymentList_iconBakkt_PBFhF{margin-top:calc(1.3125rem + .75vw)}@media(min-width:1200px){.PaymentList_iconBakkt_PBFhF{margin-top:1.875rem}}", ""]);
// Exports
exports.locals = {
	"checkBox": "PaymentList_checkBox_3pptf",
	"errors": "PaymentList_errors_JBkZ3",
	"baseButton": "PaymentList_baseButton_VAkIO",
	"cancelButton": "PaymentList_cancelButton_Mw7n5",
	"userAgreement": "PaymentList_userAgreement_GH5Nl",
	"achPullTips": "PaymentList_achPullTips__6DQ_",
	"iconBakkt": "PaymentList_iconBakkt_PBFhF"
};
module.exports = exports;
