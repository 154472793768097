import axios from "axios";
import arrLogFunc from "@/utils";
import store from "@/store";

export const LoadMockJs = async () => {
  return await Promise.all([
    import(/* webpackChunkName: "mock" */ "mockjs"),
    (await import(/* webpackChunkName: "mock" */ "axios-mock-adapter")).default
  ]);
};

export const initMockAdapter = async (api, Mock, MockAdapter) => {
  if (
    process.env.NODE_ENV === "development" &&
    store.getters["APICONFIG/baseRemote"] !== "mock"
  ) {
    return;
  }

  const {
    tmplQueryKycInfo,
    tmplQueryKycQa,
    tmplQueryKycQaSubmit,
    tmplSubmitIndividual,
    tmplQueryVeriffSession,
    tmplCheckProcess,
    tmplQueryPhoneCountryList
  } = await import(/* webpackChunkName: "mock-data" */ "@/mocks/v2/kyc");

  const mock = new MockAdapter(api, {
    // delayResponse: 2000
  });

  mock
    .onGet("/individual")
    .reply(200, Mock.mock(tmplQueryKycInfo))
    .onPost("/individual/save")
    .reply(200, { message: "succeed" })
    .onGet("/individual/qa")
    .reply(200, Mock.mock(tmplQueryKycQa))
    .onPost("/individual/qa")
    .reply(200, tmplQueryKycQaSubmit)
    .onPost("/individual/submit")
    .reply(200, Mock.mock(tmplSubmitIndividual))
    .onGet("/individual/idv/session")
    .reply(200, Mock.mock(tmplQueryVeriffSession))
    .onGet("/process/check")
    .reply(200, Mock.mock(tmplCheckProcess))
    .onGet("/phone/country/list")
    .reply(200, Mock.mock(tmplQueryPhoneCountryList))
    .onGet("/country/list")
    .reply(200, Mock.mock(tmplQueryPhoneCountryList))
    .onGet("/state/list")
    .reply(200, Mock.mock(tmplQueryPhoneCountryList))
    .onAny()
    .passThrough();
};

const api = axios.create({
  baseURL: store.getters["APICONFIG/baseUrlKycV2"],
  headers: {
    Authorization: `Bearer`,
    Accept: "application/json"
  },
  timeout: 180000
});

api.interceptors.request.use(config => {
  config.baseURL = store.getters["APICONFIG/baseUrlKycV2"];
  const token = store.state.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(...arrLogFunc);

const queryKycInfo = async params => {
  const resp = await api.get("/individual", {
    params: { query: params }
  });
  return resp.data;
};

const submitKycInfo = async params => {
  const resp = await api.post("/individual/save", params);
  return resp.data;
};

const queryKycQa = async () => {
  const resp = await api.get("/individual/qa");
  return resp.data;
};

const submitKycQa = async params => {
  const resp = await api.post("/individual/qa", params);
  return resp.data;
};

const submitIndividual = async () => {
  const resp = await api.post("/individual/submit");
  return resp.data;
};

const queryVeriffSession = async () => {
  const resp = await api.get("/individual/idv/session");
  return resp.data;
};

const checkProcess = async ({ process = "individual_kyc" } = {}) => {
  const resp = await api.get("/process/check", {
    params: {
      process
    }
  });
  return resp.data;
};

const queryPhoneCountryList = async () => {
  const resp = await api.get("/phone/country/list");
  return resp.data;
};

const queryCountryList = async () => {
  const resp = await api.get("/country/list");
  return resp.data;
};

const queryStateList = async ({ region_id }) => {
  const resp = await api.get("/state/list", {
    params: { region_id }
  });
  return resp.data;
};

const queryCommonFields = async params => {
  const resp = await api.get("/custom/fields", {
    params: {
      field: params
    }
  });
  return resp.data;
};

const uploadFile = async formData => {
  const resp = await api.post("/file/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};

// 图片预览
const getFilePreview = async ({ file_preview = "" }) => {
  const resp = await api.post(
    "/file/preview",
    {
      file_preview
    },
    {
      responseType: "arraybuffer"
    }
  );
  return resp.data;
};

export {
  api,
  queryKycInfo,
  submitKycInfo,
  queryKycQa,
  submitKycQa,
  submitIndividual,
  queryVeriffSession,
  checkProcess,
  queryPhoneCountryList,
  queryCountryList,
  queryStateList,
  queryCommonFields,
  uploadFile,
  getFilePreview
};
