<template>
  <section :class="$style.dexWrapper">
    <div :class="$style.dexItem">
      <div :class="$style.title">{{ $t("Receiving address") }}:</div>
      <div :class="$style.itemValue">{{ walletAddress }}</div>
    </div>
    <div :class="$style.dexItem">
      <div :class="$style.title">{{ $t("Network") }}:</div>
      <div :class="$style.itemValue">{{ network }}</div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DexPanel",
  props: {
    walletAddress: [String],
    network: [String]
  },
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";
.dexWrapper {
  @include rfs(22.5rem, max-width);

  margin: 2rem auto;
  .dexItem {
    text-align: left;
    .title {
      @include rfs(0.875rem);
      @include rfs(0.4375rem, margin-bottom);

      font-weight: 600;
      color: var(--legend-c-text);
    }
    .itemValue {
      @include rfs(0.875rem);
      @include rfs(0.4375rem, margin-bottom);

      color: var(--legend-c-text);
    }
  }
}
</style>
