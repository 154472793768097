import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { mapGetters, mapState, mapActions } from "vuex";

dayjs.extend(utc);
dayjs.extend(timezone);

export const veriffMixin = {
  data() {
    return {
      whiteRouterList: [
        "home.trade",
        "account.userinfo",
        "account.paymentList",
        "trade.history",
        "transfer.history",
        "account.balance",
        "payment",
        "deposit",
        "faq",
        "account.bankDetails"
      ]
    };
  },
  computed: {
    ...mapGetters(["isEddPendingStatus", "isRfiPendingStatus"]),
    ...mapState({
      kycStatus: state => state.kycStatus,
      nextKycStep: state => state.nextKycStep,
      userInfo: state => state.userInfo
    }),
    isInRfiGracePeriodEndTimestamp() {
      const rfiGracePeriodEndTimestamp =
        this.userInfo?.rfi?.grace_period_end_timestamp || undefined;
      if (rfiGracePeriodEndTimestamp) {
        // 有值
        const dateStart = dayjs(rfiGracePeriodEndTimestamp).tz("UTC");
        const dateEnd = dayjs().tz("UTC"); // 今天
        const isAfterToday = dateStart.isAfter(dateEnd);

        // 超过(dateStart>dateEnd) 返回true 没有超过(dateStart<dateEnd) 返回 false
        // 在缓冲时间 返回true 不在缓冲时间 返回false
        return isAfterToday;
      }

      return false;
    }
  },
  methods: {
    ...mapActions(["updateModalView", "updateBaseModalVisible"]),
    showGlobalModal({ name = "" } = {}) {
      const { kycStatus, nextKycStep } = this;
      if (kycStatus === "Rejected") {
        this.updateModalView("RejectedAccount");
        this.updateBaseModalVisible(true);
        return false;
      } else {
        const isWhiteList = this.whiteRouterList.includes(name);
        if (isWhiteList) return true;

        if (nextKycStep === "verify_id") {
          this.updateModalView("VerifyIdentity");
          this.updateBaseModalVisible(true);
          return false;
        } else if (
          kycStatus === "Submitted" ||
          kycStatus === "In Review" ||
          nextKycStep === "reviewing_id" ||
          kycStatus === "Refresh Needed"
        ) {
          this.updateModalView("VerifyReviewing");
          this.updateBaseModalVisible(true);
          return false;
        } else if (this.isRfiPendingStatus) {
          //rfi弹窗的优先级更高
          this.updateModalView("RfiPendingDeposit");
          this.updateBaseModalVisible(true);

          if (this.isInRfiGracePeriodEndTimestamp) {
            return true;
          } else {
            return false;
          }
        } else if (this.isEddPendingStatus) {
          this.updateModalView("EddPendingDeposit");
          this.updateBaseModalVisible(true);
          return false;
        } else {
          return true;
        }
      }
    }
  }
};
