<template>
  <section :class="$style.wrapper">
    <template v-if="isSpinning">
      <div :class="$style.waitWrapper">
        <h2>
          {{ $t("Processing") }}
        </h2>

        <a-spin :spinning="isSpinning">
          <a-icon slot="indicator" :component="IconWait" spin />
        </a-spin>

        <p :class="$style.loadingTips">{{ $t("Please wait") }}</p>
      </div>
    </template>
    <div :title="$t('Identification')" v-else>
      <div :id="veriffId"></div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { MESSAGES } from "@veriff/incontext-sdk";
import { initVeriff, createVeriffFrame } from "@/utils/veriff";
import { Spin, Icon } from "ant-design-vue";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";
import { displayErrors } from "@/utils";
import { reLoadMixin } from "@/mixin/reLoadMixin";

export default {
  name: "Modal.VerifyIdentify",
  components: {
    [Spin.name]: Spin,
    [Icon.name]: Icon
  },
  mixins: [reLoadMixin],
  data() {
    return {
      veriffStatus: "",
      veriffId: "veriff-root",
      veriffFrame: null,
      isSpinning: false,
      IconWait
    };
  },
  computed: {
    ...mapState(["accountType"])
  },
  watch: {
    veriffStatus(status) {
      if (status === MESSAGES.FINISHED) {
        this.$emit("cancel");
        //只要做了人脸识别并且成功了，就一定这个下面的状态
        this.updateKycStatus("In Progress");
        this.updateNextKycStep("verify_id");
        this.isNeedLoad();
        const { name } = this.$route;
        !name.startsWith("home") && this.$router.push({ name: "home.trade" });
      } else {
        this.$emit("cancel");
      }
    }
  },
  methods: {
    ...mapActions(["updateKycStatus", "updateNextKycStep"]),
    handleClick() {
      this.$emit("ok");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleIndividualVeriff() {
      try {
        this.isSpinning = true;
        initVeriff({
          id: this.veriffId,
          handler: (url = "") => {
            this.veriffFrame = createVeriffFrame(url, msg => {
              this.veriffStatus = msg;
            });
          }
        }).then(() => {
          this.isSpinning = false;
        });
      } catch (err) {
        displayErrors(err);
      }
    },
    handleInstitutionVeriff() {}
  },
  activated() {
    if (this.accountType === "individual") {
      this.handleIndividualVeriff();
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);
  display: flex;
  flex-direction: column;
  align-items: center;

  .waitWrapper {
    @include rfs(4rem 8rem, padding);

    border-radius: 1rem;
    background: var(--legend-panel-bg);
    text-align: center;

    p {
      @include rfs(1.25rem);
      @include rfs(2.875rem, padding-top);

      color: var(--grey-cool);
    }

    :global {
      .ant-spin-dot {
        @include rfs(3rem);
      }
    }
  }
}

#veriff-root {
  @include rfs(12rem, min-height);
  margin: 0 auto;
  p {
    @include rfs(0.8rem);
    font-weight: 400;
  }
  a {
    @include rfs(0.8rem);
  }
}
</style>
