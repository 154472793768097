// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Veriff_wrapper_Yg86R{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}@media(min-width:1200px){.Veriff_wrapper_Yg86R{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.Veriff_wrapper_Yg86R .Veriff_waitWrapper_1MGPW{padding:calc(1.525rem + 3.3vw) calc(1.925rem + 8.1vw);border-radius:1rem;background:var(--legend-panel-bg);text-align:center}@media(min-width:1200px){.Veriff_wrapper_Yg86R .Veriff_waitWrapper_1MGPW{padding:4rem 8rem}}.Veriff_wrapper_Yg86R .Veriff_waitWrapper_1MGPW p{font-size:1.25rem;padding-top:calc(1.4125rem + 1.95vw);color:var(--grey-cool)}@media(min-width:1200px){.Veriff_wrapper_Yg86R .Veriff_waitWrapper_1MGPW p{padding-top:2.875rem}}.Veriff_wrapper_Yg86R .Veriff_waitWrapper_1MGPW .ant-spin-dot{font-size:calc(1.425rem + 2.1vw)}@media(min-width:1200px){.Veriff_wrapper_Yg86R .Veriff_waitWrapper_1MGPW .ant-spin-dot{font-size:3rem}}#Veriff_veriff-root_1Ybdh{min-height:calc(2.325rem + 12.9vw);margin:0 auto}@media(min-width:1200px){#Veriff_veriff-root_1Ybdh{min-height:12rem}}#Veriff_veriff-root_1Ybdh p{font-weight:400}#Veriff_veriff-root_1Ybdh a,#Veriff_veriff-root_1Ybdh p{font-size:.8rem}", ""]);
// Exports
exports.locals = {
	"wrapper": "Veriff_wrapper_Yg86R",
	"waitWrapper": "Veriff_waitWrapper_1MGPW",
	"veriff-root": "Veriff_veriff-root_1Ybdh"
};
module.exports = exports;
