<template>
  <section :class="$style.wrapper">
    <section :class="$style.cardHeader" @click="toTradeHistory">
      <icon-orders />
      <h5>{{ $t("Recent trades") }}</h5>
      <icon-arrow-left />
    </section>
    <section :class="$style.cardContent">
      <template v-if="dataSource.length === 0">
        <div :class="$style.recentEmpty">
          <icon-empty-order />
          <p v-html="$t('empty_messages[2]')"></p>
          <span :class="$style.link" @click="toTrade">
            {{ $t("FEED ME SOME ORDERS") }}
          </span>
        </div>
      </template>
      <template v-else>
        <div :class="$style.orderList">
          <div
            :class="$style.orderItem"
            v-for="{ pair, side, quantity, created_at, trade_id } in dataSource"
            :key="trade_id"
          >
            <div :class="$style.itemCell">
              <div :class="$style.orderPairs" v-if="side === 'sell'">
                {{ formatCrypto(pair) }}
              </div>
              <div :class="$style.orderPairs" v-if="side === 'buy'">
                {{ formatFiat(pair) }}
              </div>

              <div :class="$style.orderQuantity">
                {{ quantity }}
                <span v-if="side === 'buy'">{{ formatCrypto(pair) }}</span>
              </div>
            </div>
            <div :class="$style.itemCell">
              <div :class="$style.orderDate">{{ created_at | formatDate }}</div>
              <div
                :class="{
                  [$style.orderType]: true,
                  [$style.danger]: side === 'sell',
                  [$style.info]: side === 'buy'
                }"
              >
                {{ $t(side.toUpperCase()) }}
              </div>
            </div>
          </div>
        </div>

        <span :class="$style.link" @click="toTradeHistory">
          {{ $t("VIEW MORE") }}
        </span>
      </template>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IconOrders from "@/assets/img/trade/icon-orders.svg?inline";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";
import IconEmptyOrder from "@/assets/img/trade/icon-empty-order.svg?inline";
import { getTradeHistory } from "@/api";
import { displayErrors } from "@/utils";

export default {
  name: "RecentOrdersCard",
  data() {
    return {
      dataSource: []
    };
  },
  components: {
    IconOrders,
    IconArrowLeft,
    IconEmptyOrder
  },
  computed: {
    ...mapGetters(["isAccountEnabled"]),
    ...mapState({
      balance: state => state.balance
    }),
    formatCrypto() {
      return pair => {
        const array = pair?.split(" / ") ?? "";
        return array?.[0] ?? "";
      };
    },
    formatFiat() {
      return pair => {
        const array = pair?.split(" / ") ?? "";
        return array?.[1] ?? "";
      };
    }
  },
  watch: {
    balance: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if (this.isAccountEnabled) {
            this.fetchData();
          } else {
            this.dataSource = [];
          }
        }
      },
      deep: true
    }
  },
  filters: {},
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const result = await getTradeHistory({ limit: 3 });
        if (!result.errors) {
          this.dataSource = result;
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isLoading = false;
      }
    },
    toTradeHistory() {
      this.$router.push({ name: "trade.history" });
    },
    toTrade() {
      const { name } = this.$route;
      name !== "home.trade" && this.$router.push({ name: "home.trade" });
    }
  },
  created() {
    if (this.isAccountEnabled) {
      this.fetchData();
    } else {
      this.dataSource = [];
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(17.5rem, max-width);
  @include rfs(1.6875rem, margin-bottom);

  .cardHeader {
    @include rfs(1rem, margin-bottom);
    @include rfs(0.4375rem, padding-right);

    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--legend-c-text);

    svg:first-child {
      @include rfs(1.125rem, max-width);
    }

    h5 {
      @include rfs(0rem, margin-bottom);
      @include rfs(0.75rem, padding-left);

      line-height: 1.5625rem;
      flex: 1;
      font-weight: 600;
      color: var(--legend-c-text);
    }

    svg:last-child {
      @include rfs(0.5rem, max-height);
      @include rfs(0.9375rem, max-width);
      transform: rotate(-90deg);
      color: var(--legend-c-text);
    }
  }
  .cardContent {
    @include rfs(1.125rem 1.25rem 1.25rem, padding);

    border-radius: 0.625rem;
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--legend-panel-bg);

    .recentEmpty {
      @include rfs(11.875rem, min-height);
      @include rfs(1rem);
      display: flex;
      flex-flow: column nowrap;
      font-weight: 500;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      align-items: center;

      svg {
        @include rfs(1.25rem, margin-bottom);
      }

      p {
        @include rfs(0.875rem);
        @include rfs(0rem, margin-bottom);

        font-weight: 500;
        color: var(--legend-c-text);
      }

      .link {
        @include rfs(0.625rem, margin-top);
      }
    }

    .orderList {
      width: 100%;

      .orderItem {
        @include rfs(0.625rem, padding-bottom);
        @include rfs(0.4375rem, padding-top);

        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          border-bottom: solid 0.0625rem var(--silver-25);
        }

        .itemCell {
          &:not(:last-child) {
            @include rfs(0.1875rem, margin-bottom);
          }

          display: flex;
          justify-content: space-between;

          .orderPairs {
            @include rfs(1rem);

            font-weight: 500;
            color: var(--legend-c-text);
          }

          .orderQuantity {
            @include rfs(0.875rem);

            text-align: right;
            color: var(--legend-c-text);
            font-weight: 500;
            flex: 1;
          }

          .orderDate {
            @include rfs(0.75rem);

            color: var(--grey-cool);
            font-style: italic;
          }

          .danger {
            background: var(--success);
          }

          .info {
            background: var(--info);
          }

          .orderType {
            @include rfs(0.875rem);
            @include rfs(0 0.1875rem, padding);
            @include rfs(2.6875rem, width);
            @include rfs(1.25rem, line-height);

            align-self: flex-start;
            border-radius: 0.25rem;
            color: var(--white);
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
          }
        }

        .orderPair {
          @include rfs(1.0625rem, width);
          @include rfs(1.0625rem, height);
        }

        .orderInfo {
          @include rfs(0.75rem, margin-left);

          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }

    .link {
      @include rfs(0.875rem);
      @include rfs(1.25rem, margin-top);

      font-weight: bold;
      color: var(--primary);
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: var(--primary-3);
      }

      &:active {
        color: var(--legend-c-text);
      }
    }
  }
}
</style>
