<template>
  <section :class="$style.wrapper">
    <div :class="$style.title">
      <img
        src="../../assets/img/common/icon-reminder.png"
        alt="icon-reminder"
      />
      {{ eddFailedInfo.subject || subject }}
    </div>

    <p v-if="eddFailedInfo.message">
      {{ eddFailedInfo.message }}
    </p>

    <IconLogoBlack />
    <BaseButton @click="$emit('ok')">{{ $t("Okay") | uppercase }}</BaseButton>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import IconLogoBlack from "@/assets/img/logo-black.svg?inline";

export default {
  name: "Modal.EddError",
  components: {
    BaseButton,
    IconLogoBlack
  },
  data() {
    return {
      subject: "Order received, payment pending."
    };
  },
  props: {
    eddFailedInfo: {
      type: Object,
      default: () => {
        return { subject: "", message: "" };
      }
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 2rem 3.125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  .title {
    @include rfs(1.5rem);
    @include margin(0 0 1.75rem);

    position: relative;
    color: #df943e;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      @include rfs(2rem, width);
      position: absolute;
      top: 0;
      left: -3rem;
    }
  }

  p {
    @include rfs(1rem);
    @include rfs(1.75rem, margin-bottom);

    width: 100%;
    text-align: left;
    color: var(--grey-cool);
  }

  svg {
    width: 9.375rem;

    @include rfs(1.75rem, margin-bottom);
  }
}
</style>
