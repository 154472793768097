import Vue from "vue";
import Router from "vue-router";
import {
  KycRouter,
  TradeRouter,
  WithdrawRouter,
  AdditionalKycRouter,
  CreditCardRouter
} from "./subroute/index";
import DepositRouter from "./deposit";
import RegisterIndex from "../views/register/Index.vue";
import AdditionalKyc from "../views/additionalKyc/Index.vue";
import Home from "../views/Home.vue";
import TradeIndex from "../views/trade/Index.vue";
import externalCheckout from "../views/external/checkout/Index.vue";
import store from "../store";

Vue.use(Router);
const router = new Router({
  mode: "abstract",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      meta: { requireOtcAccount: true },
      component: Home,
      children: [
        {
          path: "",
          name: "home",
          component: () =>
            import(
              /* webpackChunkName: "trade" */ "../views/trade/TradeEntry.vue"
            )
        },
        {
          path: "trade",
          component: TradeIndex,
          children: TradeRouter
        },
        {
          path: "withdraw",
          component: () =>
            import(
              /* webpackChunkName: "withdraw" */ "../views/withdraw/Index.vue"
            ),
          children: WithdrawRouter
        }
      ]
    },
    {
      path: "/register",
      component: RegisterIndex,
      children: KycRouter
    },
    {
      path: "/kyc",
      component: AdditionalKyc,
      children: AdditionalKycRouter
    },
    {
      path: "/payment",
      name: "payment",
      meta: { requireOtcAccount: true },
      component: () =>
        import(/* webpackChunkName: "payment" */ "../views/payment/Index.vue")
    },
    {
      path: "/payment/wire",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "payment" */ "../views/payment/wireTransfer/Index.vue"
        ),
      children: [
        {
          path: "",
          name: "payment.wire",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/wireTransfer/ChooseCurrency.vue"
            )
        },
        {
          path: "/payment/wire/enterBankDetails",
          name: "payment.wire.enterBankDetails",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/wireTransfer/EnterBankDetails.vue"
            )
        },
        {
          path: "/payment/wire/confirmBankDetails",
          name: "payment.wire.confirmBankDetails",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/wireTransfer/ConfirmBankDetails.vue"
            )
        },
        {
          path: "/payment/wire/wireFinished",
          name: "payment.wire.wireFinished",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/wireTransfer/WireFinished.vue"
            )
        },
        {
          path: "/payment/achPull/ssnInfo",
          name: "payment.achPull.ssnInfo",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/achPull/SsnInfo.vue"
            ),
          meta: {
            type: "achPull_in_payment",
            title: "",
            showBackBtn: false
          }
        },
        {
          path: "/payment/achPull/verifyIdentify",
          name: "payment.achPull.verifyIdentify",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/achPull/VerifyIdentify.vue"
            ),
          meta: {
            type: "achPull_in_payment",
            title: "VERIFY IDENTITY",
            showBackBtn: false
          }
        },
        {
          path: "/payment/achPull/autoVerify",
          name: "payment.achPull.autoVerify",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/achPull/AutoVerify.vue"
            ),
          meta: {
            type: "achPull_in_payment",
            title: "VERIFY YOUR IDENTITY",
            showBackBtn: false
          }
        },
        {
          path: "/payment/achPull/verifyFailed",
          name: "payment.achPull.verifyFailed",
          component: () =>
            import(
              /* webpackChunkName: "payment" */ "../views/payment/achPull/VerifyFailed.vue"
            ),
          meta: {
            type: "achPull_in_payment",
            title: "VERIFY YOUR IDENTITY",
            showBackBtn: false
          }
        }
      ]
    },
    {
      path: "/payment/card",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "payment" */ "../views/payment/creditCard/Index.vue"
        ),
      children: CreditCardRouter
    },
    {
      path: "/payment/ach",
      name: "payment.ach",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "payment" */ "../views/payment/bankTransfer/Index.vue"
        )
    },
    {
      path: "/plaid",
      name: "plaid",
      meta: { requireOtcAccount: true },
      component: () =>
        import(/* webpackChunkName: "payment" */ "../views/plaid/Index.vue")
    },
    {
      path: "/paymentList",
      name: "account.paymentList",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../views/account/PaymentList.vue"
        )
    },
    {
      path: "/bankDetails",
      name: "account.bankDetails",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../views/account/BankDetails.vue"
        )
    },
    {
      path: "/userinfo",
      name: "account.userinfo",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../views/account/UserInfo.vue"
        )
    },
    {
      path: "/trade/history",
      name: "trade.history",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "trade" */ "../views/trade/TradeHistory.vue"
        )
    },
    {
      path: "/transfer/history",
      name: "transfer.history",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "trade" */ "../views/trade/TransferHistory.vue"
        )
    },
    {
      path: "/maintain",
      name: "maintain",
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/Maintain.vue")
    },
    {
      path: "/faq",
      name: "faq",
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/Faq.vue")
    },
    {
      path: "/faq/detail",
      name: "faq.detail",
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/FaqDetail.vue")
    },
    {
      path: "/balance",
      name: "account.balance",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../views/account/BalanceList.vue"
        )
    },
    {
      path: "/refuses/:error?",
      name: "refuses",
      meta: { requireOtcAccount: true },
      props: true,
      component: () =>
        import(/* webpackChunkName: "account" */ "../views/Refuses.vue")
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: () =>
        import(/* webpackChunkName: "account" */ "../views/Forbidden.vue")
    },
    {
      path: "/restriction",
      name: "restriction",
      component: () =>
        import(/* webpackChunkName: "account" */ "../views/Restriction.vue")
    },
    {
      path: "/external/checkout",
      name: "external.checkout",
      component: externalCheckout,
      meta: {
        type: "credit_card_in_external_checkout",
        title: "",
        showBackBtn: false
      }
    },
    ...DepositRouter,
    {
      path: "*",
      redirect: "/register"
    }
  ]
});

// trigger init route
router.push("/register");

router.afterEach(() => {
  window.scrollTo(0, 0);
});

store.watch(
  state => state.nextKycStep,
  nextKycStep => {
    nextKycStep === "redo_verify_id" &&
      router.push({ name: "individual.verifyIdentify" });
  }
);

export default router;
