import {
  UPDATE_CONFIG_ENV,
  UPDATE_CONFIG_TOKEN,
  UPDATE_CONFIG_REMOTE,
  UPDATE_CONFIG_MODE,
  UPDATE_CONFIG_EXTRA
} from "./mutation-types";

// inital state
const state = {
  token: "",
  envConfig: {
    development: "qaapi",
    sandbox: "sandboxapi",
    production: "api"
  },
  domain: "legendtrading.com",
  env: process.env.NODE_ENV,
  mode: "",
  reHostname: /^localhost|127\.0\.0\.1|::1$/i,
  extraConfig: {
    development: { checkout: "pk_sbox_krbmse35h3a6njiilb7rpigdteu" },
    sandbox: { checkout: "pk_sbox_krbmse35h3a6njiilb7rpigdteu" },
    production: { checkout: "pk_2fewwrbxyyvlq4btvxm63fkm4er" }
  },
  environment: "qa", // 默认写死qa,请参考legend-demo中该接口的返回,https://demo-dev.legendtrading.com/api/v1/demo/environment
  demoDevBaseUrl: "https://demo-dev.legendtrading.com/api/v1" // 在jssdk中使用login和sign接口,也只在本地开发中使用，所以只用demo-dev.中的login和sign接口就够了。
};
// getters
const getters = {
  baseUrl: state => {
    const { envConfig, domain, env } = state;
    if (envConfig.development === "mock") {
      return `https://devapi.${domain}`;
    } else {
      return `https://${envConfig[env]}.${domain}`;
    }
  },
  baseUrlTrade: (state, { baseUrl }) => {
    return `${baseUrl}/v3/ex/`;
  },
  baseUrlKyc: (state, { baseUrl }) => {
    return `${baseUrl}/v3/kyc/`;
  },
  baseUrlPlaid: (state, { baseUrl }) => {
    return `${baseUrl}/v3/ex/plaid/`;
  },
  baseUrlCommon: (state, { baseUrl }) => {
    return `${baseUrl}/v2/common`; // 新版KYC的都是v2开头的
  },
  baseUrlKycV2: (state, { baseUrl }) => {
    return `${baseUrl}/v2/kyc`; // 新版KYC的都是v2开头的
  },
  hoseName: state => {
    return state.reHostname;
  },
  baseRemote: state => {
    return state.envConfig.development;
  },
  checkoutKey: state => {
    const { extraConfig, env } = state;
    return extraConfig[env].checkout;
  },
  getEnvironment: state => state.environment,
  getDemoDevBaseUrl: state => state.demoDevBaseUrl
};
// actions
const actions = {
  updateConfigMode({ commit }, data) {
    commit(UPDATE_CONFIG_MODE, data);
  },
  updateConfigEnv({ commit }, data) {
    commit(UPDATE_CONFIG_ENV, data);
  },
  updateConfigToken({ commit }, data) {
    commit(UPDATE_CONFIG_TOKEN, data);
  },
  updateConfigRemote({ commit }, data) {
    commit(UPDATE_CONFIG_REMOTE, data);
  },
  updateConfigExtra({ commit }, data) {
    commit(UPDATE_CONFIG_EXTRA, data);
  }
};
// mutations
const mutations = {
  [UPDATE_CONFIG_ENV](state, value) {
    state.env = value;
  },
  [UPDATE_CONFIG_TOKEN](state, value) {
    state.token = value;
  },
  [UPDATE_CONFIG_REMOTE](state, value) {
    state.envConfig.development = value;
  },
  [UPDATE_CONFIG_MODE](state, value) {
    state.mode = value;
  },
  [UPDATE_CONFIG_EXTRA](state, value) {
    const { env, extraConfig } = state;
    state.extraConfig = {
      ...extraConfig,
      [env]: {
        ...extraConfig[env],
        ...value
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
