<template>
  <section :class="$style.wrapper">
    <h2 v-html="$t('modal_messsages.card_daily_limit.title')"></h2>

    <p
      v-html="
        $t('modal_messsages.card_daily_limit.content', {
          currencyUnit: currencyUnitList[asset],
          maxLimit
        })
      "
    />

    <BaseButton type="primary" @click="handleClick">{{
      $t("Okay") | uppercase
    }}</BaseButton>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Modal.CardDailyLimit",
  components: {
    BaseButton
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      asset: "asset"
    }),
    ...mapState(["currencies", "currencyUnitList"]),
    maxLimit() {
      const { daily_max_credit_card_limit = 100000 } = this.currencies.find(
        item => item.currency === this.asset
      );
      return daily_max_credit_card_limit;
    }
  },
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--legend-c-text);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }
}
</style>
