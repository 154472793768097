<template>
  <section :class="$style.wrapper">
    <div :class="$style.title">
      <img
        src="../../assets/img/common/icon-reminder.png"
        alt="icon-reminder"
      />
      {{ $t("edd_pending_deposit.title") }}
    </div>
    <p>
      {{ $t("edd_pending_deposit.content[0]") }}
    </p>

    <i18n path="edd_pending_deposit.content[1]" tag="p">
      <template #amount>
        <span>{{ amount }}</span>
      </template>
    </i18n>

    <i18n path="edd_pending_deposit.content[2]" tag="p">
      <template #email>
        <span>{{ email }}</span>
      </template>
    </i18n>

    <i18n path="edd_pending_deposit.content[3]" tag="p">
      <template #verifiedBy>
        <span>
          {{ verifiedBy }}({{ $t("edd_pending_deposit.content[4]") }})
        </span>
      </template>
      <template #returnedOn>
        <span>
          {{ returnedOn }}({{ $t("edd_pending_deposit.content[4]") }})
        </span>
      </template>
    </i18n>

    <p>
      {{ $t("edd_pending_deposit.content[5]") }}
    </p>
    <IconLogoBlack />
    <BaseButton @click="handleClick">{{ $t("Okay") | uppercase }}</BaseButton>
  </section>
</template>

<script>
import { mapState } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import IconLogoBlack from "@/assets/img/logo-black.svg?inline";

export default {
  name: "Modal.EddPendingDeposit",
  components: {
    BaseButton,
    IconLogoBlack
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    handleClick() {
      this.$emit("ok");
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    amount() {
      return this.userInfo?.edd?.amount ?? "0";
    },
    email() {
      return this.userInfo?.edd?.email ?? "";
    },
    verifiedBy() {
      const date = this.userInfo?.edd?.verified_by ?? null;
      const formatDate = this.$options.filters.formatDate(date);
      return formatDate;
    },
    returnedOn() {
      const date = this.userInfo?.edd?.returned_on ?? null;
      const formatDate = this.$options.filters.formatDate(date);
      return formatDate;
    }
  }
};
</script>
<style lang="scss" module>
@import "~rfs/scss";

.wrapper {
  @include rfs(2.5rem 2rem 3.125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-component-bg);
  color: var(--dark);

  .title {
    @include rfs(1.5rem);
    @include margin(0 0 1.75rem);

    position: relative;
    color: #df943e;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      @include rfs(2rem, width);
      position: absolute;
      top: 0;
      left: -3rem;
    }
  }
  p {
    @include rfs(1rem);

    width: 100%;
    text-align: left;
    span {
      color: #4fad5b;
      font-weight: 500;
    }
  }

  svg {
    width: 9.375rem;

    @include rfs(1.75rem, margin-bottom);
  }
}
</style>
