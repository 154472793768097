// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IncreaseLimit_wrapper_3tdA5{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--legend-component-bg)}@media(min-width:1200px){.IncreaseLimit_wrapper_3tdA5{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.IncreaseLimit_wrapper_3tdA5>h2{margin-bottom:calc(1.3rem + .6vw);color:var(--legend-c-text);text-align:center}@media(min-width:1200px){.IncreaseLimit_wrapper_3tdA5>h2{margin-bottom:1.75rem}}.IncreaseLimit_wrapper_3tdA5 p{font-size:1.25rem;margin-bottom:calc(1.3rem + .6vw);text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.IncreaseLimit_wrapper_3tdA5 p{margin-bottom:1.75rem}}.IncreaseLimit_wrapper_3tdA5 .IncreaseLimit_baseButton_Gk4yD{margin-bottom:calc(1.3125rem + .75vw)}@media(min-width:1200px){.IncreaseLimit_wrapper_3tdA5 .IncreaseLimit_baseButton_Gk4yD{margin-bottom:1.875rem}}.IncreaseLimit_wrapper_3tdA5 p:last-child{font-size:.875rem;margin-bottom:0;font-weight:700;text-decoration:underline;cursor:pointer;color:var(--primary)}.IncreaseLimit_wrapper_3tdA5 p:last-child:hover{color:var(--primary-3)}.IncreaseLimit_wrapper_3tdA5 p:last-child:active{color:var(--legend-c-text)}", ""]);
// Exports
exports.locals = {
	"wrapper": "IncreaseLimit_wrapper_3tdA5",
	"baseButton": "IncreaseLimit_baseButton_Gk4yD"
};
module.exports = exports;
