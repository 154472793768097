<template>
  <section :class="$style.wrapper">
    <template v-if="isSpinning">
      <h2>
        {{ $t("Processing your order") }}
      </h2>

      <a-spin :spinning="isSpinning">
        <a-icon slot="indicator" :component="IconWait" spin />
      </a-spin>

      <p :class="$style.loadingTips">{{ $t("Please wait") }}</p>
    </template>
    <template v-else>
      <h2>
        {{ $t("You purchased") }} <br />
        {{ getReceiveCount }}
      </h2>
      <icon-success :class="$style.iconSuccess" />
      <p>
        {{ $t("modal_messsages.purchased_mediately.content") }}
      </p>

      <BaseButton
        type="primary"
        :class="$style.baseButton"
        @click="handleClick"
      >
        {{ $t("OK") }}
        <icon-arrow-left />
      </BaseButton>

      <label @click="toTradeHistory">
        {{ $t("Check order history") }}
      </label>
    </template>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Spin, Icon } from "ant-design-vue";
import BaseButton from "@/components/BaseButton.vue";
import IconSuccess from "@/assets/img/common/icon-success.svg?inline";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";

export default {
  name: "Modal.PurchasedMediately",
  components: {
    [Spin.name]: Spin,
    [Icon.name]: Icon,
    BaseButton,
    IconSuccess,
    IconArrowLeft
  },
  data() {
    return {
      IconWait,
      IconArrowLeft
    };
  },
  computed: {
    ...mapState(["tradePair", "currencies"]),
    ...mapGetters({
      asset: "asset"
    }),
    isSpinning() {
      const { spinning = false } = this.$attrs;
      return spinning;
    },
    getReceiveCount() {
      const quantity = this.$attrs.orderConfirmInfo.quantity;
      return `${quantity} ${this.tradePair}`;
    }
  },
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    },
    toTradeHistory() {
      this.$emit("navigate");
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--legend-c-text);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);
    @include rfs(1.25rem, margin-top);

    text-align: center;
    color: var(--grey-cool);
  }

  .iconSuccess {
    @include rfs(4.375rem, max-width);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }

  label {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);

    &:hover {
      color: var(--primary-3);
    }

    &:active {
      color: var(--legend-c-text);
    }
  }

  .loadingTips {
    @include rfs(2.875rem, margin-top);
    @include rfs(0.5rem, margin-bottom);
  }

  :global {
    .ant-spin-dot {
      @include rfs(3.125rem);
    }
  }
}
</style>
