// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l{max-width:calc(3.75rem + 30vw);margin:auto}@media(min-width:1200px){.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l{max-width:26.25rem}}.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_news_15m2x{margin:.0625rem 0 calc(-1.525rem - 3.3vw);color:var(--primary)}@media(min-width:1200px){.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_news_15m2x{margin:.0625rem 0 -4rem}}.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_desc_1dWLq{margin:calc(1.9375rem + 8.25vw) 0 calc(1.6875rem + 5.25vw) 0}@media(min-width:1200px){.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_desc_1dWLq{margin:8.125rem 0 5.625rem 0}}.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_desc_1dWLq svg{margin-bottom:calc(1.375rem + 1.5vw)}@media(min-width:1200px){.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_desc_1dWLq svg{margin-bottom:2.5rem}}.EnterEmail_wrapper_2_0Qx .EnterEmail_container_2fB9l .EnterEmail_desc_1dWLq span{font-size:.875rem;color:var(--grey-cool);display:block}", ""]);
// Exports
exports.locals = {
	"wrapper": "EnterEmail_wrapper_2_0Qx",
	"container": "EnterEmail_container_2fB9l",
	"news": "EnterEmail_news_15m2x",
	"desc": "EnterEmail_desc_1dWLq"
};
module.exports = exports;
