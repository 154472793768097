// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dashboard_wrapper_NWqTU h6{font-size:1rem;margin-bottom:1.25rem;font-weight:500;color:var(--legend-c-text)}", ""]);
// Exports
exports.locals = {
	"wrapper": "Dashboard_wrapper_NWqTU"
};
module.exports = exports;
