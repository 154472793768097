import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=96b0f618"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Index.vue?vue&type=style&index=0&id=96b0f618&prod&module=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)
this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports