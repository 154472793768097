<template>
  <section :class="$style.accountStatus">
    <section :class="$style.container">
      <section :class="$style.content">
        <a-timeline>
          <a-timeline-item @click="handleVerify">
            <span slot="dot" :class="verifyClass" v-if="!!verifyClass" />
            <div :class="[$style.timelineItem, $style.verifyText]">
              <span
                :class="[
                  nextKycStep === 'enter_bank'
                    ? $style.hasVerifyTitle
                    : $style.noVerifyTitle
                ]"
                >{{ $t("Verify your identity") }}</span
              >
              <span :class="$style.verifyStatus">{{ verifyStatusText }}</span>
            </div>
          </a-timeline-item>
          <template v-if="allowAchPull">
            <a-timeline-item @click="handleLink">
              <span slot="dot" v-if="hasDeposit" />
              <div :class="[$style.timelineItem, $style.depositText]">
                <span>{{ getLinkBankText }}</span>
              </div>
            </a-timeline-item>
          </template>
          <template v-else>
            <a-timeline-item @click="handleDeposit">
              <span slot="dot" v-if="hasDeposit" />
              <div :class="[$style.timelineItem, $style.depositText]">
                <span>{{ $t("Make a deposit") }}</span>
              </div>
            </a-timeline-item>
          </template>
        </a-timeline>

        <h6>
          {{ $t("Account Status Tips") }}
        </h6>
      </section>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Timeline } from "ant-design-vue";
import { veriffMixin } from "@/mixin/veriffMixin";
import { commonMixin } from "@/mixin/commonMixin";

export default {
  name: "AccountStatus",
  components: {
    [Timeline.name]: Timeline,
    [Timeline.Item.name]: Timeline.Item
  },
  data() {
    return {
      paymentList: []
    };
  },
  mixins: [veriffMixin, commonMixin],
  computed: {
    ...mapState(["kycStatus", "nextKycStep", "hasLinkBankAccount"]),
    ...mapGetters({
      balance: "balance",
      isVerifyFinished: "isVerifyFinished",
      allowAchPull: "allowAchPull"
    }),
    verifyClass() {
      if (this.nextKycStep === "reviewing_id") {
        return this.$style.verifyPendingDot;
      } else if (this.nextKycStep === "verify_id") {
        return this.$style.verifyStartedDot;
      } else if (this.nextKycStep === "enter_bank") {
        return this.$style.verifyFinishedDot;
      } else {
        return "";
      }
    },
    verifyStatusText() {
      if (this.nextKycStep === "reviewing_id") {
        return "Pending";
      } else {
        return "";
      }
    },
    hasFinishedVerify() {
      return this.nextKycStep === "enter_bank";
    },
    hasDeposit() {
      //不会出现绿点的情况呀
      return false;
    },
    getLinkBankText() {
      if (this.hasLinkBankAccount) {
        return this.$t("Make a deposit");
      } else {
        return this.$t("Link Your Bank Account");
      }
    }
  },
  methods: {
    ...mapActions(["updateModalView", "updateBaseModalVisible"]),
    handleVerify() {
      this.showGlobalModal();
    },
    async handleDeposit() {
      const isShowGlobalModal = this.showGlobalModal();
      if (!isShowGlobalModal) return;
      this.$router.push({ name: "deposit.wire.transfer" });
    },
    handleLink() {
      const isShowGlobalModal = this.showGlobalModal();
      if (!isShowGlobalModal) return;
      this.$router.push({ name: "deposit.wire.transfer" });
    }
  },
  activated() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.accountStatus {
  @include rfs(1.6875rem, margin-bottom);

  .container {
    @include rfs(17.5rem, max-width);
    @include rfs(10rem, min-height);
    @include rfs(0.25rem, padding);
    @include rfs(2.6rem, margin-top);

    background: var(--legend-panel-bg);
    border-radius: 0.625rem;
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    .content {
      @include rfs(1.875rem 0.5rem 1.25rem 1.25rem, padding);

      border-radius: 0.625rem;
      box-shadow: inset 0 0 0 0.125rem var(--primary);

      .verifyFinishedDot {
        @include rfs(0.5625rem, width);
        @include rfs(0.5625rem, height);

        display: block;
        border-radius: 50%;
        background: var(--success);
        box-shadow: 0 0 0 0.2rem var(--white), 0 0 0 0.4rem var(--success);
        top: 0px;
        left: 0px;
        position: absolute;
      }

      .verifyPendingDot {
        @include rfs(0.5625rem, width);
        @include rfs(0.5625rem, height);

        display: block;
        border-radius: 50%;
        background: var(--yellow);
        box-shadow: 0 0 0 0.2rem var(--white), 0 0 0 0.4rem var(--yellow);
        top: 0px;
        left: 0px;
        position: absolute;
      }

      .verifyStartedDot {
        @include rfs(0.5625rem, width);
        @include rfs(0.5625rem, height);

        display: block;
        border-radius: 50%;
        background: var(--silver);
        position: absolute;
        top: 0;
        left: 0;
      }

      .timelineItem {
        @include rfs(0.875rem);
        @include rfs(1rem, margin-left);

        font-weight: 500;
        cursor: pointer;
      }

      .verifyText {
        .hasVerifyTitle {
          color: var(--grey-cool-light);
        }
        .noVerifyTitle {
          color: var(--info);

          text-decoration: underline;
          display: inline-block;
          &:hover {
            color: var(--primary-3);
          }

          &:active {
            color: var(--dark);
          }
        }
        .verifyStatus {
          @include rfs(0.5rem, margin-left);

          color: var(--grey-cool-light);
        }
      }

      .depositText {
        color: var(--dark);

        span {
          color: var(--info);
          text-decoration: underline;

          &:hover {
            color: var(--primary-3);
          }

          &:active {
            color: var(--dark);
          }
        }
      }

      h6 {
        @include rfs(1rem, margin-top);
        @include rfs(1rem, margin-right);

        @include rfs(0rem, margin-bottom);

        text-align: center;
        font-weight: bold;
        color: var(--primary);
      }
    }
  }

  :global {
    .ant-timeline-item {
      @include rfs(0rem, padding-bottom);

      &:not(:last-child) {
        @include rfs(0.9375rem, padding-bottom);
      }
    }

    .ant-timeline-item-head {
      @include rfs(0.5625rem, width);
      @include rfs(0.5625rem, height);

      background-color: var(--silver);
      border: none;
      border-radius: 50%;
    }

    .ant-timeline-item-tail {
      border-left: 0.0625rem solid var(--silver);
    }

    .ant-timeline-item-content {
      min-height: auto;
    }
  }
}
</style>
