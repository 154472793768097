<template>
  <section :class="$style.uiQuery">
    <template v-if="isLoading">
      <a-spin>
        <a-icon slot="indicator" :component="IconWait" spin />
      </a-spin>
    </template>
    <template v-else>
      <slot />
    </template>
  </section>
</template>

<script>
import { Spin, Icon } from "ant-design-vue";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";

export default {
  name: "UIQuery",
  props: {
    isLoading: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      IconWait
    };
  },
  computed: {},
  components: {
    [Icon.name]: Icon,
    [Spin.name]: Spin
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.uiQuery {
  :global {
    .ant-spin-dot {
      @include rfs(3rem);
    }
  }

  //   .spin {
  //     @include rfs(2.5rem 0rem 6.875rem 0rem, padding);

  //     background-color: var(--legend-panel-bg);
  //     border-radius: 0.625rem;
  //     box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
  //   }
}
</style>
