<template>
  <section :class="$style.checkBoxWrapper" @click="onHandleChange">
    <div :class="$style.chkIcon">
      <icon-checked v-if="checked" />
    </div>
    <div :class="$style.chkTitle">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import IconChecked from "@/assets/img/common/icon-success.svg?inline";

export default {
  name: "BaseCheckBox",
  props: {
    checked: {
      type: [Boolean],
      default: false
    }
  },
  components: {
    IconChecked
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    onHandleChange() {
      this.$emit("click", this.checked);
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.checkBoxWrapper {
  display: flex;
  cursor: pointer;

  .chkIcon {
    @include rfs(0.125rem, margin-top);
    @include rfs(1.3125rem, min-width);
    @include rfs(1.3125rem, width);
    @include rfs(1.3125rem, height);

    border-radius: 0.125rem;
    background: var(--legend-component-bg);
    border: solid 0.0625rem var(--silver);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .chkTitle {
    @include rfs(0.875rem, margin-left);

    color: currentColor;
    text-align: left;
  }
}
</style>
