import {
  required,
  email,
  numeric,
  alpha_num,
  alpha_dash,
  min,
  max
} from "vee-validate/dist/rules";
import { extend, configure } from "vee-validate";
import i18n from "./i18n";
import moment from "moment";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    switch (values._rule_) {
      case "required": {
        return i18n.t("is Empty", [values._field_]);
      }
      case "email": {
        return i18n.t("Incorrect email format");
      }
      case "numeric": {
        return i18n.t("please enter numeric");
      }
      case "alpha_num":
      case "alpha_dash": {
        return i18n.t("Incorrect passport format");
      }
      case "min": {
        return i18n.t(
          `The ${field} field must be at least ${values.length} characters`
        );
      }
      case "max": {
        return i18n.t(
          `The ${field} field may not be greater than ${values.length} characters`
        );
      }
    }
  }
});

extend("required", required);

extend("email", email);

extend("numeric", numeric);

extend("alpha_num", alpha_num);

extend("alpha_dash", alpha_dash);

extend("min", min);

extend("max", max);

extend("stateCheck", value => {
  const invalidArray = ["NY", "NEWYORK", "纽约"];
  let bRet = true;
  invalidArray.forEach(item => {
    let tempValue = value.replace(/\s*/g, "");
    if (item === tempValue.toUpperCase()) {
      bRet = false;
    }
  });
  if (!bRet) {
    return i18n.t("State Check MSG");
  }
  return true;
});

extend("cityCheck", value => {
  const invalidArray = ["NY", "NEWYORK", "纽约", "NEWYORKCITY"];
  let bRet = true;
  invalidArray.forEach(item => {
    let tempValue = value.replace(/\s*/g, "");
    if (item === tempValue.toUpperCase()) {
      bRet = false;
    }
  });
  if (!bRet) {
    return i18n.t("State Check MSG");
  }
  return true;
});

extend("alpha_address", value => {
  // const reg = /^[\u4e00-\u9fa5]+$/;
  const reg = /\p{Unified_Ideograph}/u;
  if (reg.test(value)) {
    return i18n.t("Receipt Address");
  }
  return true;
});

extend("alpha_num_space", value => {
  const reg = /^[A-Za-z0-9 ]+$/;
  if (!reg.test(value)) {
    return i18n.t("Receipt Bank Account Number");
  }
  return true;
});

extend("alpha_amount", {
  validate(value, { min }) {
    const reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/;
    if (!reg.test(value)) {
      return i18n.t("Incorrect amount format");
    } else if (parseFloat(value) > parseFloat(min)) {
      // return i18n.t(`limits_amount_tips`);
      return "limits_amount_tips"; //返回多语言字典的key值
    }
    return true;
  },
  params: ["min"]
});

extend("withdraw_amount", {
  validate(value, { min }) {
    const reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/;
    if (!reg.test(value)) {
      return i18n.t("Incorrect amount format");
    } else if (parseFloat(value) === 0) {
      return i18n.t("Incorrect amount format");
    } else if (parseFloat(value) > parseFloat(min)) {
      return i18n.t("withdraw_amount_error_msg");
    }
    return true;
  },
  params: ["min"]
});

extend("is_adult", value => {
  const isAdult = moment(new Date()).isAfter(moment(value).add(18, "years"));
  if (!isAdult) {
    return i18n.t("is_adult_msg");
  }
  return true;
});

extend("cpf_rule", {
  validate(value, { length }) {
    if (parseFloat(value.length) !== parseFloat(length)) {
      return i18n.t("brl_id_err_msg", { idType: "CPF", length });
    }
    return true;
  },
  params: ["length"]
});

extend("cnpj_rule", {
  validate(value, { length }) {
    if (parseFloat(value.length) !== parseFloat(length)) {
      return i18n.t("brl_id_err_msg", { idType: "CNPJ", length });
    }
    return true;
  },
  params: ["length"]
});

extend("rfc_rule", {
  validate(value, { min }) {
    if (parseFloat(value.length) < parseFloat(min)) {
      return i18n.t("mxn_id_err_msg", { idType: "RFC", min });
    }
    return true;
  },
  params: ["min"]
});

extend("curp_rule", {
  validate(value, { min }) {
    if (parseFloat(value.length) < parseFloat(min)) {
      return i18n.t("mxn_id_err_msg", { idType: "CURP", min });
    }
    return true;
  },
  params: ["min"]
});

extend("is_ssn", value => {
  const reg = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
  if (!reg.test(value)) {
    return i18n.t("Incorrect ssn format");
  }
  return true;
});

extend("is_date", {
  validate(value, { format }) {
    const arr = value.split("-"); // YYYY-MM-DD split("-")小于3说明不是一个完整的日期，不去判断
    if (arr.length < 3) return false;

    const isValid = moment(value, format, true).isValid();
    if (!isValid) {
      return i18n.t("Incorrect date format");
    }
    return true;
  },
  params: ["format"]
});

extend("fileRequired", value => {
  if (!value || value.length === 0) {
    return i18n.t("please upload file");
  }
  return true;
});

// extend('fileRequired', (value) => {
//   if (!value || value.length === 0) {
//     return '文件是必填项';
//   }
//   return true;
// });
