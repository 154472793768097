import KycBuyRouter from "./register/buy";
import RegisterDeposit from "./register/deposit";
import RegisterCreditCardRouter from "./register/creditCard";
import Dashboard from "../../views/register/Dashboard.vue";
import EnterEmail from "../../views/register/individual/EnterEmail.vue";

const routes = [
  {
    path: "",
    name: "register",
    component: Dashboard
  },
  {
    path: "/register/pending",
    name: "register.pending",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/Pending.vue"
      )
  },
  {
    path: "/individual/personalInfo",
    name: "individual.personalInfo",
    meta: {
      type: "individual_kyc",
      title: "BASIC INFORMATION",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/PersonalInfo.vue"
      )
  },
  {
    path: "/individual/enterIdentifyNumber",
    name: "individual.enterIdentifyNumber",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/thirdParty/EnterIdentifyNumber.vue"
      )
  },
  {
    path: "/individual/userAddress",
    name: "individual.userAddress",
    meta: {
      type: "individual_kyc",
      title: "ENTER YOUR PRIMARY ADDRESS",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/UserAddress.vue"
      )
  },
  {
    path: "/individual/enterEmail",
    name: "individual.enterEmail",
    component: EnterEmail
  },
  {
    path: "/individual/verifyEmail",
    name: "individual.verifyEmail",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/VerifyEmail.vue"
      )
  },
  {
    path: "/individual/phoneInfo",
    name: "individual.phoneInfo",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/PhoneInfo.vue"
      ),
    meta: {
      type: "individual_kyc",
      title: "Mobile Phone",
      showBackBtn: true
    }
  },
  {
    path: "/individual/verifyPhone",
    name: "individual.verifyPhone",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/VerifyPhone.vue"
      )
  },
  {
    path: "/individual/kycSurvey",
    name: "individual.kycSurvey",
    meta: {
      type: "individual_kyc",
      title: "SURVEY",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/KycSurvey.vue"
      )
  },
  {
    path: "/individual/verifyIdentify",
    name: "individual.verifyIdentify",
    meta: {
      type: "individual_kyc",
      title: "Verify your identity",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/VerifyIdentify.vue"
      )
  },
  {
    path: "/individual/autoVerify",
    name: "individual.autoVerify",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/AutoVerify.vue"
      )
  },
  {
    path: "/individual/verifyFailed",
    name: "individual.verifyFailed",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/VerifyFailed.vue"
      ),
    meta: {
      type: "individual_kyc",
      showBackBtn: false
    }
  },
  {
    path: "/individual/verifySuccess",
    name: "individual.verifySuccess",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/VerifySuccess.vue"
      ),
    meta: {
      type: "individual_kyc",
      showBackBtn: false
    }
  },
  {
    path: "/individual/buyConfirm",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/BuyConfirm.vue"
      ),
    children: KycBuyRouter
  },
  {
    path: "/individual/achPull/ssnInfo",
    name: "individual.achPull.ssnInfo",
    meta: {
      title: "LINK YOUR BANK ACCOUNT",
      type: "achPull_in_kyc"
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/achPull/SsnInfo.vue"
      )
  },
  {
    path: "/individual/achPull/verifyIdentify",
    name: "individual.achPull.verifyIdentify",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/register/individual/achPull/VerifyIdentify.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      title: "VERIFY YOUR IDENTITY",
      showBackBtn: true
    }
  },
  {
    path: "/individual/achPull/autoVerify",
    name: "individual.achPull.autoVerify",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/register/individual/achPull/AutoVerify.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      title: "VERIFY YOUR IDENTITY",
      showBackBtn: true
    }
  },
  {
    path: "/individual/achPull/verifySuccess",
    name: "individual.achPull.verifySuccess",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/register/individual/achPull/VerifySuccess.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      title: "",
      showBackBtn: true
    }
  },
  {
    path: "/individual/achPull/verifyFailed",
    name: "individual.achPull.verifyFailed",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/register/individual/achPull/VerifyFailed.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      showBackBtn: true
    }
  },
  {
    path: "/individual/achPull/verifyEmail",
    name: "individual.achPull.verifyEmail",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/register/individual/achPull/VerifyEmail.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      showBackBtn: false
    }
  },
  {
    path: "/individual/achPull/orderSummary",
    name: "individual.achPull.orderSummary",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/register/individual/achPull/OrderSummary.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      showBackBtn: false
    }
  },
  ...RegisterDeposit,
  ...RegisterCreditCardRouter
];

export default routes;
