<template>
  <section :class="$style.wrapper">
    <h6>{{ $t("How much?") | uppercase }}</h6>

    <a-input
      :class="
        getUnitLen >= 2 ? $style.quantityInputLarge : $style.quantityInputSmall
      "
      v-model="quantity"
      @pressEnter="handleReviewRfq()"
    >
      <template slot="prefix">
        {{ currencyUnitList[asset] }}
      </template>
      <template slot="suffix">
        <label :class="$style.maxCrypto" @click="inputMaxAmount">
          {{ $t("MAX") }}
        </label>
      </template>
      <a-select
        slot="addonAfter"
        style="min-width: 125px;"
        :dropdownMatchSelectWidth="false"
        :getPopupContainer="() => $root.$el"
        :dropdownClassName="$style.currencyDropdown"
        v-model="currency"
      >
        <a-icon slot="suffixIcon" :component="CaretDown" />

        <a-select-option v-for="item in getAssetList" :key="item">
          <span class="fi" :class="flagClass(item)"></span>
          <span :class="$style.currencyItem">
            {{ item }}
          </span>
        </a-select-option>
      </a-select>
    </a-input>

    <div v-if="errors">
      <div :class="$style.errorMsg">
        <icon-error />
        <span>{{ errors }}</span>
      </div>
    </div>

    <div :class="$style.approx">
      <span>{{ $t("approx") }} {{ approxAmount }}</span>
      <a-spin :spinning="approxLoading">
        <a-icon slot="indicator" type="loading" style="font-size: 12px" spin />
      </a-spin>
    </div>

    <div :class="$style.tagWrapper">
      <a-tag
        v-for="(item, index) in getTagList"
        :key="index"
        :class="{ [$style.tag]: true, [$style.active]: quantity === item }"
        @click="handleTagClick(item)"
      >
        {{ currencyUnitList[asset] }}{{ item }}
      </a-tag>
    </div>

    <BaseButton
      type="primary"
      :class="$style.baseButton"
      :passed="isPassed"
      @click="onClick"
    >
      {{ $t("Continue") | uppercase }}
    </BaseButton>

    <modal-base
      :visible.sync="isBaseModalVisible"
      @ok="onOk"
      @cancel="onCancel"
    />
  </section>
</template>

<script>
import { debounce } from "lodash-es";
import { mapGetters, mapState, mapActions } from "vuex";
import { Notification, Icon, Input, Select, Spin, Tag } from "ant-design-vue";
import IconError from "@/assets/img/common/icon-error.svg?inline";
import BaseButton from "@/components/BaseButton.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import CaretDown from "@/assets/img/common/caret-down.svg?inline";
import { commonMixin } from "@/mixin/commonMixin";
import { queryQuoteForFiat, saveUserPreference } from "@/api";
import { rfqByFiat as rfqByFiatKyc } from "@/api/kyc";
import { setCurrencyFlag, displayErrors } from "@/utils";

export default {
  name: "KycBuyPanel",
  mixins: [commonMixin],
  components: {
    [Icon.name]: Icon,
    [Input.name]: Input,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    [Spin.name]: Spin,
    [Tag.name]: Tag,
    IconError,
    BaseButton,
    ModalBase
  },
  data() {
    return {
      approxAmount: "",
      approxLoading: false,
      errors: "",
      tags: ["500", "2000", "5000", "20000"],
      CaretDown,
      isBaseModalVisible: false
    };
  },
  props: {
    tradeType: [String]
  },
  inject: {
    assetList: {
      default: () => {
        return [];
      }
    }
  },
  computed: {
    ...mapState([
      "pairs",
      "clientInfo",
      "tradeQuantity",
      "tradePair",
      "currencies",
      "nextKycStep",
      "modalView",
      "accountType",
      "kycStatus",
      "currencyUnitList"
    ]),
    ...mapState({
      allowedPaymentMethods: state =>
        state.exchangeConfig.allowed_payment_methods
    }),
    ...mapGetters({
      asset: "asset",
      balance: "balance",
      isEnabled: "isAccountEnabled",
      rfqParams: "rfqParams",
      isExternalCheckoutProcess: "isExternalCheckoutProcess"
    }),
    isPassed() {
      return !(!!this.quantity && !this.errors && !!this.tradePair);
    },
    getTagList() {
      const minimumDeposit = this.getMinimumDepositbyPairsInfo(
        this.pairs,
        this.tradePair,
        this.asset
      );
      return [
        10 * minimumDeposit,
        40 * minimumDeposit,
        100 * minimumDeposit,
        400 * minimumDeposit
      ];
    },
    getAssetList() {
      return this.assetList();
    },
    currency: {
      get() {
        return this.asset;
      },
      set(value) {
        if (this.isEnabled) {
          this.handleSaveUserPreference(value);
        } else {
          this.updateAsset(value);
        }
      }
    },
    decimals() {
      const { currencies, asset } = this;
      const config = currencies.find(({ currency }) => currency === asset);
      return config?.decimals ?? 2;
    },
    quantity: {
      get() {
        return this.tradeQuantity;
      },
      set(value) {
        this.updateTradeQuantity(value);
      }
    },
    flagClass() {
      return currency => {
        return `fi-${setCurrencyFlag(currency)}`;
      };
    },
    ownBalance() {
      const asset = this.asset;
      const _balance = this.balance;
      const existBalance = _balance.fiat[asset];
      return (existBalance && existBalance.amount) || 0;
    },
    debouncedHandleApprox() {
      return debounce(this.handleApprox, 1000);
    },
    hasCreditcard() {
      return this.allowedPaymentMethods?.includes("creditcard");
    },
    getUnitLen() {
      return this.currencyUnitList?.[this.asset]?.length;
    }
  },
  watch: {
    tradeQuantity: {
      async handler(val, oldVal) {
        if (Number.isNaN(Number.parseFloat(val))) {
          this.errors = this.$t("amount format errors");
          this.approxAmount = "";
          return;
        }
        const decimals = val?.split?.(".")[1] || "";
        const re = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if (decimals.length > this.decimals) {
          // validate currency decimals
          this.updateTradeQuantity(oldVal);
        } else if (!Number.isNaN(val) && re.test(val)) {
          this.errors = "";
          await this.updateApprox();
        } else {
          this.approxAmount = "";
          this.errors = this.$t("amount format errors");
        }
      },
      immediate: true
    },
    async tradePair() {
      await this.updateApprox();
    },
    async currency() {
      await this.updateApprox();
    },
    rfqParams(val) {
      if (val === null) {
        // setting rfq params finished.
        this.onClick();
      }
    }
  },
  methods: {
    ...mapActions([
      "updateClientInfo",
      "updateAsset",
      "updateTradePair",
      "updateTradeQuantity",
      "updateModalView"
    ]),
    async updateApprox() {
      const minimumDeposit = this.getMinimumDepositbyPairsInfo(
        this.pairs,
        this.tradePair,
        this.asset
      );

      if (Number.parseFloat(this.quantity) >= minimumDeposit) {
        this.errors = "";
        await this.debouncedHandleApprox();
      } else {
        this.approxAmount = "";
        this.errors = `Minimum transaction size is ${
          this.currencyUnitList[this.currency]
        }${minimumDeposit}`;
      }
    },
    handleReviewRfq() {
      this.loadTimer = true;
      this.handleRfq();
    },
    handleChange(value) {
      this.updateAsset(value);
    },
    inputMaxAmount() {
      this.quantity = this.ownBalance;
    },
    rfq(params) {
      return this.isEnabled ? queryQuoteForFiat(params) : rfqByFiatKyc(params);
    },
    async handleApprox() {
      try {
        this.approxLoading = true;
        const result = await this.rfq({
          pair: `${this.tradePair}${this.asset}`,
          side: this.tradeType.toLowerCase(),
          size: Number.parseFloat(this.quantity)
        });
        if (!result.errors) {
          // 这个quantity是询价后接口返回的数字货币的个数,不要跟Input输入的价格搞混了
          let { quantity: cryptoQuantity } = result;
          const amountStr = `${cryptoQuantity} ${this.tradePair}`;
          this.approxAmount = amountStr;
        } else {
          Notification.error({
            message: "Error",
            description: JSON.stringify(result.errors),
            placement: "bottomRight"
          });
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.approxLoading = false;
      }
    },
    handleTagClick(item) {
      this.quantity = item;
    },
    onClick() {
      const pathName = this.operateKycProcessSteps();
      this.$router.push({ name: pathName });
    },
    onOk() {
      this.isBaseModalVisible = false;
    },
    onCancel() {
      this.isBaseModalVisible = false;
    },
    async handleSaveUserPreference(asset) {
      try {
        const params = {
          key: "default_fiat",
          value: asset
        };
        const result = await saveUserPreference(params);
        if (!result.errors) {
          this.updateAsset(asset);
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    }
  },
  created() {},
  activated() {
    if (this.isExternalCheckoutProcess) {
      this.onClick();
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

$darkish-pink-color: #dc4f6b;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .maxCrypto {
    @include rfs(0.875rem);
    @include rfs(0 0.5rem, padding);
    background: var(--darkish-pink);
    border-radius: 0.25rem;
    color: var(--white);
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background: #{adjust-color($darkish-pink-color, $alpha: -0.2)};
    }

    &:active {
      background: var(--white);
      color: var(--darkish-pink);
      box-shadow: 0 0.125rem 0.25rem 0 var(--dark-70-5);
    }
  }

  .approx {
    @include rfs(26.25rem, width);
    @include rfs(1.5rem, height);
    @include margin-top(0.5rem);
    @include margin-bottom(0.5rem);

    color: var(--primary);
    font-weight: 500;
    text-align: left;
  }

  .tagWrapper {
    @include margin-bottom(2.5rem);

    .tag {
      @include rfs(0.875rem);
      @include rfs(0.1875rem 1rem, padding);

      color: var(--white);
      border-radius: 0.25rem;
      border: none;
      cursor: pointer;
      font-weight: 600;
      background-color: var(--silver);

      &:hover {
        background-color: var(--grey-cool-light);
      }

      &.active {
        background-color: var(--primary);
      }
    }
  }

  .errorMsg {
    @include rfs(23.75rem, max-width);
    @include rfs(1.625rem, margin-top);
    @include margin-bottom(0.5rem);
    @include rfs(0.625rem 1rem, padding);

    border-radius: 0.625rem;
    background: var(--pale-grey);
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      @include rfs(1.25rem, min-width);
      @include rfs(1.25rem, height);
      @include rfs(1.375rem, margin-right);

      color: var(--grey-cool);
    }

    span {
      color: var(--grey-cool);
      text-align: left;
    }
  }

  .baseButton {
    margin: auto;
  }

  .quantityInputLarge {
    :global {
      .ant-input-affix-wrapper .ant-input:not(:first-child) {
        @include rfs(4.5rem, padding-left);
      }
    }
  }

  .quantityInputSmall {
    :global {
      .ant-input-affix-wrapper .ant-input:not(:first-child) {
        @include rfs(3.125rem, padding-left);
      }
    }
  }

  :global {
    .ant-input-wrapper {
      @include rfs(26.25rem, width);

      margin: auto;
    }

    .ant-input-affix-wrapper .ant-input-prefix {
      @include rfs(1.25rem);
      @include rfs(1.5625rem, left);

      color: var(--silver);
    }

    .ant-input-suffix {
      top: 50%;
      right: 0.9375rem;
    }

    .ant-input {
      @include rfs(3.125rem, height);
      @include rfs(1.25rem);

      border: solid 0.0625rem #cecbcb;
      border-radius: 0.625rem 0 0 0.625rem;
      font-weight: 500;
    }

    .ant-input-group-addon {
      @include rfs(1.25rem);
      @include rfs(7.8125rem, width);
      @include rfs(0 0.875rem, padding);

      border-radius: 0 0.625rem 0.625rem 0;
      background-color: var(--legend-component-bg);

      & > div {
        display: flex;
        align-items: center;
      }
    }

    .ant-input-group-addon .ant-select .ant-select-selection {
      width: 100%;
    }

    .ant-input-group > .ant-input-group-addon:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
    .ant-select-arrow {
      right: 0.875rem;

      svg {
        @include rfs(0.6875rem, width);
        @include rfs(0.5rem, height);
      }
    }
    .ant-select-selection-selected-value {
      span:first-child {
        @include rfs(1.625rem, width);
        @include rfs(1.625rem, height);

        border-radius: 50%;
        background-size: cover;
        box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);

        line-height: 1.875rem;
      }
      span:last-child {
        @include rfs(1.25rem);
        @include rfs(0.625rem, margin-left);

        color: var(--legend-c-text);
      }
    }
  }
}

.currencyDropdown {
  border-radius: 0.625rem !important;
  padding-top: 1rem !important;
  background-color: transparent !important;
  box-shadow: none !important;

  :global {
    .ant-select-dropdown-menu-item {
      @include rfs(3rem, height);
      @include rfs(0.4375rem 0.75rem, padding);

      display: flex;
      align-items: center;
      justify-content: space-between;

      span:first-child {
        @include rfs(1.625rem, width);
        @include rfs(1.625rem, height);

        border-radius: 50%;
        background-size: cover;
        box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);
      }
      span:last-child {
        @include rfs(1.25rem);

        color: var(--legend-c-text);
      }
    }

    .ant-select-dropdown-content {
      background-color: var(--legend-component-bg);

      .ant-select-dropdown-menu {
        border-radius: 0.625rem;
        border: solid 0.0625rem var(--silver);

        /* 滚动条整体部分 */
        &::-webkit-scrollbar {
          @include rfs(0.1875rem, width);
          @include rfs(1.1875rem, height);

          border-radius: 0.625rem;
          background-color: var(--silver);
        }
        /* scroll轨道背景 */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);

          background-color: var(--white);
        }

        /* 滚动条中能上下移动的小块 */
        &::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
          background-color: var(--silver);
        }

        &::-webkit-scrollbar-button {
          // background-color: #7c2929;
        } /* 滑轨两头的监听按钮颜色 */
      }
    }
  }
}
</style>
