<template>
  <section>
    <a-collapse v-page-guide="'debug.guide_messages[1]'" :class="$style.nav">
      <a-collapse-panel header="router list">
        <nav>
          <router-link v-for="route in navRoutes" :to="route" :key="route.name">
            [ {{ route.name }} ]
          </router-link>
        </nav>
      </a-collapse-panel>
    </a-collapse>

    <section v-page-guide="'debug.guide_messages[2]'" :class="$style.debug">
      <v-swatches
        :class="$style.swatches"
        :fallback-input-class="$style.swatchesInput"
        :fallback-ok-class="$style.swatchesOk"
        v-model="color"
        show-fallback
        shapes="circles"
        swatch-size="32"
        :trigger-style="{ height: '32px', width: '32px' }"
        fallback-input-type="color"
      />
      <a-radio-group v-model="size" size="small" buttonStyle="solid">
        <a-radio-button
          v-for="item in sizeList"
          :key="`size-${item}`"
          :value="item"
          >{{ $t(`debug.size.${item}`) }}</a-radio-button
        >
      </a-radio-group>

      <a-radio-group v-model="bgColor" size="small" buttonStyle="solid">
        <a-radio-button
          v-for="color in ['dark', 'light']"
          :key="color"
          :value="color"
        >
          {{ color }}
        </a-radio-button>
      </a-radio-group>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  Collapse,
  Icon,
  Radio,
  Switch,
  Select,
  Button,
  Input
} from "ant-design-vue";
import VSwatches from "vue-swatches/src/VSwatches.vue";
import emitter from "@/utils/eventBus";
import { sizeList } from "@/utils";

export default {
  name: "DebugPanel",
  components: {
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [Collapse.Panel.name]: Collapse.Panel,
    [Icon.name]: Icon,
    [Radio.Button.name]: Radio.Button,
    [Radio.Group.name]: Radio.Group,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    [Input.name]: Input,
    VSwatches
  },
  data() {
    return {
      sizeList,
      kycNextStepList: {
        "Not Started": ["enter_email"],
        Started: [],
        "In Progress": [
          "enter_card",
          "enter_email",
          "verify_email",
          "enter_pi",
          "enter_phone",
          "verify_phone",
          "questionnaire",
          "enter_address",
          "verify_id",
          "submit_id",
          "redo_verify_id"
        ],
        Submitted: ["reviewing_id"],
        "In Review": ["enter_bank"],
        Approved: [
          "enter_bank",
          "questionnaire",
          "submitted_questionnaire",
          "approved_questionnaire",
          "reviewing_questionnaire",
          "rejected_questionnaire",
          "approved"
        ],
        Rejected: ["rejected"]
      },
      nextAccountStatus: "",
      appExchangeNameLists: [
        "Bitrue",
        "LBank",
        "GATEIO",
        "PHEMEX",
        "BitMart",
        "Bybit",
        "KuCoin",
        "FuniBet",
        "Circle"
      ],
      appExchangeName: "",
      email: ""
    };
  },
  computed: {
    ...mapState(["themeColor", "clientInfo", "bgThemeColor"]),
    color: {
      get() {
        return this.themeColor;
      },
      set(val) {
        this.setThemeColor(val);
      }
    },
    size: {
      get() {
        return this.$store.state.clientInfo.uiSize;
      },
      set(val) {
        this.setUiSize(val);
      }
    },
    navRoutes() {
      const { options } = this.$router;
      return options.routes.filter(n => n.path !== "*");
    },
    isStatusSelectVisible() {
      return [
        "home",
        "home.trade",
        "home.withdraw",
        "register",
        "register.individual",
        "account.userinfo",
        "refuses",
        "forbidden",
        "restriction"
      ].includes(this.$route.name);
    },
    noLegend: {
      get() {
        return this.clientInfo.noLegend;
      },
      set(noLegend) {
        this.updateClientInfo({ noLegend });
      }
    },
    bgColor: {
      get() {
        return this.bgThemeColor;
      },
      set(val) {
        this.setBgThemeColor(val);
      }
    }
  },
  watch: {
    themeColor(...args) {
      emitter.emit("legend:change-theme-color", args);
    }
  },
  methods: {
    ...mapActions([
      "updateClientInfo",
      "setThemeColor",
      "setUiSize",
      "setBgThemeColor"
    ])
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.nav {
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: 1000;

  & nav {
    align-items: flex-end;
    display: flex;
    flex-flow: column nowrap;
    margin: -16px;
    max-height: 60vh;
    overflow-y: auto;
    padding: 8px;
  }
  & a[href] {
    color: var(--ayanami);
    &:global(.router-link-active),
    &:global(.router-link-exact-active) {
      color: var(--red);
    }
  }
}
.debug {
  @include rfs(3rem, top);
  align-items: flex-end;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  right: 0;
  z-index: 1000;

  .swatches,
  :global(.ant-radio-group),
  :global(.ant-switch) {
    @include margin-top(0.25rem);
  }

  .swatches {
    display: flex;
    :global {
      .vue-swatches__wrapper {
        box-sizing: content-box;
      }
      .vue-swatches__fallback__wrapper {
        align-items: stretch;
        display: flex;
      }
    }
    .swatchesInput {
      height: 100%;
      padding: 0;
    }
    .swatchesOk {
      background-color: var(--legend);
    }
  }

  .btnChange {
    border-radius: var(--border-radius);
    margin-top: 0.5rem;
    height: auto;
    padding: 0.2rem;
  }
}
</style>
