// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabsItem_tabsItem_1wu7R{padding:.75rem .75rem .9375rem 1.25rem;width:10rem;border-radius:1.25rem;text-align:left;cursor:pointer}.TabsItem_tabsItem_1wu7R:not(:last-child){margin-right:1.25rem}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemTop_15Hjr{margin-bottom:.5625rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:1.625rem}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemTop_15Hjr .TabsItem_logo_9_lWp{width:1.875rem}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemTop_15Hjr .TabsItem_logo_9_lWp svg{height:1.625rem}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemTop_15Hjr .TabsItem_radioBtn_1h7IX{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:1.625rem;height:1.625rem;border-radius:50%;border:solid 1px var(--grey-cool)}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemTop_15Hjr .TabsItem_radioBtn_1h7IX .TabsItem_radioBtnChecked_23wHH{width:1.125rem;height:1.125rem;background-color:var(--primary);border-radius:50%}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemTitle_3m4bL{font-size:.875rem;font-weight:500;line-height:1.25rem;color:var(--legend-c-text)}.TabsItem_tabsItem_1wu7R .TabsItem_tabsItemDesc_OkV6q{font-size:.75rem;font-weight:400;color:var(--grey-cool);letter-spacing:-.26px}.TabsItem_tabsItemNor_11uBO{background-color:var(--legend-disabled-bg)}.TabsItem_tabsItemNor_11uBO svg{color:var(--grey-cool)}.TabsItem_tabsItemSel_Ogxp9{background-color:var(--legend-panel-bg);-webkit-box-shadow:inset 0 0 0 1px var(--primary);box-shadow:inset 0 0 0 1px var(--primary)}.TabsItem_tabsItemSel_Ogxp9 svg{color:var(--primary)}", ""]);
// Exports
exports.locals = {
	"tabsItem": "TabsItem_tabsItem_1wu7R",
	"tabsItemTop": "TabsItem_tabsItemTop_15Hjr",
	"logo": "TabsItem_logo_9_lWp",
	"radioBtn": "TabsItem_radioBtn_1h7IX",
	"radioBtnChecked": "TabsItem_radioBtnChecked_23wHH",
	"tabsItemTitle": "TabsItem_tabsItemTitle_3m4bL",
	"tabsItemDesc": "TabsItem_tabsItemDesc_OkV6q",
	"tabsItemNor": "TabsItem_tabsItemNor_11uBO",
	"tabsItemSel": "TabsItem_tabsItemSel_Ogxp9"
};
module.exports = exports;
