// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"bgColor": "rgba(231,231,232,.3)",
	"fontColor": "#0a0a16",
	"panelBgColor": "#fff",
	"componentBgColor": "#fff",
	"disabledBgColor": "#ededf4"
};
module.exports = exports;
