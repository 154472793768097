<template>
  <section :class="$style.wrapper">
    <div :class="$style.title">
      <img
        src="../../assets/img/common/icon-reminder.png"
        alt="icon-reminder"
      />
      {{ $t("rfi_pending_deposit.title") }}
    </div>
    <p>
      {{ $t("rfi_pending_deposit.content[0]") }}
    </p>
    <p>
      {{ $t("rfi_pending_deposit.content[1]") }}
    </p>
    <a :class="$style.rfiLink" :href="rfiUrl" target="_blank">{{ rfiUrl }}</a>

    <i18n path="rfi_pending_deposit.content[2]" tag="p">
      <template #email>
        <span>{{ email }}</span>
      </template>
    </i18n>

    <p>
      {{ $t("rfi_pending_deposit.content[3]") }}
    </p>
    <IconLogoBlack />
    <BaseButton @click="handleClick">{{ $t("Okay") | uppercase }}</BaseButton>
  </section>
</template>

<script>
import { mapState } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import IconLogoBlack from "@/assets/img/logo-black.svg?inline";

export default {
  name: "Modal.RfiPendingDeposit",
  components: {
    BaseButton,
    IconLogoBlack
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    handleClick() {
      this.$emit("ok");
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    email() {
      return this.userInfo?.rfi?.email ?? "";
    },
    rfiUrl() {
      return this.userInfo?.rfi?.rfi_url ?? "";
    }
  }
};
</script>
<style lang="scss" module>
@import "~rfs/scss";

.wrapper {
  @include rfs(2.5rem 2rem 3.125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);
  color: var(--legend-c-text);

  .title {
    @include rfs(1.5rem);
    @include margin(0 0 1.75rem);

    position: relative;
    color: #df943e;
    font-weight: bold;
    display: flex;
    align-items: center;

    img {
      @include rfs(2rem, width);
      position: absolute;
      top: 0;
      left: -3rem;
    }
  }
  p {
    @include rfs(1rem);

    width: 100%;
    text-align: left;
    span {
      color: #4fad5b;
      font-weight: 500;
    }
  }

  svg {
    width: 9.375rem;

    @include rfs(1.75rem, margin-bottom);
  }

  .rfiLink {
    @include rfs(1rem, margin-bottom);

    width: 100%;
  }
}
</style>
