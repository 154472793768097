<template>
  <section :class="$style.wrapper">
    <section :class="$style.cardHeader" @click="toBalance">
      <icon-crypto />
      <h5>{{ $t("My crypto portfolio") }}</h5>
      <icon-arrow-left />
    </section>
    <section :class="$style.cardContent">
      <template v-if="hasCryptoBalance">
        <icon-empty-crypto :class="$style.emptyBalance" />
        <p v-html="$t('empty_messages[1]')"></p>
      </template>
      <template v-else>
        <div :class="$style.contentTop">
          <span v-if="!showExchangeCol" :class="$style.exchangeName">
            {{ appId }}
          </span>
          <span :class="$style.systemName">{{ $t("Legend Trading") }}</span>
        </div>
        <div :class="$style.cryptoList">
          <div
            :class="$style.cryptoItem"
            v-for="{
              code,
              value,
              value_exchange,
              value_on_hold
            } in namedCrypto"
            :key="code"
          >
            <cryptoicon :symbol="code" size="15" generic />
            <span :class="$style.cryptoName">{{ code }}</span>
            <span v-if="showExchangeCol" :class="$style.cryptoExchangeAmount">{{
              getCryptoExchangeValue(value_exchange)
            }}</span>
            <span :class="$style.cryptoAmount">{{
              getCryptoValue(value, value_on_hold)
            }}</span>
          </div>
        </div>

        <span :class="$style.link" @click="toBalance">
          {{ $t("View more") | uppercase }}
        </span>
      </template>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IconCrypto from "@/assets/img/common/icon-crypto.svg?inline";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";
import IconEmptyCrypto from "@/assets/img/trade/icon-empty-crytpo.svg?inline";

export default {
  name: "CryptoPortfolio",
  data() {
    return {};
  },
  components: {
    IconCrypto,
    IconArrowLeft,
    IconEmptyCrypto
  },
  computed: {
    ...mapState({
      appId: state => state.clientInfo.appId
    }),
    ...mapGetters({
      balance: "balance"
    }),
    namedCrypto() {
      const cryptoCode = [
        ...new Set([
          ...Object.keys(this.balance.crypto_exchange || {}),
          ...Object.keys(this.balance.crypto || {}),
          ...Object.keys(this.balance.crypto_on_hold || {})
        ])
      ];
      const arr = cryptoCode.map(key => {
        const {
          crypto_exchange = {},
          crypto = {},
          crypto_on_hold = {}
        } = this.balance;
        return {
          name: key,
          code: key,
          value: crypto[key] ?? { amount: 0 },
          value_exchange: crypto_exchange[key],
          value_on_hold: crypto_on_hold[key] ?? { amount: 0 }
        };
      });
      return arr;
    },
    getCryptoValue() {
      return (crypto, cryptoOnHold) => {
        const cryptoAmount = +parseFloat(crypto?.amount) || 0;
        const cryptoOnHoldAmount = +parseFloat(cryptoOnHold?.amount) || 0;
        const cryptoTotal = cryptoAmount + cryptoOnHoldAmount;
        return cryptoTotal;
      };
    },
    getCryptoExchangeValue() {
      return cryptoExchange => {
        return parseFloat(cryptoExchange?.amount) || "0";
      };
    },
    hasCryptoBalance() {
      return (
        JSON.stringify(this.balance.crypto) === "{}" &&
        JSON.stringify(this.balance.crypto_exchange) === "{}"
      );
    },
    showExchangeCol() {
      return JSON.stringify(this.balance.crypto_exchange) !== "{}";
    }
  },
  watch: {},
  methods: {
    toBalance() {
      this.$router.push({ name: "account.balance" });
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(17.5rem, max-width);
  @include rfs(1.6875rem, margin-bottom);

  .cardHeader {
    @include rfs(1rem, margin-bottom);
    @include rfs(0.4375rem, padding-right);

    display: flex;
    align-items: center;
    color: var(--legend-c-text);
    cursor: pointer;

    svg:first-child {
      @include rfs(1.125rem, max-width);
    }

    h5 {
      @include rfs(0rem, margin-bottom);
      @include rfs(0.75rem, padding-left);

      flex: 1;
      line-height: 1.5625rem;
      font-weight: 600;
      color: var(--legend-c-text);
    }

    svg:last-child {
      @include rfs(0.5rem, max-height);
      @include rfs(0.9375rem, max-width);

      transform: rotate(-90deg);
      color: var(--legend-c-text);
    }
  }
  .cardContent {
    @include rfs(1.125rem 1.25rem 1.25rem, padding);

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.625rem;
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    background-color: var(--legend-panel-bg);

    .contentTop {
      @include rfs(0.75rem);
      @include rfs(0.9375rem, margin-bottom);

      width: 100%;
      font-weight: 600;
      color: var(--grey-cool);
      display: flex;
      justify-content: flex-end;

      .exchangeName {
        @include rfs(0.6875rem, padding-right);
        @include rfs(0.6875rem, margin-right);

        border-right: solid 0.0625rem var(--silver);
      }
    }

    p {
      @include rfs(0.875rem);
      @include rfs(0rem, margin-bottom);

      font-weight: 500;
      color: var(--legend-c-text);
      text-align: center;
    }

    .emptyBalance {
      @include rfs(1.25rem, margin-bottom);
    }

    .cryptoList {
      width: 100%;

      .cryptoItem {
        @include rfs(0.3125rem 0rem, padding);

        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
          border-bottom: solid 0.0625rem var(--silver-25);
        }
        color: var(--legend-c-text);

        .cryptoName {
          @include rfs(0.875rem);
          @include rfs(2.5rem, min-width);
          @include rfs(0.4375rem, margin-left);

          font-weight: 500;
        }

        .cryptoAmount {
          @include rfs(10px);

          text-align: right;
          flex: 3;
        }

        .cryptoExchangeAmount {
          @include rfs(10px);

          text-align: right;
          flex: 4;
        }
      }
    }

    .link {
      @include rfs(0.875rem);
      @include rfs(1.25rem, margin-top);

      color: var(--primary);
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: var(--primary-3);
      }

      &:active {
        color: var(--legend-c-text);
      }
    }
  }
}
</style>
