<template>
  <section :class="$style.wrapper">
    <h2>{{ pendingInfo.subject || $t(subject) }}</h2>

    <icon-add-wait />

    <p>{{ $t("modal_messsages.oops_pending.title") }}:</p>

    <section>
      <div v-if="pendingInfo.message" :class="$style.message">
        {{ pendingInfo.message }}
      </div>
      <p v-else v-html="$t('modal_messsages.oops_pending.content')"></p>
    </section>

    <BaseButton @click="$emit('ok')">{{ $t("Okay") | uppercase }}</BaseButton>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import IconAddWait from "@/assets/img/payment/icon-add-wait.svg?inline";

export default {
  name: "Modal.OopsPending",
  components: {
    BaseButton,
    IconAddWait
  },
  data() {
    return {
      subject: "oops_pending_default_subject"
    };
  },
  props: {
    pendingInfo: {
      type: Object,
      default: () => {
        return { subject: "", message: "" };
      }
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 2rem 3.125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(2rem);
    @include margin(0 0 1.75rem);

    color: var(--legend-c-text);
    line-height: 1.2;
    text-align: center;
  }

  svg {
    @include rfs(5.5rem, max-width);
    @include margin(0.5rem auto 2.5rem);
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.25rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }

  section {
    @include margin(0 0 2rem);
    @include padding(1rem);
    background-color: var(--disable);
    border-radius: var(--border-radius);
    color: var(--grey-cool);

    p {
      margin: 0;
    }

    .message {
      @include rfs(0.875rem);

      color: var(--grey-cool);
      text-align: left;
      white-space: pre-wrap;
    }
  }
}
</style>
