<template>
  <section>
    <OrderConfirmPanel :class="$style.tradeBuyConfirm">
      <template v-if="hideTitle" #panel_header>
        <h3>{{ $t("Confirm Order") }}</h3>
        <p>{{ $t("Order Summary") | uppercase }}</p>
      </template>
    </OrderConfirmPanel>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { commonMixin } from "@/mixin/commonMixin";
import OrderConfirmPanel from "@/components/OrderConfirmPanel.vue";

export default {
  name: "BuyConfirm",
  components: {
    OrderConfirmPanel
  },
  mixins: [commonMixin],
  data() {
    return {};
  },
  computed: {
    hideTitle() {
      return !this.tradeBuyAchPullProcess();
    }
  },
  methods: {
    ...mapActions([
      "updatePaymentFrom",
      "updateDefaultPaymentTabsActived",
      "updateDefaultPaymentItemActived"
    ])
  },
  beforeRouteLeave(to, from, next) {
    const { name } = to;
    if (name.startsWith("payment")) {
      this.updatePaymentFrom("home.trade.buy");
    } else if (name.startsWith("deposit")) {
      this.updatePaymentFrom("home.trade.buy");
    } else {
      this.updatePaymentFrom("");
      this.updateDefaultPaymentTabsActived("balance");
      this.updateDefaultPaymentItemActived({
        balance: {
          payment_method_id: "",
          more: false,
          type: ""
        },
        bank_transfer: {
          payment_method_id: "",
          more: false,
          type: ""
        },
        credit_card: {
          payment_method_id: "",
          more: false,
          type: ""
        }
      });
    }
    next();
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.tradeBuyConfirm {
  :global .content {
    @include rfs(47.5rem, min-width);
    @include rfs(2.5rem 5.625rem 3.875rem 5.625rem, padding);

    border-radius: 0.625rem;
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    background: var(--legend-panel-bg);
    text-align: center;
  }

  h3 {
    @include rfs(0rem, margin-bottom);

    color: var(--legend-c-text);
  }

  p {
    @include rfs(0.875rem);
    @include rfs(1.25rem, margin-bottom);

    color: var(--grey-cool);
  }
}
</style>
