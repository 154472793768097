<template>
  <div :class="$style.btnBack" @click="handleBack">
    <icon-arrow-left />{{ $t("Back") | uppercase }}
  </div>
</template>

<script>
import { Button, Icon } from "ant-design-vue";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";

export default {
  name: "BackBtn",
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    IconArrowLeft
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleBack() {
      this.$router.back();
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.btnBack {
  @include rfs(0.875rem);
  @include rfs(0rem, padding);

  position: absolute;
  left: 0;
  color: var(--legend-c-text);
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    @include rfs(0.5rem, margin-right);
    @include rfs(0.9375rem, width);

    transform: rotate(90deg);
  }
}
</style>
