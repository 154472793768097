<template>
  <section :class="$style.header">
    <BackBtn v-if="isShowBackBtn" />
    <p><slot></slot></p>
  </section>
</template>

<script>
import BackBtn from "@/components/BackBtn.vue";

export default {
  name: "RegisterHeader",
  components: {
    BackBtn
  },
  props: {
    isShowBackBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.header {
  @include rfs(2rem, line-height);
  @include rfs(1.875rem, margin-bottom);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    @include rfs(1rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    color: var(--legend-c-text);
  }
}
</style>
