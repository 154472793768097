<template>
  <section :class="$style.wrapper">
    <h2>{{ $t("Pending") }}</h2>

    <div :class="$style.waitWrapper">
      <a-spin :spinning="spinning">
        <a-icon slot="indicator" :component="IconWait" spin />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { Spin, Icon } from "ant-design-vue";
import { of } from "rxjs";
import { mergeMap, delay, repeat, tap } from "rxjs/operators";
import { mapState, mapActions } from "vuex";
import { queryUserStatus as queryKycUserStatus } from "@/api/kyc";
import { reLoadMixin } from "@/mixin/reLoadMixin";
import emitter from "@/utils/eventBus";
import { displayErrors } from "@/utils";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";

export default {
  name: "Modal.RefreshNeededPending",
  components: {
    [Spin.name]: Spin,
    [Icon.name]: Icon
  },
  mixins: [reLoadMixin],
  data() {
    return {
      IconWait,
      spinning: true,
      subject: "oops_pending_default_subject",
      source: null,
      subscription: null,
      interval: 5000,
      count: 12
    };
  },
  props: {},
  computed: {
    ...mapState(["clientInfo"])
  },
  watch: {},
  methods: {
    ...mapActions(["updateBaseModalVisible", "updateKycStatus"]),
    cleanup() {
      this.subscription?.unsubscribe?.();
    },
    queryUserStatus() {
      const params = {
        "APP-ID": this.clientInfo.appId,
        "APP-UID": this.clientInfo.appUid
      };
      return queryKycUserStatus(params);
    },
    polling() {
      this.source = of({}).pipe(
        mergeMap(() => this.queryUserStatus()),
        tap({ next: this.onNext.bind(this) }),
        delay(this.interval),
        repeat(this.count)
      );
      this.subscription = this.source.subscribe({
        error: this.onError.bind(this),
        complete: this.onComplete.bind(this)
      });
    },
    async onNext(result) {
      if (result.success) {
        const { kyc_status } = result.data;
        if (["Approved", "Submitted"].includes(kyc_status)) {
          await this.getRefreshToken();

          this.cleanup();
          this.updateKycStatus(kyc_status);
          this.updateBaseModalVisible(false);
          emitter.emit("legend:kyc-activated", { status: kyc_status });

          this.isNeedLoad(true);
          this.$router.push({ name: "home.trade" });
        }
      } else {
        displayErrors(result);
      }
    },
    onError(err) {
      displayErrors(err);

      this.cleanup();
      this.updateBaseModalVisible(false);
      this.$router.push({ name: "home.trade" });
    },
    onComplete() {
      this.cleanup();
      this.updateBaseModalVisible(false);
      this.$router.push({ name: "home.trade" });
    }
  },
  async created() {
    this.polling();
  },
  beforeDestroy() {
    this.cleanup();
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 2rem 3.125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(2rem);
    @include margin(0 0 3rem);

    color: var(--legend-c-text);
    line-height: 1.2;
    text-align: center;
  }
  .waitWrapper {
    @include rfs(9.5625rem, margin-bottom);

    :global {
      .ant-spin-dot {
        @include rfs(10rem);
      }
      .anticon-spin {
        animation-duration: 2s;
      }
    }
  }
}
</style>
