<template>
  <section :class="$style.wrapper">
    <RegisterHeader :isShowBackBtn="!isExternalCheckoutProcess">
      {{ $t("EMAIL ADDRESS") }}
    </RegisterHeader>
    <section :class="$style.container">
      <ValidationObserver
        slim
        ref="veeObserver"
        class="formValidation"
        v-slot="{ valid }"
      >
        <a-form>
          <a-row type="flex" justify="start">
            <a-col :span="24">
              <p>{{ $t("Your email address") }}</p>
              <ValidationProvider
                :immediate="immediate"
                :name="$t('Email')"
                mode="eager"
                rules="required|email"
                v-slot="slotProps"
              >
                <a-form-item :validate-status="resolveState(slotProps)">
                  <template slot="help" v-if="slotProps.errors[0] || !!errors">
                    <div class="errorMsg">
                      <icon-error />
                      <span>{{ !errors ? slotProps.errors[0] : errors }}</span>
                    </div>
                  </template>
                  <a-input
                    ref="email"
                    type="email"
                    :size="$store.state.clientInfo.uiSize"
                    :placeholder="$t('Email')"
                    v-model.trim="account_email"
                    @pressEnter="onHandleNext(valid)"
                  >
                    <icon-success slot="suffix" v-show="slotProps.valid" />
                  </a-input>
                </a-form-item>
              </ValidationProvider>
            </a-col>
          </a-row>
          <a-row v-if="showEmailExisted && !errors" :class="$style.news">
            {{ $t("email_exited_tips") }}
          </a-row>
        </a-form>

        <section :class="$style.desc">
          <icon-email-fill />
          <span v-html="$t('email_input_desc')"></span>
        </section>

        <BaseButton
          :class="$style.baseButton"
          :passed="!(valid && !errors)"
          :loading="isLoading"
          @click="onHandleNext"
        >
          {{ $t("NEXT") }}
          <icon-arrow-left />
        </BaseButton>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
import { debounce } from "lodash-es";
import { mapGetters, mapActions } from "vuex";
import { Input, Row, Col, Form, Icon } from "ant-design-vue";
import BaseButton from "@/components/BaseButton.vue";
import RegisterHeader from "@/components/RegisterHeader.vue";
import IconError from "@/assets/img/common/icon-error.svg?inline";
import IconSuccess from "@/assets/img/common/icon-valid.svg?inline";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";
import IconEmailFill from "@/assets/img/register/icon-email-fill.svg?inline";
import { queryEmail, saveEmail, checkEmail } from "@/api/kyc";
import { displayErrors } from "@/utils";

export default {
  name: "RegisterNow",
  components: {
    [Input.name]: Input,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Icon.name]: Icon,
    BaseButton,
    IconArrowLeft,
    IconError,
    IconSuccess,
    RegisterHeader,
    IconEmailFill
  },
  data() {
    return {
      immediate: false,
      showEmailExisted: false,
      account_email: "",
      errors: "",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["isExternalCheckoutProcess"]),
    debouncedCheckEmail() {
      return debounce(this.checkEmail, 500);
    }
  },
  watch: {
    account_email: {
      handler(val) {
        this.debouncedCheckEmail(val);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(["updateAccountEmail"]),
    ...mapActions("REGISTER", ["updateResigsterEmail"]),
    resolveState({ errors, pending, valid }) {
      if (errors[0]) {
        this.errors = "";
        return "error";
      }
      if (pending) {
        this.errors = "";
        return "validating";
      }
      if (valid) {
        return "success";
      }
      return "";
    },
    async checkEmail(val) {
      if (val) {
        const { result } = await checkEmail(val);
        this.showEmailExisted = result;
      } else {
        this.showEmailExisted = false;
      }
    },
    async onHandleNext(isValid) {
      if (!isValid) return;
      const { account_email } = this;
      try {
        this.isLoading = true;
        const params = { account_email };
        const result = await saveEmail(params);
        if (!result.errors) {
          this.updateResigsterEmail(account_email);
          this.updateAccountEmail(account_email);
          this.$router.push({ name: "individual.verifyEmail" });
        } else {
          displayErrors(result);
        }
      } catch (err) {
        const result = err.response.data;
        this.errors = result?.errors?.account_email ?? "";
        displayErrors(err);
      } finally {
        this.isLoading = false;
      }
    },
    initData() {
      this.immediate = false;
      this.account_email = "";
      this.$nextTick(() => {
        this.$refs?.["veeObserver"]?.reset();
      });
    },
    async fetchData() {
      try {
        const result = await queryEmail();
        if (!result.errors) {
          const { account_email } = result;
          this.account_email = account_email ?? "";
          this.immediate = true;
          this.$refs?.email?.focus();
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    }
  },
  created() {},
  activated() {
    this.fetchData();
  },
  deactivated() {
    this.initData();
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.wrapper {
  .container {
    @include rfs(26.25rem, max-width);

    margin: auto;

    .news {
      @include margin(1px 0 -4rem);
      color: var(--primary);
    }
    .desc {
      @include rfs(8.125rem 0 5.625rem 0, margin);

      svg {
        @include rfs(2.5rem, margin-bottom);
      }

      span {
        @include rfs(0.875rem);

        color: var(--grey-cool);
        display: block;
      }
    }
    .baseButton {
      // color: var(--white);
      // &:not([disabled]) {
      //   background: var(--dark);
      // }
    }
  }
}
</style>
