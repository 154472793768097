<template>
  <section :class="$style.wrapper">
    <h6>{{ $t("Choose a crypto") | uppercase }}</h6>
    <CryptoSelector
      v-if="pairList.length"
      :defaultCrypto.sync="currentPair"
      :pairList="pairList"
    />
    <KycBuyPanel tradeType="Buy" />
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import CryptoSelector from "@/components/CryptoSelector.vue";
import KycBuyPanel from "@/components/KycBuyPanel.vue";
import { commonMixin } from "@/mixin/commonMixin";

export default {
  name: "Dashboard",
  mixins: [commonMixin],
  components: {
    CryptoSelector,
    KycBuyPanel
  },
  provide() {
    return {
      assetList: () => this.assetList
    };
  },
  data() {
    return {
      cryptoDefaultBalance: {
        BTC: { amount: 0.05 },
        ETH: { amount: 0.5 },
        USDT: { amount: 1000 }
      },
      address: ""
    };
  },
  computed: {
    ...mapState({
      pairs: state => state.pairs,
      tradeQuantity: state => state.tradeQuantity,
      tradeSide: state => state.tradeSide,
      tradePair: state => state.tradePair,
      nextKycStep: state => state.nextKycStep,
      currencies: state => state.currencies
    }),
    ...mapGetters({
      asset: "asset",
      balance: "balance",
      isEnabled: "isAccountEnabled",
      rfqParams: "rfqParams"
    }),
    currentPair: {
      get() {
        return this.tradePair;
      },
      set(value) {
        this.updateTradePair(value);
      }
    },
    fiat() {
      return this.asset;
    },
    isBuy() {
      return this.tradeSide.toLowerCase() === "buy";
    },
    pairList() {
      // 先找设定的法币对应的交易对，找到就正常展示
      // 找不到默认使用USD的交易对
      const set = new Set();
      const defaultSet = new Set();
      this.pairs.forEach(itemPair => {
        Object.keys(itemPair).forEach(pair => {
          let v = itemPair[pair];
          const vArr = v.display?.split(" / ") ?? "";
          if (this.asset === vArr[1]) {
            vArr.length && set.add(vArr[0]);
          } else {
            vArr.length && defaultSet.add(vArr[0]);
          }
        });
      });
      const arr = Array.from(!set.size ? defaultSet : set);
      return arr;
    },
    assetList() {
      // 如果用户选择了数字货币，那么就找到对应的可以交易的法币
      const set = new Set();
      this.pairs.forEach(itemPair => {
        Object.keys(itemPair).forEach(pair => {
          let v = itemPair[pair];
          const vArr = v.display?.split(" / ") ?? "";
          if (this.currentPair === vArr[0]) {
            set.add(v.display.split(" / ")[1]);
          }
        });
      });
      const arr = Array.from(set);
      return arr;
    },
    ownBalance() {
      const asset = this.asset;
      const _balance = this.balance;

      if (this.isBuy) {
        const existBalance = _balance.fiat[asset];
        return (existBalance && existBalance.amount) || 0;
      } else {
        const existBalance =
          _balance.crypto_exchange[this.currentPair] ??
          this.cryptoDefaultBalance[this.currentPair]; // 默认数据 就是 只有 BTC ETH USDT 其他数字货币 不用考虑
        if (
          parseFloat(existBalance?.amount) === 0 &&
          ["BTC", "ETH", "USDT"].includes(this.currentPair)
        ) {
          const defaultBalance = this.cryptoDefaultBalance[this.currentPair];
          return (defaultBalance && defaultBalance.amount) || 0;
        } else {
          return (existBalance && existBalance.amount) || 0;
        }
      }
    }
  },
  watch: {
    pairList: {
      handler(val) {
        // 如果之前选择的crpyto在新的交易对就保持不变，不在的话就默认用第一个
        if (
          Array.isArray(val) &&
          val.length > 0 &&
          !val.includes(this.currentPair)
        ) {
          this.currentPair = val[0];
        }
      },
      immediate: true
    },
    currentPair() {
      this.resetQuantity();
    },
    tradeSide() {
      this.resetQuantity();
    },
    asset() {
      this.resetQuantity();
    }
  },
  methods: {
    ...mapActions(["updateTradePair", "updateTradeQuantity", "setupRfqParams"]),
    getMinimumDepositbyPairsInfo(paris, currentPair, defaultAsset) {
      const pairsInfo = `${currentPair}${defaultAsset}`;
      const dataInfo = paris?.find(pair => pair?.[pairsInfo]);
      return dataInfo?.[pairsInfo]?.minimum_size ?? 0;
    },
    resetQuantity() {
      if (this.rfqParams) {
        this.setupRfqParams();
      } else {
        let quantity = "";
        if (this.isBuy) {
          const minimumDeposit = this.getMinimumDepositbyPairsInfo(
            this.pairs,
            this.currentPair,
            this.asset
          );
          quantity = Math.max(this.ownBalance, minimumDeposit).toString();
        } else {
          quantity = this.ownBalance;
        }
        // 快速切换 buy/sell 询价的入参会有异常。因为快速切换时接口还没有返回
        this.$nextTick(() => {
          this.updateTradeQuantity(quantity);
        });
      }
    }
  },
  created() {
    this.resetQuantity();
  },
  activated() {},
  deactivated() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.wrapper {
  h6 {
    @include rfs(1rem);
    @include rfs(1.25rem, margin-bottom);

    font-weight: 500;
    color: var(--legend-c-text);
  }
}
</style>
