var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.wrapper},[_c('section',{class:_vm.$style.cardHeader,on:{"click":_vm.toTradeHistory}},[_c('icon-orders'),_c('h5',[_vm._v(_vm._s(_vm.$t("Recent trades")))]),_c('icon-arrow-left')],1),_c('section',{class:_vm.$style.cardContent},[(_vm.dataSource.length === 0)?[_c('div',{class:_vm.$style.recentEmpty},[_c('icon-empty-order'),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('empty_messages[2]'))}}),_c('span',{class:_vm.$style.link,on:{"click":_vm.toTrade}},[_vm._v(" "+_vm._s(_vm.$t("FEED ME SOME ORDERS"))+" ")])],1)]:[_c('div',{class:_vm.$style.orderList},_vm._l((_vm.dataSource),function(ref){
var _obj;

var pair = ref.pair;
var side = ref.side;
var quantity = ref.quantity;
var created_at = ref.created_at;
var trade_id = ref.trade_id;return _c('div',{key:trade_id,class:_vm.$style.orderItem},[_c('div',{class:_vm.$style.itemCell},[(side === 'sell')?_c('div',{class:_vm.$style.orderPairs},[_vm._v(" "+_vm._s(_vm.formatCrypto(pair))+" ")]):_vm._e(),(side === 'buy')?_c('div',{class:_vm.$style.orderPairs},[_vm._v(" "+_vm._s(_vm.formatFiat(pair))+" ")]):_vm._e(),_c('div',{class:_vm.$style.orderQuantity},[_vm._v(" "+_vm._s(quantity)+" "),(side === 'buy')?_c('span',[_vm._v(_vm._s(_vm.formatCrypto(pair)))]):_vm._e()])]),_c('div',{class:_vm.$style.itemCell},[_c('div',{class:_vm.$style.orderDate},[_vm._v(_vm._s(_vm._f("formatDate")(created_at)))]),_c('div',{class:( _obj = {}, _obj[_vm.$style.orderType] = true, _obj[_vm.$style.danger] = side === 'sell', _obj[_vm.$style.info] = side === 'buy', _obj )},[_vm._v(" "+_vm._s(_vm.$t(side.toUpperCase()))+" ")])])])}),0),_c('span',{class:_vm.$style.link,on:{"click":_vm.toTradeHistory}},[_vm._v(" "+_vm._s(_vm.$t("VIEW MORE"))+" ")])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }