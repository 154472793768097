<template>
  <section :class="$style.wrapper">
    <h2 v-if="type === 'verification'">
      {{ $t("modal_messsages.oops_failed.defaut_card_title") }}
    </h2>
    <template v-else-if="type === 'payment'">
      <h2>
        {{
          failedInfo.subject ||
            $t("modal_messsages.oops_failed.default_payment_title")
        }}
      </h2>
    </template>

    <h2 v-else>{{ $t("Oops") }}</h2>

    <icon-add-error />
    <p>{{ $t("credit_card_status_message.faild.default_title") }}</p>
    <section>
      <div v-if="failedInfo.message" :class="$style.cardFaildMsg">
        {{ failedInfo.message }}
      </div>
      <template v-else>
        <ol v-if="type === 'verification'">
          <li>
            {{ $t("credit_card_status_message.faild.default_faild_desc[0]") }}
          </li>
          <li>
            {{ $t("credit_card_status_message.faild.default_faild_desc[1]") }}
          </li>
          <li>
            {{ $t("credit_card_status_message.faild.default_faild_desc[2]") }}
          </li>
        </ol>
        <ol v-else-if="type === 'payment'">
          <li>{{ $t("payment_status_message[0]") }}</li>
          <li>
            {{ $t("payment_status_message[1]") }}
          </li>
          <li>
            {{ $t("payment_status_message[2]") }}
          </li>
          <li>{{ $t("Try a separate card") }}</li>
        </ol>
        <p v-else v-html="$t('alter_default_message')"></p>
      </template>
    </section>

    <BaseButton @click="handleClick">{{ $t("Okay") | uppercase }}</BaseButton>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import IconAddError from "@/assets/img/payment/icon-add-error.svg?inline";

export default {
  name: "Modal.OopsFailed",
  components: {
    BaseButton,
    IconAddError
  },
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    failedInfo: {
      type: Object,
      default: () => {
        return { subject: "", message: "" };
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 2rem 3.125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(2rem);
    @include margin(0 0 1.75rem);

    color: var(--legend-c-text);
    line-height: 1.2;
    text-align: center;
  }

  svg {
    @include rfs(5.5rem, max-width);
    @include margin(0.5rem auto 2.5rem);
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.25rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }

  section {
    @include margin(0 0 2rem);
    @include padding(1rem);
    background-color: var(--disable);
    border-radius: var(--border-radius);
    color: var(--grey-cool);

    p {
      margin: 0;
    }

    .cardFaildMsg {
      @include rfs(0.875rem);

      color: var(--grey-cool);
      text-align: left;
      white-space: pre-wrap;
    }
  }
  ol {
    @include rfs(1.25rem);
    @include padding(0 0 0 1.5rem);
    line-height: 1.6;
    margin: 0;
  }
}
</style>
