import axios from "axios";
import arrLogFunc from "../utils";
import store from "../store";

export const LoadMockJs = async () => {
  return await Promise.all([
    (await import(/* webpackChunkName: "mock" */ "axios-mock-adapter")).default
  ]);
};

export const initMockAdapter = (api, MockAdapter) => {
  if (
    process.env.NODE_ENV === "development" &&
    store.getters["APICONFIG/baseRemote"] !== "mock"
  ) {
    return;
  }

  const mock = new MockAdapter(api, {
    // delayResponse: 2000
  });

  // mock.onPost('/trade').networkErrorOnce();
  mock
    .onGet("/users")
    .reply(200, {
      users: [{ id: 1, name: "John Smith" }]
    })
    .onGet("/items")
    .reply(200, [
      {
        uuid: "33c627de-880a-46e0-a5c0-1177c75b4668",
        verification_status: "AUTOMATICALLY_VERIFIED",
        created_at: "2021-12-06 16:15:21",
        updated_at: "2021-12-09 01:39:41",
        bank_name: "Chase",
        mask: "0000"
      },
      {
        uuid: "204f519a-1980-47d6-9efa-73a484c2303e",
        verification_status: "AUTOMATICALLY_VERIFIED",
        created_at: "2021-08-05 22:40:15",
        updated_at: "2021-08-05 22:40:15",
        bank_name: "Chase",
        mask: "0000"
      },
      {
        verification_status: "pending_manual_verification",
        created_at: "2021-08-05 22:42:40",
        updated_at: "2021-08-05 22:42:40",
        bank_name: null,
        mask: null,
        linkToken: "link-sandbox-27b2ca63-edf8-4a3f-9100-94a1757c1a9a"
      },
      {
        verification_status: "pending_manual_verification",
        created_at: "2021-08-07 00:46:18",
        updated_at: "2021-08-07 00:46:18",
        bank_name: null,
        mask: null,
        linkToken: "link-sandbox-063bb98b-6d66-40ff-8b67-8af3a9198f90"
      }
    ])
    .onGet("/createlinktoken")
    .reply(200, {
      linkToken: "link-sandbox-f99f9a8a-3c6f-4b27-8e74-9726d416f228",
      environment: "development"
    })
    // .reply(() => {
    //   // https://github.com/plaid/quickstart
    //   // start a node server to generate token for mocking
    //   return axios
    //     .post("http://localhost:8000/api/create_link_token")
    //     .then(() => [
    //       200,
    //       {
    //         linkToken: "link-sandbox-25e82253-c00b-4fcf-8b33-022481994a34",
    //         environment: "sandbox"
    //       }
    //     ]);
    // })
    .onPost("/item/remove")
    .reply(200, { message: "succeed" })
    .onAny()
    .passThrough();
};

const api = axios.create({
  baseURL: store.getters["APICONFIG/baseUrlPlaid"],
  headers: {
    Authorization: `Bearer`,
    Accept: "application/json"
  },
  timeout: 180000
});

api.interceptors.request.use(config => {
  config.baseURL = store.getters["APICONFIG/baseUrlPlaid"];
  const token = store.state.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(...arrLogFunc);

const getUsers = () => api.get("/users");

const createLinkToken = async () => {
  const resp = await api.get("/link");
  return resp.data;
};

const items = async () => {
  const resp = await api.get("/items");
  return resp.data;
};

const removeItem = async ({ uuid = "" }) => {
  const resp = await api.post("/item/remove", {
    uuid
  });
  return resp.data;
};

const exchangepublictoken = async ({ publicToken = "", metadata = "" }) => {
  const resp = await api.post("/link", {
    public_token: publicToken,
    metadata
  });
  return resp.data;
};

const webhook = async ({
  webhook_type = "",
  webhook_code = "",
  item_id = "",
  account_i = ""
}) => {
  const resp = await api.post("/webhook", {
    webhook_type,
    webhook_code,
    item_id,
    account_i
  });
  return resp.data;
};

const reLinkPlaid = async ({ payment_method_id = "" } = {}) => {
  const resp = await api.get("/relink", {
    params: {
      payment_method_id
    }
  });
  return resp.data;
};

export {
  api,
  getUsers,
  createLinkToken,
  items,
  exchangepublictoken,
  webhook,
  removeItem,
  reLinkPlaid
};
