import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import arrLogFunc from "../utils";
import store from "../store";
import sha1 from "crypto-js/sha1";

let configPairs = {
  sorted: null,
  unsorted: null
};

export const LoadMockJs = async () => {
  return await Promise.all([
    import(/* webpackChunkName: "mock" */ "mockjs"),
    (await import(/* webpackChunkName: "mock" */ "axios-mock-adapter")).default
  ]);
};

export const initMockAdapter = async (api, Mock, MockAdapter) => {
  if (
    process.env.NODE_ENV === "development" &&
    store.getters["APICONFIG/baseRemote"] !== "mock"
  ) {
    return;
  }

  const {
    tmplReadIdentification,
    tmplGetAccountCustomSupport,
    tmplSaveEmail,
    tmplPersonalInfo,
    tmplMobileInfo,
    tmplPIAddress,
    tmplQueryEmail,
    tmplVerifyEmail,
    tmplResendEmailCode,
    tmplQueryIndividualQA,
    tmplQueryRegion,
    tmplVeriffResult,
    tmplCheckBin
  } = await import(/* webpackChunkName: "mock-data" */ "./mock");

  const mock = new MockAdapter(api, {
    // delayResponse: 2000
  });

  // mock.onPost('/trade').networkErrorOnce();
  mock
    .onGet("/users")
    .reply(200, {
      users: [{ id: 1, name: "John Smith" }]
    })
    .onGet("/individual/id")
    .reply(200, Mock.mock(tmplReadIdentification))
    .onGet("/account/cs")
    .reply(200, Mock.mock(tmplGetAccountCustomSupport))
    .onPost("/individual/email/save")
    .reply(200, Mock.mock(tmplSaveEmail))
    .onGet("/individual/email")
    .reply(200, Mock.mock(tmplQueryEmail))
    .onPost("/email/code/verify")
    .reply(200, Mock.mock(tmplVerifyEmail))
    .onPost("/individual/pi/save")
    .reply(200, Mock.mock(tmplSaveEmail))
    .onGet("/individual/pi")
    .reply(200, Mock.mock(tmplPersonalInfo))
    .onGet("/individual/mobile")
    .reply(200, Mock.mock(tmplMobileInfo))
    .onPost("/individual/mobile/save")
    .reply(200, Mock.mock(tmplSaveEmail))
    .onPost("/mobile/code/verify")
    .reply(200, Mock.mock(tmplSaveEmail))
    .onGet("/individual/pi/address")
    .reply(200, Mock.mock(tmplPIAddress))
    .onGet("/individual/qa")
    .reply(200, Mock.mock(tmplQueryIndividualQA))
    .onPost("/individual/pi/address/save")
    .reply(200, Mock.mock(tmplSaveEmail))
    .onPost("/email/code/resend")
    .reply(200, Mock.mock(tmplResendEmailCode))
    .onPost("/mobile/code/resend")
    .reply(200, Mock.mock(tmplResendEmailCode))
    .onGet("/region")
    .reply(200, Mock.mock(tmplQueryRegion))
    .onGet("/result")
    .reply(() => {
      return [200, Mock.mock(tmplVeriffResult)];
    })
    .onPost("/creditcard/checkbin")
    .reply(200, Mock.mock(tmplCheckBin))
    .onAny()
    .passThrough();
};

const api = axios.create({
  baseURL: store.getters["APICONFIG/baseUrlKyc"],
  headers: {
    Authorization: `Bearer`,
    Accept: "application/json"
  },
  timeout: 180000
});

api.interceptors.request.use(config => {
  const { token, tokenType } = store.state;
  config.baseURL = store.getters["APICONFIG/baseUrlKyc"];
  config.headers.Authorization = `Bearer ${token}`;
  const arrUrl = [
    "/balance",
    "/bank",
    "/billingaddress",
    "/creditcard",
    "/creditcard/checkbin",
    "/currencies",
    "/fees",
    "/individual/qa",
    "/pairs",
    "/profile",
    "/quote",
    "/quotebyfiat",
    "/region",
    "/user/info"
  ];
  if (tokenType === "Bearer" && config.url && arrUrl.includes(config.url)) {
    // token exchanged, use /ex url endpoint instead
    config.baseURL = store.getters["APICONFIG/baseUrlTrade"];
  }
  return config;
});

api.interceptors.response.use(...arrLogFunc);

const getPairs = async ({ is_sort = false }) => {
  const cacheKey = is_sort ? "sorted" : "unsorted";
  if (!configPairs[cacheKey]) {
    const resp = await api.get("/pairs", {
      params: { is_sort: is_sort ? 1 : 0 }
    });
    configPairs[cacheKey] = resp.data;
  }
  return configPairs[cacheKey];
};

const getPairsInfo = async () => {
  const resp = await api.get("/pairsinfo");
  return resp.data;
};

const rfq = async ({ pair = "BTCUSD", side = "buy", quantity = 1 }) => {
  const resp = await api.post("/quote", {
    client_rfq_id: uuidv4(),
    pair,
    side,
    quantity
  });
  return resp.data;
};

//个人信息表单提交
const submitPersonInfo = async ({
  account_email = "",
  first_name = "",
  middle_name = "",
  last_name = "",
  country = "",
  address = "",
  city = "",
  state = "",
  zipcode = "",
  birthdate = "",
  phone_country_code = "",
  phone_country_code_short = "",
  phone_number = ""
}) => {
  const resp = await api.post("/individual/pi", {
    account_email,
    first_name,
    middle_name,
    last_name,
    country,
    address,
    city,
    state,
    zipcode,
    birthdate,
    phone_number,
    phone_country_code,
    phone_country_code_short
  });
  return resp.data;
};

// 提交个人认证表单
const submitIdentification = async data => {
  const resp = await api.post("/individual/id", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
// 获取个人认证表单数据
const getIdentification = async () => {
  const resp = await api.get("/individual/id");
  return resp.data;
};

//银行信息表单提交
const submitBankigInfo = async data => {
  const resp = await api.post("/individual/banking", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
//查询银行信息数据
const getBankingInfo = async () => {
  const resp = await api.get("/individual/banking");
  return resp.data;
};

//补充信息表单提交
const submitQuestionnaire = async ({
  pep_is_officer = "No",
  pep_related_officer = "No",
  net_wealth_source = "",
  income_source = "",
  employment = "",
  comments = ""
}) => {
  const resp = await api.post("/individual/qa", {
    pep_is_officer,
    pep_related_officer,
    net_wealth_source,
    income_source,
    employment,
    comments
  });
  return resp.data;
};
//查询补充信息数据
const getQuestionnaire = async () => {
  const resp = await api.get("/individual/qa");
  return resp.data;
};

const getAccountCustomSupport = async () => {
  const resp = await api.get("/account/cs");
  return resp.data;
};

const getFees = async () => {
  const resp = await api.get("/fees");
  return resp.data;
};

const uploadFile = async formData => {
  const resp = await api.post("/userfiles", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};

const rfqByFiat = async ({ size = "", side = "", pair = "" }) => {
  const resp = await api.post("/quotebyfiat", {
    size,
    side,
    pair
  });
  return resp.data;
};

const saveEmail = async ({ account_email = "" }) => {
  const resp = await api.post("/individual/email/save", {
    account_email
  });
  return resp.data;
};

const queryEmail = async () => {
  const resp = await api.get("/individual/email");
  return resp.data;
};

const checkEmail = async (email = "") => {
  const resp = await api.post("/email/check", {
    email
  });
  return resp.data;
};

const resendEmailCode = async () => {
  const resp = await api.post("/email/code/resend");
  return resp.data;
};

const verifyEmail = async ({ code = "" }) => {
  const resp = await api.post("/email/code/verify", {
    code
  });
  return resp.data;
};

const savePersonalInfo = async ({
  first_name = "",
  middle_name = "",
  last_name = "",
  date_of_birth = ""
}) => {
  const resp = await api.post("/individual/pi/save", {
    first_name,
    middle_name,
    last_name,
    date_of_birth
  });
  return resp.data;
};

//查询个人信息表单提交
const getPersonInfo = async () => {
  const resp = await api.get("/individual/pi");
  return resp.data;
};

const saveMobile = async ({
  phone_number = "",
  phone_country_code = "",
  phone_country_code_short = ""
}) => {
  const resp = await api.post("/individual/mobile/save", {
    phone_number,
    phone_country_code,
    phone_country_code_short
  });
  return resp.data;
};

const getMobile = async () => {
  const resp = await api.get("/individual/mobile");
  return resp.data;
};

const resendMobileCode = async () => {
  const resp = await api.post("/mobile/code/resend");
  return resp.data;
};

const verifyMobile = async ({ code = "" }) => {
  const resp = await api.post("/mobile/code/verify", {
    code
  });
  return resp.data;
};

const getPIAddress = async () => {
  const resp = await api.get("/individual/pi/address");
  return resp.data;
};

const savePIAddress = async ({
  country = "",
  state = "",
  city = "",
  address_line_1 = "",
  address_line_2 = "",
  zipcode
}) => {
  const resp = await api.post("/individual/pi/address/save", {
    country,
    state,
    city,
    address_line_1,
    address_line_2,
    zipcode
  });
  return resp.data;
};

const queryKycRegion = async ({ parent_id }) => {
  const resp = await api.get("/region", {
    params: { parent_id }
  });
  return resp.data;
};

const getVeriffUrl = async (verification_type = "individual_id") => {
  const resp = await api.post("/veriff", {
    verification_type
  });
  return resp.data;
};

const getVeriffSession = async () => {
  const resp = await api.get("/veriff/session");
  return resp.data;
};

const getVeriffStatus = async (verification_type = "individual_id") => {
  const resp = await api.get("/veriff", {
    params: { verification_type }
  });
  return resp.data;
};

const getBankInfoByCurrency = async ({ currency = "" }) => {
  const params = currency ? { currency } : {};
  const resp = await api.get("/bank", { params });
  return resp.data;
};

const setBankInfoByCurrency = async ({
  currency = "USD",
  params,
  bank_file
}) => {
  const data = bank_file
    ? { currency, params, bank_file }
    : { currency, params };
  const resp = await api.post("/bank", data);
  return resp.data;
};

const getCurrencies = async () => {
  const resp = await api.get("/currencies");
  return resp.data;
};

const getBalance = async () => {
  const resp = await api.get("/balance");
  return resp.data;
};

const queryKycProfile = async () => {
  const resp = await api.get("/profile");
  return resp.data;
};

const submitKycProfile = async params => {
  const resp = await api.post("/profile", params);
  return resp.data;
};

const getVeriffResult = async () => {
  const resp = await api.get("/result");
  return resp.data;
};

const submitCreditcard = async params => {
  const resp = await api.post("/creditcard", params);
  return resp.data;
};

const saveBillingAddress = async ({
  country_iso2_code = "",
  state = "",
  city = "",
  address_line_1 = "",
  address_line_2 = "",
  zipcode,
  as_pi_address,
  paymentmethod = ""
}) => {
  const resp = await api.post("/billingaddress", {
    country_iso2_code,
    state,
    city,
    address_line_1,
    address_line_2,
    zipcode,
    as_pi_address,
    paymentmethod
  });
  return resp.data;
};

const checkCreditCardBin = async ({ bin = "" }) => {
  const resp = await api.post("/creditcard/checkbin", { bin });
  return resp.data;
};

const getBillingAddress = async () => {
  const resp = await api.get("/getbillingaddress");
  return resp.data;
};

const getUserInfo = async ({ appId, appUid }) => {
  const resp = await api.get("/user/info", {
    params: {
      "APP-ID": appId,
      "APP-UID": appUid
    }
  });
  return resp.data;
};

const exchangeToken = async ({ appId, appUid }) => {
  const expiresAt = store.state.expiresAt;
  const kycToken = store.state.token;
  const salt = "nZXrAKewxEUtRR9";
  const strEncrypt = expiresAt + kycToken + appId + salt;
  const exchange_sign = sha1(strEncrypt).toString();
  const resp = await api.post("/token/exchange", {
    "APP-ID": appId,
    "APP-UID": appUid,
    exchange_sign
  });

  const { expires_in } = resp.data;

  store.dispatch("updateExpiresIn", expires_in);
  store.dispatch(
    "updateTokenExpiresDate",
    moment()
      .add(expires_in ?? 0, "seconds")
      .format()
  );
  return resp.data;
};

const queryKycQa = async () => {
  const resp = await api.get("/qa");
  return resp.data;
};

const submitKycQa = async params => {
  const resp = await api.post("/qa", params);
  return resp.data;
};

const saveRegistrationPath = async ({ registration_path = "" }) => {
  const resp = await api.post("/individual/pi/registrationpath", {
    registration_path
  });
  return resp.data;
};

const getIddocinfo = async () => {
  const resp = await api.get("/individual/iddocinfo");
  return resp.data;
};

const saveIddocinfo = async ({ id_doc_type, id_doc_number }) => {
  const resp = await api.post("/individual/iddocinfo/save", {
    id_doc_type,
    id_doc_number
  });
  return resp.data;
};

const getIdvCompleted = async () => {
  const resp = await api.get("/individual/idvcompleted");
  return resp.data;
};

const doIndividualSubmit = async () => {
  const resp = await api.post("/individual/submit");
  return resp.data;
};

const queryUserStatus = async params => {
  const resp = await api.get("/user/status", {
    params
  });
  return resp.data;
};

const doSubmit = async () => {
  const resp = await api.post("/submit");
  return resp.data;
};

const checkProcess = async ({ scene = "kyc" } = {}) => {
  const resp = await api.get("/process/check", {
    params: {
      scene
    }
  });
  return resp.data;
};

const queryKycInfo = async params => {
  const resp = await api.get("/info", {
    params: {
      query: params
    }
  });
  return resp.data;
};

const submitKycInfo = async params => {
  const resp = await api.post("/info", params);
  return resp.data;
};

const getAutocompleted = async () => {
  const resp = await api.post("/individual/autocompleted");
  return resp.data;
};

export {
  api,
  getPairs,
  rfq,
  submitPersonInfo,
  submitIdentification,
  getIdentification,
  submitBankigInfo,
  getBankingInfo,
  submitQuestionnaire,
  getQuestionnaire,
  getAccountCustomSupport,
  getFees,
  uploadFile,
  rfqByFiat,
  queryEmail,
  saveEmail,
  verifyEmail,
  savePersonalInfo,
  getPersonInfo,
  saveMobile,
  getMobile,
  verifyMobile,
  getPIAddress,
  savePIAddress,
  checkEmail,
  resendEmailCode,
  resendMobileCode,
  queryKycRegion,
  getVeriffUrl,
  getVeriffStatus,
  getBankInfoByCurrency,
  setBankInfoByCurrency,
  getCurrencies,
  queryKycProfile,
  submitKycProfile,
  getBalance,
  getVeriffResult,
  submitCreditcard,
  saveBillingAddress,
  getVeriffSession,
  checkCreditCardBin,
  getBillingAddress,
  getUserInfo,
  exchangeToken,
  queryKycQa,
  submitKycQa,
  saveRegistrationPath,
  getIddocinfo,
  saveIddocinfo,
  getIdvCompleted,
  doIndividualSubmit,
  queryUserStatus,
  doSubmit,
  getAutocompleted,
  getPairsInfo,
  checkProcess,
  queryKycInfo,
  submitKycInfo
};
