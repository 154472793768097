// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RegisterHeader_header_1B0PX{line-height:calc(1.325rem + .9vw);margin-bottom:calc(1.3125rem + .75vw);position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}@media(min-width:1200px){.RegisterHeader_header_1B0PX{line-height:2rem;margin-bottom:1.875rem}}.RegisterHeader_header_1B0PX p{font-size:1rem;margin-bottom:0;font-weight:700;color:var(--legend-c-text)}", ""]);
// Exports
exports.locals = {
	"header": "RegisterHeader_header_1B0PX"
};
module.exports = exports;
