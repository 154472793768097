// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DexPanel_dexWrapper_vZauh{max-width:calc(3.375rem + 25.5vw);margin:2rem auto}@media(min-width:1200px){.DexPanel_dexWrapper_vZauh{max-width:22.5rem}}.DexPanel_dexWrapper_vZauh .DexPanel_dexItem_oXzBw{text-align:left}.DexPanel_dexWrapper_vZauh .DexPanel_dexItem_oXzBw .DexPanel_title_hN6WZ{font-weight:600}.DexPanel_dexWrapper_vZauh .DexPanel_dexItem_oXzBw .DexPanel_itemValue_36rZQ,.DexPanel_dexWrapper_vZauh .DexPanel_dexItem_oXzBw .DexPanel_title_hN6WZ{font-size:.875rem;margin-bottom:.4375rem;color:var(--legend-c-text)}", ""]);
// Exports
exports.locals = {
	"dexWrapper": "DexPanel_dexWrapper_vZauh",
	"dexItem": "DexPanel_dexItem_oXzBw",
	"title": "DexPanel_title_hN6WZ",
	"itemValue": "DexPanel_itemValue_36rZQ"
};
module.exports = exports;
