// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"bgColor": "#1a1a25",
	"fontColor": "#fff",
	"panelBgColor": "#2d2d36",
	"componentBgColor": "#393941",
	"disabledBgColor": "#393941"
};
module.exports = exports;
