<template>
  <section :class="$style.wrapper">
    <h2 v-html="$t('modal_messsages.increase_limit.title')"></h2>

    <p v-html="$t('modal_messsages.increase_limit.content')"></p>

    <BaseButton type="primary" :class="$style.baseButton" @click="handleClick">
      {{ $t("LET US DO THIS") }}
    </BaseButton>

    <p @click="handleCancel">
      {{ $t("I will do it later") }}
    </p>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Modal.IncreaseLimit",
  components: {
    BaseButton
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-component-bg);

  & > h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--legend-c-text);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }

  p:last-child {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);

    &:hover {
      color: var(--primary-3);
    }

    &:active {
      color: var(--legend-c-text);
    }
  }
}
</style>
