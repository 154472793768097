// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BannerTop_banner_2Gi2M{height:calc(1.275rem + .3vw);background-color:var(--legend-c-bg);position:relative}@media(min-width:1200px){.BannerTop_banner_2Gi2M{height:1.5rem}}.BannerTop_banner_2Gi2M>.ant-btn{font-size:.75rem;height:calc(1.275rem + .3vw);-webkit-box-sizing:border-box;box-sizing:border-box;font-weight:400;padding:0;position:absolute;right:0;text-decoration:underline;top:0}@media(min-width:1200px){.BannerTop_banner_2Gi2M>.ant-btn{height:1.5rem}}.BannerTop_banner_2Gi2M>.ant-btn:hover{-webkit-box-shadow:none;box-shadow:none}.BannerTop_banner_2Gi2M>.ant-carousel .slick-vertical .slick-slide>div{font-size:0}.BannerTop_banner_2Gi2M .BannerTop_slide_3GvJV,.BannerTop_banner_2Gi2M>.ant-carousel .slick-vertical .slick-slide{height:calc(1.275rem + .3vw);line-height:calc(1.275rem + .3vw);text-align:center;overflow:hidden}@media(min-width:1200px){.BannerTop_banner_2Gi2M .BannerTop_slide_3GvJV,.BannerTop_banner_2Gi2M>.ant-carousel .slick-vertical .slick-slide{height:1.5rem;line-height:1.5rem}}.BannerTop_banner_2Gi2M .BannerTop_slide_3GvJV{font-size:1rem;color:var(--legend-c-text);margin:0;padding:0 8em;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"banner": "BannerTop_banner_2Gi2M",
	"slide": "BannerTop_slide_3GvJV"
};
module.exports = exports;
