<template>
  <section :class="$style.tradeBuyConfirm">
    <section :class="$style.content">
      <h3>{{ $t("Confirm Order") }}</h3>
      <p>{{ $t("Order Summary") | uppercase }}</p>

      <UIQuery :isLoading="isLoading">
        <OrderSummary />
        <DexPanel :walletAddress="walletAddress" :network="network" />
        <keep-alive>
          <PaymentList workflow="trade" />
        </keep-alive>
      </UIQuery>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { commonMixin } from "@/mixin/commonMixin";
import { reLoadMixin } from "@/mixin/reLoadMixin";
import { quoteMixin } from "@/mixin/quoteMixin";
import PaymentList from "@/components/localPaymentProcess/PaymentList.vue";
import OrderSummary from "@/components/OrderSummary.vue";
import DexPanel from "@/components/trade/DexPanel.vue";
import UIQuery from "@/components/UIQuery.vue";
import { queryPaymentDetailsByIntentId } from "@/api";
import { displayErrors } from "@/utils";

export default {
  name: "BuyConfirm",
  components: {
    OrderSummary,
    PaymentList,
    DexPanel,
    UIQuery
  },
  mixins: [commonMixin, reLoadMixin, quoteMixin],
  data() {
    return {
      walletAddress: "",
      network: ""
    };
  },
  computed: {
    ...mapGetters({
      asset: "asset"
    }),
    ...mapState({
      tradePair: state => state.tradePair,
      tradeQuantity: state => state.tradeQuantity,
      paymentIntentId: state => state.clientInfo.paymentIntentId
    }),
    isLoading() {
      return (
        this.tradeQuantity === "" || this.tradePair === "" || this.asset === ""
      );
    }
  },
  methods: {
    ...mapActions([
      "updatePaymentFrom",
      "updateDefaultPaymentTabsActived",
      "updateDefaultPaymentItemActived",
      "updateAsset",
      "updateTradePair",
      "updateTradeQuantity"
    ]),

    async fetchData() {
      try {
        const result = await queryPaymentDetailsByIntentId({
          payment_intent_id: this.paymentIntentId
        });
        // let result = {
        //   success: true,
        //   data: [
        //     {
        //       fiat: "USD",
        //       crypto: "USDT",
        //       size: "51",
        //       address: "0x630F9af447fB80FFa79Ea429C20937E64DCdacEb",
        //       network: "Sepolia"
        //     }
        //   ]
        // };

        if (result.success) {
          const data = result.data[0];
          this.updateAsset(data.fiat);
          this.updateTradePair(data.crypto);
          this.updateTradeQuantity(data.size);
          this.walletAddress = data?.address ?? "";
          this.network = data?.network ?? "";
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    }
  },
  activated() {
    this.fetchData();
  },
  deactivated() {
    this.stopQuote();
  },
  mounted() {
    this.handleReLoad();
  },
  beforeRouteLeave(to, from, next) {
    const { name } = to;
    if (name.startsWith("payment")) {
      this.updatePaymentFrom("external.checkout");
    } else if (name.startsWith("deposit")) {
      this.updatePaymentFrom("external.checkout");
    } else {
      this.updatePaymentFrom("");
      this.updateDefaultPaymentTabsActived("balance");
      this.updateDefaultPaymentItemActived({
        balance: {
          payment_method_id: "",
          more: false,
          type: ""
        },
        bank_transfer: {
          payment_method_id: "",
          more: false,
          type: ""
        },
        credit_card: {
          payment_method_id: "",
          more: false,
          type: ""
        }
      });
    }
    next();
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.tradeBuyConfirm {
  @include rfs(2.5rem 5.625rem 3.875rem 5.625rem, padding);

  .content {
    @include rfs(47.5rem, max-width);
    @include rfs(2.5rem 5.625rem 3.875rem 5.625rem, padding);

    border-radius: 0.625rem;
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    background: var(--legend-panel-bg);
    text-align: center;
    margin: 0 auto;
  }

  h3 {
    @include rfs(0rem, margin-bottom);

    color: var(--legend-c-text);
  }

  p {
    @include rfs(0.875rem);
    @include rfs(1.25rem, margin-bottom);

    color: var(--grey-cool);
  }
}
</style>
