<template>
  <div
    data-expired="EXPIRED"
    :class="[
      $style.listItem,
      itemData.checked ? $style.listItemSel : $style.listItemNor,
      isItemDisabled(itemData.type) && $style.itemDisabled,
      isItemExpired(itemData) && $style.itemExpired
    ]"
    @click="onChange(itemData)"
  >
    <div
      :class="{
        [$style.itemFlag]: true,
        [$style.itemFlag4BankTransfer]: [
          'bank_transfer',
          'cash_payment'
        ].includes(itemData.type),
        [$style.itemFlag4CreditCard]: itemData.type === 'credit_card',
        [$style.itemFlag4Balance]: itemData.type === 'balance',
        [$style.itemFlag4AchBalance]: itemData.type === 'ach_balance',
        [$style.itemFlag4Ach]: itemData.type === 'ach'
      }"
    >
      <IconCreditCard :type="itemData.type" :flag="itemData.flag" />
    </div>
    <div :class="$style.itemContent">
      <div :class="$style.title">
        {{
          itemData.type === "ach"
            ? `${itemData.bank_name} ${itemData.title}`
            : `${itemData.title}`
        }}
      </div>
      <div v-if="isItemDisabled(itemData.type)" :class="$style.desc">
        {{ $t("Insufficient Balance") }}
      </div>
    </div>
    <div :class="$style.action">
      <slot name="action" v-bind:itemData="itemData"> </slot>
      <template v-if="isItemAvailable(itemData)">
        <div :class="$style.radioBtn">
          <div :class="$style.radioBtnChecked" v-show="itemData.checked" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import IconCreditCard from "@/components/IconCreditCard.vue";

export default {
  name: "TradePaymentList.TradeTabPane.TabPaneItem",
  props: {
    isEnoughBalance: [Boolean],
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      disabledCreditcard: "disabledCreditcard",
      asset: "asset",
      allowAchPull: "allowAchPull"
    }),
    ...mapState({
      isSkipWireDisclaimer: state => state.user_preference.skip_wire_disclaimer
    }),
    isKycProcess() {
      const { name } = this.$route;
      return name.startsWith("individual");
    }
  },
  components: {
    IconCreditCard
  },
  methods: {
    ...mapActions("DEPOSIT", ["updateWireCurrency4Deposit"]),
    isItemDisabled(type) {
      return !this.isEnoughBalance && type === "balance";
    },
    isItemExpired(item) {
      return item?.is_expired ?? false;
    },
    isItemAvailable(item) {
      return !(this.isItemDisabled(item.type) || this.isItemExpired(item));
    },
    showAddMoney(type) {
      // 没有足够的钱 && 不是只支持信用卡  需要展示add money
      return this.isItemDisabled(type) && this.disabledCreditcard;
    },
    onChange(data) {
      // if (data.type === "balance") return; // balance就是固定的一个
      if (data.is_expired) return; // 过期卡片不可用
      this.$emit("change:paneItem", data);
    },
    toDeposit() {
      this.updateWireCurrency4Deposit(this.asset);
      if (this.isKycProcess) {
        if (this.allowAchPull) {
          this.$router.push({ name: "individual.deposit" });
        } else {
          if (this.isSkipWireDisclaimer) {
            this.$router.push({
              name: "individual.wireTransfer.confirm"
            });
          } else {
            this.$router.push({ name: "individual.wireTransfer.notice" });
          }
        }
      } else {
        if (this.allowAchPull) {
          this.$router.push({ name: "deposit" });
        } else {
          if (this.isSkipWireDisclaimer) {
            this.$router.push({
              name: "deposit.wire.confirm"
            });
          } else {
            this.$router.push({ name: "deposit.wire.notice" });
          }
        }
      }
    }
  },
  created() {},
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.listItem {
  &:not(:last-child) {
    @include rfs(20px, margin-bottom);
  }

  @include rfs(70px, height);
  @include rfs(22px, padding);

  width: 100%;
  border-radius: rfs-value(20px);
  display: flex;
  align-items: center;
  cursor: pointer;

  .itemFlag {
    @include rfs(14px, margin-right);

    color: var(--legend-c-text);
    display: flex;
    align-items: center;
  }
  .itemFlag4BankTransfer {
    @include rfs(80px, width);
  }
  .itemFlag4CreditCard {
    @include rfs(40px, width);
  }
  .itemFlag4Balance,
  .itemFlag4Ach,
  .itemFlag4AchBalance {
    @include rfs(40px, width);
    svg {
      @include rfs(30px, width);
    }
  }

  .itemFlag4Balance {
    @include rfs(4px, padding-left);
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    // max-width: 60%;
    flex: 1;
    .title {
      @include rfs(20px);

      color: var(--legend-c-text);
    }
    .desc {
      @include rfs(14px);

      color: var(--danger);
      font-weight: 600;
    }
  }

  .itemTips {
    @include rfs(12px);
    @include rfs(20px, margin-left);

    letter-spacing: -0.26px;
    color: var(--grey-cool);
    text-align: left;
    flex: 1;
  }
  .action {
    .addMoney {
      @include rfs(14px);

      font-weight: 600;
      color: var(--legend-c-text);
      cursor: pointer;
    }
  }
}

.listItemNor {
  box-shadow: inset 0 0 0 1px var(--silver);
}

.listItemSel {
  box-shadow: inset 0 0 0 1px var(--primary);
}

.itemDisabled {
  background: var(--legend-disabled-bg);
  box-shadow: none;
}

.itemExpired {
  overflow: hidden;
  position: relative;
  &::after {
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    color: var(--danger);
    content: attr(data-expired);
    display: flex;
    font-weight: 600;
    height: 100%;
    left: 0;
    justify-content: flex-end;
    padding-right: rfs-value(26px);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.radioBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rfs-value(26px);
  height: rfs-value(26px);
  border-radius: 50%;
  border: solid 1px var(--grey-cool);

  .radioBtnChecked {
    width: rfs-value(18px);
    height: rfs-value(18px);
    background-color: var(--primary);
    border-radius: 50%;
  }
}
</style>
