// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UIQuery_uiQuery_3Wsmr .ant-spin-dot{font-size:calc(1.425rem + 2.1vw)}@media(min-width:1200px){.UIQuery_uiQuery_3Wsmr .ant-spin-dot{font-size:3rem}}", ""]);
// Exports
exports.locals = {
	"uiQuery": "UIQuery_uiQuery_3Wsmr"
};
module.exports = exports;
