import Vue from "vue";
import dayjs from "dayjs";
import emitter from "./eventBus";

var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
dayjs.extend(utc);

const logSuccess = res => {
  /* eslint-disable-next-line */
  console.log(
    "%c Request Success: %s",
    "color: #4CAF50; font-weight: bold",
    res.config.url,
    res
  );
  return res;
};

const logError = err => {
  if (err?.response?.status === 403) {
    const { data } = err.response;
    if (data?.error_type === "forbidden") {
      // v1接口的返回值
      emitter.emit("legend-error:forbidden", data.data);
    } else if (data.code === "303102") {
      emitter.emit("legend-error:rejected");
    } else if (data.code === "101100") {
      // 兼容v2接口的返回值
      const args = {
        ...data.metadata,
        message: data.message
      };
      emitter.emit("legend-error:restriction", args);
    }
  }

  /* eslint-disable-next-line */
  console.log("%c Request Error:", "color: #EC6060; font-weight: bold", err);
  return Promise.reject(err);
};

const displayErrors = err => {
  if (err?.response) {
    if (err.response.status < 500) return; //状态码小于500的，均不展示弹窗

    const { message, errors } = err?.response?.data;
    if (typeof errors === "object") {
      if (Object.entries(errors).length) {
        Object.entries(errors).forEach(([key]) => {
          let description = "";
          if (Array.isArray(errors[key])) {
            description = h => errors[key].map(n => [n, h("br")]);
          } else {
            description = errors[key];
          }
          const modalMessage = description;
          Vue.prototype.$alertMessage.error(message, modalMessage);
        });
      } else {
        Vue.prototype.$alertMessage.error(message);
      }
    } else {
      Vue.prototype.$alertMessage.error(message);
    }
  } else {
    Vue.prototype.$alertMessage.error(
      err?.message ?? "Please try again later."
    );
  }
};

const sizeList = ["large", "default", "small"];

const dtNow = dayjs()
  .utc()
  .local();

const dateConfig = {
  moFormat: "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]",
  dtFormat: "yyyy-MM-dd'T'HH:mm:ss.u'000Z'",
  dtNow,
  dtDateOptions: [
    [dtNow.startOf("day").toISOString(), ""], // today
    [
      dtNow
        .subtract(1, "day")
        .startOf("day")
        .toISOString(),
      ""
    ], // yesterday
    [
      dtNow
        .subtract(7, "day")
        .startOf("day")
        .toISOString(),
      ""
    ], // last 7 days
    [
      dtNow
        .subtract(30, "day")
        .startOf("day")
        .toISOString(),
      ""
    ], // last 30 days
    [dtNow.startOf("month").toISOString(), ""], // this month
    [dtNow.startOf("month").toISOString(), dtNow.endOf("month").toISOString()], // last month
    [dtNow.startOf("second").millisecond(), ""] // all
  ],
  dateOptions: [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last Month",
    "All"
  ]
};

const getStartDate = () => {
  const { dtDateOptions, moFormat } = dateConfig;
  return dayjs(dtDateOptions[0][0])
    .utc()
    .format(moFormat);
};

const getCurrencyUnitList = currencies => {
  const currencyUnitInfo = {};
  currencies.map(({ currency, currency_symbol = "" }) => {
    currencyUnitInfo[currency] = currency_symbol || "";
  });
  return currencyUnitInfo;
};

const setCurrencyFlag = currency => {
  return currency.substring(0, 2).toLowerCase();
};

const locale2Language = {
  en: "English",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "es-MX": "Español (México)",
  "pt-BR": "Português (Brasil)",
  fr: "Français",
  de: "Deutsch"
};

const paymentConfig = {
  balance: "IconBalance",
  ach: "IconBank",
  credit_card: "IconCreditCard",
  wire: "IconBank"
};

const injectGTM = (
  package_version = "",
  id = "GTM-MCX3QB4",
  layer = "dataLayerLegend"
) =>
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ package_version });
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", layer, id);

const highRiskTelCountries = [
  "CU",
  "IQ",
  "LY",
  "KP",
  "SS",
  "YE",
  "IR",
  "LB",
  "PK",
  "SY",
  "RU",
  "SD",
  "UA"
];

const initIdDocInfoFormRule = (asset, idType) => {
  let idTypeRule = "";
  if (asset === "BRL") {
    if (idType === "CPF") {
      idTypeRule = "required|cpf_rule:11";
    } else if (idType === "CNPJ") {
      idTypeRule = "required|cnpj_rule:14";
    } else {
      idTypeRule = "required";
    }
  } else {
    if (idType === "RFC") {
      idTypeRule = "required|rfc_rule:13";
    } else if (idType === "CURP") {
      idTypeRule = "required|curp_rule:18";
    } else {
      idTypeRule = "required";
    }
  }
  return idTypeRule;
};

const getFilenameSuffix = (filename = "") => {
  const index = filename.lastIndexOf(".") + 1;
  const suffix = filename.slice(index);
  return suffix.toLowerCase();
};

export {
  logSuccess,
  logError,
  displayErrors,
  sizeList,
  dateConfig,
  getStartDate,
  paymentConfig,
  injectGTM,
  highRiskTelCountries,
  setCurrencyFlag,
  getCurrencyUnitList,
  initIdDocInfoFormRule,
  locale2Language,
  getFilenameSuffix
};

export default [logSuccess, logError];
