// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackBtn_btnBack_2zLQ9{font-size:.875rem;padding:0;position:absolute;left:0;color:var(--legend-c-text);font-weight:700;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer}.BackBtn_btnBack_2zLQ9>svg{margin-right:.5rem;width:.9375rem;-webkit-transform:rotate(90deg);transform:rotate(90deg)}", ""]);
// Exports
exports.locals = {
	"btnBack": "BackBtn_btnBack_2zLQ9"
};
module.exports = exports;
