// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PurchasedPending_wrapper_2N3Hg{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--legend-panel-bg)}@media(min-width:1200px){.PurchasedPending_wrapper_2N3Hg{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.PurchasedPending_wrapper_2N3Hg h2{font-size:calc(1.325rem + .9vw);margin:0 0 calc(1.3rem + .6vw);color:var(--legend-c-text);line-height:1.2;text-align:center}@media(min-width:1200px){.PurchasedPending_wrapper_2N3Hg h2{font-size:2rem;margin:0 0 1.75rem}}.PurchasedPending_wrapper_2N3Hg .PurchasedPending_desc_1rsLb{font-size:1.25rem;margin-bottom:calc(1.3rem + .6vw);margin-top:1.25rem;text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.PurchasedPending_wrapper_2N3Hg .PurchasedPending_desc_1rsLb{margin-bottom:1.75rem}}", ""]);
// Exports
exports.locals = {
	"wrapper": "PurchasedPending_wrapper_2N3Hg",
	"desc": "PurchasedPending_desc_1rsLb"
};
module.exports = exports;
