<template>
  <section :class="$style.wrapper">
    <h2>{{ $t("purchased_pending_title") }}</h2>
    <div :class="$style.desc">
      {{ $t("purchased_pending_content") }}
    </div>
    <BaseButton @click="$emit('ok')">{{ $t("OK") }}</BaseButton>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Modal.PurchasedPending",
  components: {
    BaseButton
  },
  data() {
    return {};
  },
  props: {
    pendingInfo: {
      type: Object,
      default: () => {
        return { subject: "", message: "" };
      }
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--legend-panel-bg);

  h2 {
    @include rfs(2rem);
    @include margin(0 0 1.75rem);

    color: var(--legend-c-text);
    line-height: 1.2;
    text-align: center;
  }
  .desc {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);
    @include rfs(1.25rem, margin-top);

    text-align: center;
    color: var(--grey-cool);
  }
}
</style>
