<template>
  <section :class="$style.wrapper">
    <section :class="$style.cardHeader" @click="toBalance">
      <icon-wallet />
      <h5>{{ $t("My fiat balance") }}</h5>
      <icon-arrow-left />
    </section>

    <section :class="$style.cardContent">
      <template v-if="hasFiatBalance">
        <icon-empty-fiat :class="$style.emptyBalance" />
        <p v-html="$t('empty_messages[0]')"></p>
        <template v-if="allowAchPull">
          <BaseButton
            type="primary"
            :class="$style.baseButtonLink"
            @click="toLinkAccount"
          >
            <a-icon type="api" />
            {{ $t("Link Your Bank Account") }}
          </BaseButton>
        </template>
        <template v-else>
          <div :class="$style.btnLink" @click="toDeposit">
            {{ $t("Make a deposit") | uppercase }}
          </div>
        </template>
      </template>
      <template v-else>
        <div :class="$style.balanceList">
          <div
            :class="$style.balanceItem"
            v-for="(value, key) in getFiatBalance"
            :key="key"
          >
            <span class="fi" :class="[flagClass(key), $style.fiatFlag]"></span>
            <span :class="$style.fiatAsset">{{ key }}</span>
            <div :class="$style.balance">
              <icon-empty-balance
                :class="$style.iconEmptyBalance"
                v-if="isShowEmptyIcon(value)"
              />
              <span :class="$style.fiatUnit">{{ currencyUnitList[key] }}</span>
              <span :class="$style.fiatAmount">{{ value }} </span>
            </div>
          </div>
        </div>
        <template v-if="allowAchPull">
          <BaseButton
            v-if="disabledCreditcard"
            type="primary"
            :class="$style.baseButtonLink"
            @click="toLinkAccount"
          >
            <a-icon type="api" v-if="!hasLinkBankAccount" />
            {{ getLinkBankText }}
          </BaseButton>
        </template>
        <template v-else>
          <BaseButton
            v-if="disabledCreditcard"
            type="primary"
            :class="$style.baseButton"
            @click="toDeposit"
          >
            <icon-deposit />
            {{ $t("DEPOSIT NOW") }}
          </BaseButton>
        </template>
      </template>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Icon } from "ant-design-vue";
import { veriffMixin } from "@/mixin/veriffMixin";
import { commonMixin } from "@/mixin/commonMixin";
import { currencyLoadMixin } from "@/mixin/currencyLoadMixin";
import { setCurrencyFlag } from "@/utils";
import BaseButton from "@/components/BaseButton.vue";
import IconWallet from "@/assets/img/trade/icon-wallet.svg?inline";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";
import IconDeposit from "@/assets/img/common/icon-deposit.svg?inline";
import IconEmptyFiat from "@/assets/img/trade/icon-empty-fiat.svg?inline";
import IconEmptyBalance from "@/assets/img/common/icon-empty-balance.svg?inline";

export default {
  name: "FiatBalanceCard",
  mixins: [veriffMixin, commonMixin, currencyLoadMixin],
  data() {
    return {
      isLoading: false
    };
  },
  components: {
    [Icon.name]: Icon,
    BaseButton,
    IconWallet,
    IconArrowLeft,
    IconDeposit,
    IconEmptyBalance,
    IconEmptyFiat
  },
  computed: {
    ...mapGetters({
      balance: "balance",
      disabledCreditcard: "disabledCreditcard",
      allowAchPull: "allowAchPull"
    }),
    ...mapState(["currencyUnitList", "hasLinkBankAccount"]),
    flagClass() {
      return currency => {
        return `fi-${setCurrencyFlag(currency)}`;
      };
    },
    isShowEmptyIcon() {
      return ({ amount }) => {
        return parseFloat(amount) === 0;
      };
    },
    hasFiatBalance() {
      return (
        JSON.stringify(this.balance.fiat) === "{}" &&
        JSON.stringify(this.balance.ach) === "{}"
      );
    },
    getFiatBalance() {
      const { fiat: fiatBalanceInfo, ach: achBalanceInfo } = this.balance;
      let faitBalanceInfo = { ...achBalanceInfo, ...fiatBalanceInfo };
      Object.keys(faitBalanceInfo).forEach(key => {
        faitBalanceInfo[key] = (
          (+fiatBalanceInfo?.[key]?.amount || 0) +
          (+achBalanceInfo?.[key]?.amount || 0)
        ).toFixed(2);
      });

      return faitBalanceInfo;
    },
    getLinkBankText() {
      if (this.hasLinkBankAccount) {
        return this.$t("DEPOSIT NOW");
      } else {
        return this.$t("Link Your Bank Account");
      }
    }
  },
  watch: {},
  methods: {
    ...mapActions(["updateDepositAchPullProcessIndex"]),
    toDeposit() {
      const isShowGlobalModal = this.showGlobalModal();
      if (!isShowGlobalModal) return;
      this.$router.push({ name: "deposit.wire.transfer" });
    },
    async toLinkAccount() {
      const isShowGlobalModal = this.showGlobalModal();
      if (!isShowGlobalModal) return;
      this.$router.push({ name: "deposit.wire.transfer" });
    },
    toBalance() {
      this.$router.push({ name: "account.balance" });
    }
  },
  created() {},
  activated() {
    this.reloadBalance();
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(17.5rem, max-width);
  @include rfs(1.6875rem, margin-bottom);

  .cardHeader {
    @include rfs(1rem, margin-bottom);
    @include rfs(0.4375rem, padding-right);

    display: flex;
    align-items: center;
    cursor: pointer;

    svg:first-child {
      @include rfs(1.125rem, max-width);
    }

    h5 {
      @include rfs(0rem, margin-bottom);
      @include rfs(0.75rem, padding-left);

      flex: 1;
      line-height: 1.5625rem;
      font-weight: 600;
      color: var(--legend-c-text);
    }

    svg:last-child {
      @include rfs(0.5rem, max-height);
      @include rfs(0.9375rem, max-width);

      transform: rotate(-90deg);
      color: var(--legend-c-text);
    }
  }
  .cardContent {
    @include rfs(1.125rem 1.25rem 1.25rem, padding);

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.625rem;
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    background-color: var(--legend-panel-bg);

    .emptyBalance {
      @include rfs(1.25rem, margin-bottom);
    }

    p {
      @include rfs(0.875rem);
      @include rfs(0rem, margin-bottom);

      font-weight: 500;
      color: var(--legend-c-text);
      text-align: center;
    }

    .btnLink {
      @include rfs(0.875rem);
      @include rfs(1.25rem, margin-top);

      font-weight: bold;
      color: var(--primary);
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: var(--primary-3);
      }

      &:active {
        color: var(--legend-c-text);
      }
    }

    .baseButtonLink {
      @include rfs(1.25rem, margin-top);
      @include rfs(240px, width);
    }

    .balanceList {
      @include rfs(1.5rem, margin-bottom);

      width: 100%;
      .balanceItem {
        @include rfs(0.1875rem 1.25rem 0.1875rem 0.4375rem, padding);

        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: solid 0.0625rem var(--silver-25);

        .fiatFlag {
          @include rfs(1.625rem, width);
          @include rfs(1.625rem, min-width);
          @include rfs(1.625rem, height);

          border-radius: 50%;
          background-size: cover;
          box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);

          line-height: 1.875rem;
        }
        .fiatAsset {
          @include rfs(1.25rem);
          @include rfs(0.4375rem, margin-left);
          // @include rfs(2rem, margin-right);

          color: var(--grey-cool);
        }

        .iconEmptyBalance {
          @include rfs(2rem, max-width);
          @include rfs(1rem, min-height);
          @include rfs(0.5rem, margin-right);
          color: var(--legend-c-text);
        }
        .balance {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          color: var(--legend-c-text);

          .fiatUnit {
            @include rfs(1.25rem);
            @include rfs(0.3125rem, margin-right);
          }
          .fiatAmount {
            @include rfs(1.25rem);

            text-align: right;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .baseButton {
      @include rfs(1.25rem, margin-top);

      svg {
        width: auto;
        left: 0;
        right: auto;
        transform: rotate(0deg);

        &:active {
          color: var(--primary);
        }
      }
    }
  }
}
</style>
